import { useContext } from 'react'
import axios from 'axios';
import styled from 'styled-components'
import { AppContext } from '../../Helpers/Context';
import { useQuery } from '@tanstack/react-query';
import { ProgramCard } from '../../Components/ProgramCard';

const Mentoring = () => {

  const { user } = useContext(AppContext);

  const { data:mentorCourseList } = useQuery(["mentorCourseList"],
    async () => { return await axios(`/mentor/course/mentor_detail/${user?.id}/`).then(res => res.data[0]) }
  )

  const { data:mentorInternshipList } = useQuery(["mentorInternshipList"],
    async () => { return await axios(`/mentor/intern/mentor_detail/${user?.id}/`).then(res => res.data[0]) }
  )

  return (
    <MentoringStyle>
      <div className="wrapperCourseMentoring">
        <div className="coursesHeader">
          <h2>Courses</h2>
        </div>
        {
          mentorCourseList?.id ?
          <div className="courses">
            {mentorCourseList?.course.map(course => {
              return <ProgramCard key={course.id} props={{ link:`/mentor/course/${course.id}`, image:course.image, title:course.title }} />
            })}
          </div>
        :
          <div className='noContent'>
            <h3>You are not mentoring any courses yet.</h3>
          </div>
        }
      </div>

      <br />

      <div className="wrapperCourseMentoring">
        <div className="coursesHeader">
          <h2>Internships</h2>
        </div>
        {
          (
            mentorInternshipList?.id ?
            <div className="courses">
              {mentorInternshipList?.internship?.map(internship => {
                return <ProgramCard key={internship.id} props={{ link:`/mentor/internship/${internship.id}`, image:internship.image, title:internship.title }} />
              })}
            </div>
            :
            <div className='noContent'>
              <h3>You are not mentoring any internships yet.</h3>
            </div>
          )
        }
      </div>
    </MentoringStyle>
  );
}

export default Mentoring

const MentoringStyle = styled.div`
  width: 90%;
  margin: 2rem auto 6rem auto;
  h2,h3{
    font-weight: 500;
  }
  .courses{
    display: flex;
    grid-gap: 1.5rem;
  }
  .noContent{
    margin-top: 4rem;
    margin-bottom: 10rem;
    text-align: center;
  }
`