import { Button, Checkbox, Text } from "@mantine/core";
import { useEffect, useState } from "react";
import { PayForSectionsStyle } from "./PaymentStyle";

export function PayForSections({ props }){

  const { noSections, noSectionChosen, setNoSectionChosen, sections, setSections, setPayInSection, setReadyToPurchase, coursePrice, connecting } = props;

  const [ totalPriceOfChosenSections, setTotalPriceOfChosenSections ] = useState(0);
  const [ pricePerSection, setPricePerSection ] = useState(0);

  function calcTotal(index){

    setNoSectionChosen(false);
    let newSections = sections;

    if(newSections[index].chosen === true){
      newSections[index].chosen = false;
      setSections(newSections);
      if( totalPriceOfChosenSections - pricePerSection < 0 ){
        setTotalPriceOfChosenSections(0);
      } else {
        setTotalPriceOfChosenSections(Number((totalPriceOfChosenSections - pricePerSection).toFixed(2)));
      }
    } else {
      newSections[index].chosen = true;
      setSections(newSections);
      if( totalPriceOfChosenSections + pricePerSection > coursePrice ){
        setTotalPriceOfChosenSections(coursePrice)
      } else {
        setTotalPriceOfChosenSections(Number((totalPriceOfChosenSections + pricePerSection).toFixed(2)));
      }
    }
  }

  useEffect(() => {
    if(sections.length > 0){
      setPricePerSection(parseFloat((coursePrice / sections.length).toFixed(2)));
    }
  }, [sections, coursePrice]);

  return(
    <PayForSectionsStyle>

      <div className="sections">
        <Text mt={15} size={18} color={"dimmed"}>Sections:</Text>
        <ul>
          {
            sections.map((section, index) => {
              return(
                <div key={section.id}>
                  <Checkbox
                    id={section?.id+"labelx"}
                    checked={section?.chosen}
                    onChange={() => calcTotal(index)}
                  />
                  <label htmlFor={section?.id+"labelx"}>
                    <ins>{pricePerSection} AZN</ins>
                    <p>{section?.title}</p>
                  </label>
                </div>
              )
            })
          }
        </ul>
      </div>

      {
        noSections &&
        <Text align="center">
          No sections found
          <br />
          <br />
        </Text>
      }

      {
        noSectionChosen &&
        <Text color="red" align="center">
          You must choose at least one section
        </Text>
      }

      <div className="buttonsToBuy">
        <Button disabled={noSections} fullWidth loading={connecting} size="lg" className={totalPriceOfChosenSections > 0 ? "payButton" : "payButton notChosen"}
          onClick={() => setReadyToPurchase(true)}
        >{'Purchase section(s)'}: {totalPriceOfChosenSections} AZN</Button>
        <Button variant="default" fullWidth className="buyFull" onClick={() => setPayInSection(false)}>Back</Button>
      </div>

    </PayForSectionsStyle>
  )
}