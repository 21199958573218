import { React, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Select } from '@mantine/core';
import { Search } from "tabler-icons-react"
import axios from "axios";
import { useTranslation } from "react-i18next"

export function SearchBarComponent({ props }){

  const { width, size } = props;

  const [ chosenLocation, setChosenLocation ] = useState(null);
  const [ searchData, setSearchData ] = useState([]);

  async function getCourses(){
    if(searchData.length === 0){
      try {
        const getCourses = await axios.get("/api/all-courses/");
        const getInternships = await axios.get("/internship/activeinternshiplist/");

        let returnedCourses = [];
        returnedCourses = getCourses.data.map(course => {
          return (
            {
              value: course.id + "c",
              label: course.title,
              group: "Courses"
            }
          )
        })

        let returnedInternships = [];
        returnedInternships = getInternships.data.map(internship => {
          return (
            {
              value: internship.id + "i",
              label: internship.title,
              group: "Internships"
            }
          )
        })

        setSearchData(
          [
            ...returnedCourses,
            ...returnedInternships,
          ]
        )

      } catch (error) {

      }
    }
  }

  let navigate = useNavigate();

  useEffect(() => {
    if(chosenLocation !== null){
      if(chosenLocation.endsWith("c")){
        navigate(`/courses/${chosenLocation.slice(0, -1)}`);
      } else {
        navigate(`/internships/${chosenLocation.slice(0, -1)}`);
      }
    }
  }, [chosenLocation])

  const { t, i18n } = useTranslation()

  return(
    <div onClick={() => getCourses()} style={{ width:width }}>
      <Select
        searchable
        clearable
        size={size}
        placeholder={t("search")+"..."}
        data={searchData}
        rightSection={<Search size={20} />}
        rightSectionWidth={50}
        nothingFound={searchData.length > 0 ? "No courses found" : "Loading..."}
        onChange={(e) => setChosenLocation(e)}
        styles={{
          rightSection: { pointerEvents: 'none' }
        }}
      />
    </div>
  )
}