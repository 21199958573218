import axios from 'axios'
import styled from 'styled-components'
import { useState } from 'react'
import { Material } from './Material'
import { ChevronRight, Messages } from 'tabler-icons-react'
import { Link, useParams } from 'react-router-dom'
import { Button } from '@mantine/core'
import { ChatDrawer } from '../Chat/_Chat'
import { useQuery } from '@tanstack/react-query'
import { Sections } from './Sections'
import { CourseUsers } from '../../Pages/Mentoring/CourseUsers'
import { InternshipUsers } from '../../Pages/Mentoring/IntenrshipUsers'

export const MentorContent = ({ props }) => {

  const [ opened, setOpened ] = useState(false)
  const [ showPart, setShowPart ] = useState({})
  const [ currentSection, setCurrentSection ] = useState({ id:0, purchased: false })
  const [ currentSectionEntries, setCurrentSectionEntries ] = useState([])
  const [ studentToTest, setStudentToTest ] = useState({})

  const { type } = props
  const { internshipId, courseId } = useParams()

  const { data:courseData } = useQuery([type === "course" ? 'course-data' : 'internship-data', type === "course" ? courseId : internshipId],
    async () => await axios.get(type === "course" ? `/api/course/${courseId}` : `/internship/${internshipId}/`).then(res => type === "course" ? res?.data[0] : res?.data),
    { enabled: (!!internshipId || !!courseId) }
  )

  if(!studentToTest.id){
    return(
      <CourseContentStyled>
        {
          type === "course" ?
          <CourseUsers props={{ studentToTest, setStudentToTest }}/>
          :
          <InternshipUsers props={{ studentToTest, setStudentToTest }}/>
        }
      </CourseContentStyled>
    )
  }

  return (
    <CourseContentStyled>

      <ChatDrawer props={{ opened, setOpened, entryData:courseData, type:"course", isMentorChat:false, studentToTest:{} }}/>

      <div className='weekInsideX'>

        <div className="currentLocation">
          <div>
            <Link to="/courses">All courses</Link>
            <ChevronRight/>
            <Link to="/courses">{courseData?.category?.title}</Link>
            <ChevronRight/>
            <Link to={`/course/${courseData?.id}`}>{courseData?.title}</Link>
          </div>
        </div>

        <div className="wrapperX2">
          <Sections props={{ type, courseData, currentSection, setCurrentSection, showPart, setShowPart, currentSectionEntries, setCurrentSectionEntries }}/>
          <Material props={{ type, studentToTest, currentSection, showPart, currentSectionEntries }}/>
        </div>

        <Button variant='default' className="openChatButton openChatButtonMobile" onClick={() => setOpened(true)} leftIcon={<Messages/>}>Discuss</Button>
      </div>
    </CourseContentStyled>
  )
}

const CourseContentStyled = styled.div`
  h3,h2{
    font-weight: 500;
  }
  .weekInsideX{
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #eee;
    margin: 0 auto 5rem auto;
    .openChatButton{
      font-weight: 500;
    }
    .openChatButtonMobile{
      display: none;
    }
    .currentLocation{
      display: flex;
      align-items: center;
      overflow: scroll;
      scrollbar-width: none;
      width: 92%;
      margin: 1rem auto;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
      div{
        color: #888;
        font-size: 15px;
        display: flex;
        align-items: center;
        min-width: max-content;
        a{
          display: flex;
          align-items: center;
          color: #000;
          text-decoration: none;
          min-width: max-content;
        }
        svg{
          margin: 0 .3rem;
        }
        b{
          min-width: max-content;
          text-decoration: none;
          color: #666;
          font-weight: 400;
        }
      }
    }
    .nav{
      display: flex;
      justify-content: space-between;
      margin: 1.8rem 0 1rem 0;
      button{
        border: none;
        background-color: #b6a5f5;
        color: #f8f8f8;
        font-size: 14px;
        border-radius: .5rem;
        height: max-content;
        font-weight: 500;
        padding: .85rem 3.8rem;
        cursor: pointer;
        transition: all .1s;
        &:hover{
          background-color: #917fcf;
        }
      }
      div{
        display: flex;
        align-items: center;
        margin-right: -1rem;
        margin-top: -1.2rem;
        p{
          font-weight: 500;
          margin: 1rem 0;
          margin-top: .8rem;
        }
        a:nth-child(1){
          transform: rotate(180deg);
          margin-top: -.4rem;
        }
        a{
          padding: 1rem;
        }
      }
    }
    .wrapperX2{
      width: 92%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 2.4fr;
      grid-gap: 1.5rem;
      margin-top: 2rem;
    }
    @media screen and (max-width:1024px){
      background-color: #fff;
      border-top: 1px solid #eee;
      width: 100%;
      .openChatButton{
        display: none;
      }
      .openChatButtonMobile{
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 2rem auto 0 auto;
      }
      .currentLocation{
        width: 90%;
        margin: 1rem auto;
      }
      .nav{
        display: none;
      }
      .wrapperX2{
        width: 90%;
        margin: 0 auto;
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }
  }
`