import { React } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import taskilledChair from "../../../Assets/Images/taskilledChair.png"
import taskilledChairNoBG from "../../../Assets/Images/taskilledChairNoBG.png"
import { ArrowRight, Book, Briefcase, Map, Puzzle2, Shape2, Trophy } from "tabler-icons-react";

export const FirstPart = () => {

  return (
    <FirstPartStyle>
      <div className="bg">
        <img src={taskilledChairNoBG} className="mobile" alt="" />
        <img src={taskilledChair} alt="" />
      </div>
      <div className="intro">
        <div className="move">
          <h1>Build the perfect portfolio</h1>
          <p className="description">
            By studying real cases and simulation games you will gain experience which makes your portfolio look better!
          </p>
          <div className="links">
          <Link to="/internships">
              <div className="achievement">
                <Map/>
                <p>Internships & Business tasks</p>
              </div>
            </Link>

            <Link to="/courses">
              <div className="book">
                <Shape2/>
                <p>Skill building programs</p>
              </div>
            </Link>

            <Link to="/communities">
              <div className="steam puzzle">
                <Briefcase/>
                <p> Communities</p>
                {/* <p className="busiDesktop">Career mentoring</p>
                <p className="busiMobile">Career mentoring</p> */}
              </div>
              <span><ArrowRight color="#7561da"/> </span>
            </Link>
          </div>
        </div>
      </div>
    </FirstPartStyle>
  );
};

const FirstPartStyle = styled.div`
  position: relative;
  margin-bottom: 18rem;
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  p {
    line-height: 1.5;
    margin: 1.5rem 0 3rem 0;
    color: #828282;
    font-size: clamp(1.125rem, 2.5vw, 1.2rem);
  }
  h1 {
    color: #4f4f4f;
    font-size: clamp(1.6rem, 5vw, 2.2rem);
  }
  .bg {
    width: 100%;
    z-index: 1;
    background-size: cover;
    .mobile{
      display: none;
    }
    img {
      position: absolute;
      height: max-content;
      width: 100%;
      z-index: 1;
    }
  }
  .intro {
    width: 50%;
    left: 8%;
    margin-top: 4rem;
    position: relative;
    z-index: 2;
    .description{
      width: 90%;
    }
    .move {
      .links {
        margin-top: 3.5rem;
        margin-bottom: 3.5rem;
        img {
          margin: 0 0.25rem;
        }
        p {
          transition: 0.3s all;
        }
        a {
          transition: 0.3s all;
          svg{
            margin: 0 0.25rem;
            padding: .5rem;
            border-radius: 50%;
          }
          .book{
            svg{
              background-color: #fff1f1;
              color: #f14949;
            }
          }
          .puzzle{
            .busiMobile{
              display: none;
            }
            p{
              min-width: max-content;
            }
            svg{
              background-color: #f5f1ff;
              color: #7349f1;
            }
          }
          .achievement{
            svg{
              background-color: #eefffa;
              color: #1ccfb4;
            }
          }
          &:hover {
            background-color: #bbb1ec;
            transform: scale(1.05);
            p {
              color: #fff;
            }
          }
        }
      }
      a {
        display: flex;
        align-items: center;
        background-color: #fff;
        margin: 1.5rem 0;
        max-width: 420px;
        border-radius: 0.5rem;
        padding: 0.7rem 0.7rem;
        text-decoration: none;
        font-weight: 500;
        justify-content: space-between;
        div {
          display: flex;
          align-items: center;
        }
        span {
          margin: 0 1rem;
          margin-top: 0.2rem;
        }
        p {
          color: #4f4f4f;
          margin: 0 1.2rem;
        }
      }
    }
  }
  @media screen and (max-width: 1600px) {
    .intro {
      margin-top: 3rem;
      .move {
        .links {
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 1430px) {
    .intro {
      .move {
        .links {
          margin: 0;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .bg {
      width: 100%;
      z-index: 1;
      background-size: cover;
      transform: scale(1) translateX(0) translateY(-2rem);
      img {
        position: absolute;
        height: max-content;
        width: 100%;
        z-index: 1;
      }
    }
    .intro {
      left: 5%;
      margin-top: 0%;
      position: relative;
      z-index: 2;
      transform: scale(85%);
      h1 {
        margin-top: -1rem;
      }
    }
  }
  @media screen and (max-width: 1250px) {
    .bg {
    }
    .intro {
      width: 50%;
      width: min(90%, 100vw);
      left: 3%;
      margin-top: -5rem;
      transform: scale(80%);
      h1 {
        margin-top: 3rem;
      }
    }
  }
  @media screen and (max-width: 1150px) {
    .bg {
      height: 380px;
      width: 100%;
      position: absolute;
    }
    .intro {
      h1 {
        width: 120%;
      }
      width: 50%;
      left: 1%;
      margin-top: -5.5rem;
      transform: scale(70%);
    }
  }
  @media screen and (max-width: 1130px) {
    .intro {
      margin-top: -6.5rem;
    }
  }
  @media screen and (max-width: 1060px) {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    margin: 0;
    margin-bottom: 0rem;
    padding-top: 0.5rem;
    background-color: #fbf5f1;
    .bg {
      .mobile{
        display: block;
        margin-top: min(10vh, 1.25rem);
        height: 460px;
        object-fit: contain;
      }
      img {
        display: none;
        position: absolute;
        height: max-content;
        width: 100%;
        z-index: 1;
      }
    }
    p {
      margin: 1.5rem auto 3rem auto;
      color: #828282;
    }
    h1 {
      color: #4f4f4f;
    }
    .intro {
      width: 90%;
      margin: 0 auto;
      margin-top: 2rem;
      position: relative;
      z-index: 2;
      transform: scale(100%) translateX(-0.25rem);
      .description {
        text-align: center;
      }
      h1 {
        width: 90%;
        margin: 0 auto;
        margin-bottom: 20rem;
        text-align: center;
      }
      .move {
        .links {
          margin-top: 3.5rem;
          a {
            display: flex;
            align-items: center;
            background-color: #fff;
            margin: 1.5rem 0.5rem;
            max-width: 100%;
            border-radius: 0.5rem;
            padding: 0.7rem 0.7rem;
            text-decoration: none;
            font-weight: 500;
            justify-content: space-between;
            .puzzle{
              .busiMobile{
                display: flex;
              }
              .busiDesktop{
                display: none;
              }
            }
            div {
              display: flex;
              align-items: center;
            }
            span {
              margin: 0;
              margin-top: 0.2rem;
              margin-left: -1rem;
            }
            p {
              color: #4f4f4f;
              margin: 0 1.5rem;
            }
          }
        }
      }
    }
  }
`;