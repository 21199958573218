import styled from "styled-components";
import { RecommendedPrograms } from "../../Components/RecommendedPrograms";
import { isTaskool } from "../../Helpers/RouterLogic";
import { RandomProgram } from "../../Components/RandomProgram";
import { AnimateOpa } from "../../Components/AnimateOpa";
import { ShowSidebar } from "../../Components/Layout/LayoutHelper";
import { PostCreator } from "../../Components/PostCreator";
import { AllPosts } from "../../Components/AllPosts";
import { CommunitiesInFeed } from "../../Components/Communities/CommunitiesInFeed";
import { CommunitiesScroller } from "../../Components/Communities/CommunitiesScroller";
import { Text } from "@mantine/core";
import { CommunitiesInFeedMobile } from "../../Components/Communities/CommunitiesInFeed";
import { MobileMenuNew } from "../../Components/Layout/Navbar/Menu";
import { useContext } from "react";
import { AppContext } from "../../Helpers/Context";

const Dashboard = () => {

  const { user } = useContext(AppContext);
  const userType =user?.user_type?.type

  return (
    <ShowSidebar>
      <AnimateOpa>
        <MobileTab>
          <div className="mobileMenuMain">
            <MobileMenuNew />
          </div>
        </MobileTab>
        <DashboardStyle>
          <div className="dashInside">
            <ScrollerStyle>
              <div className="wrapperHome">
                {/* <CommunitiesScroller/> */}

                <CommunitiesInFeed />
                <div className="mobileCommunities">
                  <CommunitiesInFeedMobile />
                </div>

                

                {(userType=="Mentor" || userType=="Teacher") && 
                <PostCreator props={{ community: null }} />
                }
                <AllPosts props={{ community: null }} />

                {/* <RandomProgram props={{ type:"internships" }}/>
                <RandomProgram props={{ type:"courses" }}/> */}
              </div>
            </ScrollerStyle>

            <div className="navSideRight navSide">
              <RecommendedPrograms props={{ isTaskool, type: "internship" }} />
              <RecommendedPrograms props={{ isTaskool, type: "course" }} />
            </div>
          </div>
        </DashboardStyle>
      </AnimateOpa>
    </ShowSidebar>
  );
};

export default Dashboard;

const DashboardStyle = styled.div`
  .userInfo {
    background: #fff;
    padding: 1rem 1rem;
    border-radius: 0.25rem;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    .avatar {
      margin-left: auto;
      border: 2px solid #eee;
    }
  }
  .entryx {
    display: flex;
    align-items: center;
    grid-gap: 0.5rem;
    border: 1px solid #eee;
    max-height: 80px;
    text-decoration: none;
    color: #000;
    p {
      margin: 0;
      padding: 0;
    }
    img {
      object-fit: cover;
      height: 80px;
      width: 120px;
      border-radius: 0.25rem 0 0 0.25rem;
    }
  }
  .dashInside {
    display: grid;
    grid-template-columns: 1.6fr 1fr;
    grid-gap: 1.5rem;
    width: 100%;
    margin: 0 auto;
    .addPostButtons {
      display: grid;
    }
    .navSide {
      grid-gap: 0.65rem;
      h3 {
        font-weight: 500;
      }
      .buttonX {
        padding-left: 0.75rem;
      }
    }
    .navSideRight {
      display: flex;
      flex-direction: column;
      .attendings {
        .link {
          &:hover {
            .title {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
  @media (max-width: 1308px) {
    .dashInside {
      grid-template-columns: 1fr;
      .navSideRight {
        display: none;
      }
    }
  }
  @media (max-width: 990px) {
    .dashInside {
      padding: 1rem 0;
      grid-gap: 1rem;
      .orta {
        order: 1;
      }
    }
  }
  @media (max-width: 568px) {
    .sc-ejfMa-d.erjZkF {
      padding: 0 !important;
    }
    .dashInside {
      display: flex;
    }
  }
`;

const ScrollerStyle = styled.div`
  display: flex;
  .FilterCommunities {
    width: 100% !important;
  }
  .FilterCommunities-main {
    background-color: #fff;
    border-radius: 0.75rem;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 0;
    .scrollMenu {
      overflow: auto;
      white-space: nowrap;
      display: flex;
      gap: 5px;
      width: 648px;
    }
  }

  .wrapperHome {
    width: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 0.65rem;
    width: 100%;
    .loading {
      display: flex;
      grid-gap: 1.5rem;
      margin: 2rem 1.5rem;
      .skel {
        display: flex;
        max-width: 284px;
      }
    }
  }
  @media (max-width: 1380px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 600px;
      }
    }
  }
  @media (max-width: 1308px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 820px;
      }
    }
  }
  @media (max-width: 1230px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 750px;
      }
    }
  }
  @media (max-width: 1160px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 700px;
      }
    }
  }
  @media (max-width: 1110px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 640px;
      }
    }
  }
  @media (max-width: 1050px) {
    .FilterCommunities-main {
      .scrollMenu {
        width: 600px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0 15px;
    .FilterCommunities {
      display: none;
    }
  }
  @media (max-width: 990px) {
    padding: 0;
    .wrapperHome {
      grid-gap: 1rem;
    }
  }

  @media (min-width: 569px) {
    .mobileCommunities {
      display: none;
    }
  }
`;

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
