import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Center, Divider, Group, Modal, Text } from "@mantine/core"
import QRCode from "qrcode.react"
import { BrandFacebook, BrandLinkedin, BrandTwitter, BrandWhatsapp, DeviceFloppy, Qrcode, Share } from "tabler-icons-react"

export const QRCodeModal = ({ url }) => {
  const { t, i18n } = useTranslation()

  const [showModal, setShowModal] = useState(false)

  const handleSaveImage = () => {
    const canvas = document.querySelector("canvas")
    const url = canvas.toDataURL("image/png")
    const link = document.createElement("a")
    link.href = url
    link.download = "qrcode.png"
    link.click()
  }

  const handleShareFacebook = () => {
    const text = `${t("here-my-taskilled-account")}`
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(text)}`
    window.open(facebookShareUrl, "_blank")
  }

  const handleShareTwitter = () => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `${t("here-my-taskilled-account")}`
    )} ${encodeURIComponent(url)}`
    window.open(twitterShareUrl, "_blank")
  }

  const handleShareLinkedIn = () => {
    const text = `${t("here-my-taskilled-account")}`
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(text)}`
    window.open(linkedinShareUrl, "_blank")
  }

  const handleShareWhatsApp = () => {
    const text = `${t("here-my-taskilled-account")} ${url}`
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`
    window.open(whatsappShareUrl, "_blank")
  }

  return (
    <>
      <Button color="gray.8" leftIcon={<Qrcode/>} styles={{ root:{ width:"170px" } }} onClick={() => setShowModal(true)}>Your QR code</Button>

      <Modal radius={"lg"} title={"Your QR code"} opened={showModal} onClose={() => setShowModal(false)}>
        
        <Center mt={"lg"} style={{ display:"flex", flexDirection:"column", gap:".5rem" }}> 
          <QRCode value={url} />
          <Button px={"sm"} color="gray.8" radius={"md"} leftIcon={<DeviceFloppy size={20}/>} onClick={() => handleSaveImage()}>Save image</Button>
        </Center>
        
        <Divider color="gray.1" mt={"lg"} mb={"sm"}/>
        
        <Center style={{ display:"flex", flexDirection:"column", gap:".75rem" }}>
          <Group style={{ gap:".5rem" }}>
            {/* <Share/> */}
            <Text weight={500} size={18}>Share</Text>
          </Group>
          <Group>
            <Button color="gray" px={"xs"} variant="default" onClick={handleShareFacebook}><BrandFacebook/></Button>
            <Button color="gray" px={"xs"} variant="default" onClick={handleShareTwitter}><BrandTwitter/></Button>
            <Button color="gray" px={"xs"} variant="default" onClick={handleShareLinkedIn}><BrandLinkedin/></Button>
            <Button color="gray" px={"xs"} variant="default" onClick={handleShareWhatsApp}><BrandWhatsapp/></Button>
          </Group>
        </Center>
        
      </Modal>
    </>
  )
}
