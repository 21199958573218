import Lottie from "lottie-react";
import emailVerify from "../../Assets/Lottie/MpxYWdBoKM.json";
import { useNavigate } from "react-router-dom";
import { Button, Stack, Text, Title } from "@mantine/core";
import styled from "styled-components";
import { useMutation } from "@tanstack/react-query";
import axios from "../../Helpers/Axios";
import {
    NotificationsProvider,
    showNotification,
  } from "@mantine/notifications";
import { Confetti } from "tabler-icons-react";
import { useEffect } from "react";


const EmailVerify = () => {
  const { search } = window.location;
  console.log('search', search);
  const navigate = useNavigate()
  const { mutate: verifyEmail } = useMutation({
    mutationFn: async () =>
      await axios.get(`/email-verify?token=${search.slice(7)}`),
    onSuccess: () => {
        showNotification({
            icon: <Confetti />,
            title: "Congratulations!",
            message: "You have successfully confirmed",
            color: "teal",
          });
          setTimeout(() => {
            navigate("/login");
          }, 2000);
    },
    onError: (error) => {
      console.log(error);
    },
  });
  useEffect(()=>{
  
  },[])
  return (
    <NotificationsProvider>

    <Container>
      <Lottie
        animationData={emailVerify}
        style={{
          width: "30%",
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "auto",
        }}
      />
      <Content>
        <Stack align="center">
          <Title>Verify your email address</Title>
          <Text align="center">
 Please verify this email address by clicking
            button below
          </Text>
          <Button className="verify-btn" onClick={verifyEmail}>
            Verify your email
          </Button>
        </Stack>
      </Content>
    </Container>
    </NotificationsProvider>

  );
};

export default EmailVerify;

const Container = styled.div`
  .verify-btn {
    background-color: #f5df4d;
    color: #463401;
    border-radius: 10px;
  }
`;
const Content = styled.div`
  width: 50%;
  margin: 0 auto;
`;
