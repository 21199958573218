import axios from "axios";
import styled from "styled-components";
import bubblesBgLeft from "../../../Assets/SVGs/bubblesBgLeft.svg";
import bubblesBgRight from "../../../Assets/SVGs/bubblesBgRight.svg";
import taskilledShadowLogo from "../../../Assets/Images/taskilledShadowLogo.png";
import { Link } from "react-router-dom";
import { React, useContext, useEffect, useState } from "react";
import { AppContext } from "../../../Helpers/Context";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import {
  Button,
  Group,
  Image,
  LoadingOverlay,
  PasswordInput,
  Space,
  Text,
  TextInput,
} from "@mantine/core";
import { getCookie, setCookie } from "../../../Helpers/useCookie";
import { ForgotPassword } from "../ForgotPassword";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import GoogleIcon from "../../../Assets/SVGs/Google.svg";
import Cookies from "universal-cookie";


function Login() {
  const [forgotPassword, setForgotPassword] = useState(false);
  const [wrongCredentials, setWrongCredentials] = useState(false);
  const [gotTokens, setGotTokens] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const { setUser, postURL, setPostURL } = useContext(AppContext);
  const cookie = new Cookies()
  const loginScheme = yup.object().shape({
    email: yup
      .string()
      .email("The email was not entered correctly")
      .required("Email address must be entered"),
    password: yup.string().required("The password must be entered"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(loginScheme),
  });

  useEffect(() => {
    let emailInput = document.getElementById("email");
    emailInput?.focus();
  }, []);

  let navigate = useNavigate();

  const formSubmit = async (e) => {
    let formData = {
      username: e.email,
      password: e.password,
    };

    // Gets JWT tokens
    try {
      setLoaderVisible(true);
      const tokens = await axios.post("api/v1/login/", formData);

      if (tokens.status === 200) {
        cookie.set('access', tokens.data.access, { path: '/',domain : ".taskilled.com"  });
        cookie.set('refresh', tokens.data.refresh, { path: '/',domain : ".taskilled.com"  });
        setGotTokens(true);
      }
    } catch (error) {
      if (error.response.status === 401) {
        setWrongCredentials(true);
      }
      setLoaderVisible(false);
    }

    reset();
  };

  useQuery(
    ["user-data", gotTokens],
    async () => {
      if (gotTokens) {
        const httpClient = axios.create({
          headers: {
            Authorization: `Bearer ${getCookie("access")}`,
          },
        });

        return await httpClient
          .post("api/v1/user-data/")
          .then((response) => response.data);
      }
    },
    {
      onSuccess: (data) => {
        setLoaderVisible(false);
        localStorage.setItem("taskooluser", JSON.stringify(data));
        setUser(true);
        window.location = "/";
        // navigate(-2);
        // window.location.href = "/";
        // if (postURL.length == 0) {
        //   window.location.href = "/";
        // } else {
        //   window.location.href = postURL;
        //   setPostURL("");
        // }
      },
      onError: (error) => {
        setLoaderVisible(false);
        error?.response?.data?.detail && setWrongCredentials(true);
      },
    },
    {
      enabled: !!gotTokens,
    }
  );

  return (
    <LoginDiv>
      <div className="nav">
        <Link to="/">
          <img className="logo" src={taskilledShadowLogo} alt="Logo" />
        </Link>
        <div>
          <h3>Don't have an account?</h3>
          <Link to="/register">Sign up</Link>
        </div>
      </div>

      <img src={bubblesBgLeft} alt="" />
      <img src={bubblesBgRight} alt="" />

      <div className="wrapper">
        <div className="welcome">
          <h2>Welcome!</h2>
          <h3>Glad to see you again</h3>
          <Button
            leftIcon={<Image src={GoogleIcon} alt="Google Icon" />}
            className="google-btn"
            onClick={() => window.location = "https://backend.taskilled.com/google/route/"}
          >
            Google
          </Button>
          <Space my="lg" />
          <Group noWrap >
            <Divider />
            <Text>or continue with email</Text>
            <Divider />
          </Group>
        </div>
        {forgotPassword === true && (
          <ForgotPassword props={{ setForgotPassword }} />
        )}
        {forgotPassword === false && (
          <>
            <form onSubmit={handleSubmit((e) => formSubmit(e))}>
              <LoadingOverlay
                visible={loaderVisible}
                loaderProps={{ color: "var(--yellow)" }}
              />

              <TextInput
                label="E-mail"
                required
                size="md"
                placeholder="E-mail address"
                id="email"
                {...register("email")}
                onChange={() => setWrongCredentials(false)}
                error={errors.email && errors.email.message}
              />

              <PasswordInput
                className="pswd"
                label="Password"
                required
                size="md"
                placeholder="Password"
                id="password"
                {...register("password")}
                toggleTabIndex={0}
                error={errors.password && errors.password.message}
              />
              {wrongCredentials && (
                <p className="error">Email address or password is incorrect</p>
              )}

              <button
                className="forgot"
                type="button"
                onClick={() => setForgotPassword(true)}
              >
                Forgot password?
              </button>
              <Button className="tsbutton" type="submit">
                Log in
              </Button>
            </form>
          </>
        )}
        <div className="register">
          <hr />
          <h3>Do not have an account?</h3>
          <Link to="/register">Sign up</Link>
        </div>
      </div>
    </LoginDiv>
  );
}

export default Login;

const Divider = styled.div`
 width: 25%;
 height: 1px;
 background-color: #CDD5DF;
`

const LoginDiv = styled.div`
  background-color: #fff;
  position: relative;
  overflow-x: hidden;
  a {
    color: #2f80ed;
    text-decoration: none;
  }
  .nav {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding: 1.6rem 0;
    div {
      display: flex;
      justify-content: center;
      align-items: center;
      h3 {
        font-weight: 400;
        font-size: 17px;
        margin-right: 1rem;
      }
      a {
        font-weight: 500;
      }
    }
    img {
      width: 170px;
    }
  }
  img:nth-child(2) {
    position: absolute;
    left: 8%;
    bottom: 0;
  }
  img:nth-child(3) {
    position: absolute;
    right: 8.7%;
    top: 20.8%;
  }
  p {
    text-align: center;
    font-size: 15px;
    padding: 0 2rem;
    color: #333;
  }
  .wrapper {
    width: min(90%, 400px);
    margin: 0 auto;
    margin-bottom: 5rem;
    .welcome {
      text-align: center;
      padding: 0.5rem 0 1rem 0;
      .google-btn {
        background-color: #fff;
        color: #202939;
        font-weight: 500;
        font-size: 15px;
        border-radius: 10px;
        border: 1px solid #cdd5df;
        width: 155px;
        height: 50px;
        button {
          padding: 20px 0;
        }
      }
      h2 {
        font-size: 26px;
        font-weight: 500;
      }
      h3 {
        font-size: 20px;
        font-weight: 500;
        color: #888;
      }
    }
    .register {
      display: none;
    }
    form {
      display: flex;
      flex-direction: column;
      grid-gap: 1rem;
      .pswd {
        button {
          margin-right: 1rem;
        }
      }
      .error {
        display: flex;
        width: 100%;
        padding: 0;
        margin: 0;
        justify-content: flex-start;
        color: #ff0000;
      }
      .forgot {
        margin-left: auto;
        color: #2d9cdb;
        text-decoration: none;
        cursor: pointer;
        background: none;
        border: none;
        margin-top: 0;
        font-size: 15px;
      }
    }
  }
  @media (max-width: 650px) {
    .nav {
      div {
        h3 {
          margin: 0;
        }
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        text-align: right;
      }
    }
    img:nth-child(n + 2) {
      display: none;
    }
    p {
      padding-bottom: 2rem;
    }
    .wrapper {
      .welcome {
        padding: 1rem 0;
      }
    }
  }
  @media (max-width: 500px) {
    .nav {
      display: flex;
      flex-direction: column;
      align-items: center;
      div {
        display: none;
      }
    }
    .wrapper {
      min-width: 280px;
      .register {
        display: block;
        text-align: center;
        padding-bottom: 3rem;
        hr {
          width: 100%;
          border: none;
          margin: 1.5rem auto 2rem auto;
          border-bottom: 1px solid #e6e6e6;
        }
        h3 {
          font-weight: 400;
          font-size: 17px;
        }
        a {
          font-weight: 500;
        }
      }
    }
  }
  @media (max-width: 300px) {
    .wrapper {
      min-width: 250px;
    }
  }
`;
