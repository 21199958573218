import {
  Button,
  Divider,
  Image,
  Tabs,
  Text,
  TextInput,
  Menu,
  Avatar,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useState } from "react";
import {
  AdjustmentsHorizontal,
  GridDots,
  Plus,
  Search,
  User,
  Users,
} from "tabler-icons-react";
import { CreateCommunity } from "./CreateCommunity";
import { AppContext } from "../../Helpers/Context";
import { UserCardImage } from "./CommunityCard";
import { DotsVertical } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";
import { Link } from "react-router-dom";

export function FilterCommunities() {
  const sm = useMediaQuery("(max-width: 400px)");
  const md = useMediaQuery("(min-width: 401px)");
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "1rem",
        borderRadius: ".75rem",
        border: "1px solid #ddd",
        marginBottom: ".5rem",
      }}
    >
      <Text weight={500} mb={10}>
        Find Communities
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
          flexWrap: `${sm ? "wrap" : md ? "no-wrap" : ""}`,
        }}
      >
        <TextInput
          style={{ width: "100%" }}
          radius={"md"}
          placeholder="Search for communities"
          icon={<Search />}
          rightSection={
            <Button size="xs" px={5} variant="default" style={{ border: "0" }}>
              <AdjustmentsHorizontal />
            </Button>
          }
          iconWidth={45}
          rightSectionWidth={45}
        />
        <Button size="sm" radius={"md"} color="gray.8">
          Search
        </Button>
      </div>
    </div>
  );
}

export const CommunitiesInFeed = () => {
  let [createCommunityModalOpen, setCreateCommunityModalOpen] = useState(false);

  const { data: communities, isLoading: loadingOne } = useQuery(
    ["all communities"],
    () => axios.get("/blog/communities/").then((res) => res.data)
  );

  const { user } = useContext(AppContext);

  const { data: userCommunities } = useQuery(["my communities"], () =>
    axios.get(`/blog/communitywithuserid/${user?.id}/`).then((res) => res.data)
  );

  const { data: joinedCommunities } = useQuery(["joined communities"], () =>
    axios
      .get(`/blog/communitiesmemberwithuserid/${user?.id}/`)
      .then((res) => res.data)
  );

  return (
    <div className="FilterCommunities">
      <CreateCommunity
        props={{ createCommunityModalOpen, setCreateCommunityModalOpen }}
      />

      {/* <FilterCommunities /> */}

      <div className="FilterCommunities-main">
        <div
          style={{
            padding: "1.5rem 1rem 0.5rem 1rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Tabs
              style={{ width: "100%" }}
              radius={"md"}
              color="gray"
              defaultValue="all"
              variant="pills"
            >
              <Tabs.List
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div style={{ display: "flex", gap: ".5rem" }}>
                  <Tabs.Tab value="all">
                    All communities: {communities?.length}
                  </Tabs.Tab>
                  <Tabs.Tab value="joined">
                    Joined communities: {joinedCommunities?.length}
                  </Tabs.Tab>
                  <Tabs.Tab value="mine">
                    Managed communities: {userCommunities?.length}
                  </Tabs.Tab>
                </div>

                <div>
                  <Menu trigger="hover">
                    <Menu.Target>
                      <Button px={12} variant="subtle" color={"gray.8"}>
                        <DotsVertical color="#495057" />
                      </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                      <Menu.Item>
                        <Button
                          radius={"md"}
                          color="gray"
                          onClick={() => setCreateCommunityModalOpen(true)}
                        >
                          <Text weight={400}>Create community</Text>
                        </Button>
                      </Menu.Item>
                    </Menu.Dropdown>
                  </Menu>
                </div>
              </Tabs.List>

              <Divider my={15} color="#dee2e6" />

              <Tabs.Panel value="all">
                <div style={{ marginBottom: "-.5rem" }}>
                  <div className="scrollMenu">
                    {communities?.length > 0 &&
                      communities.map((com) => {
                        return (
                          <div
                            style={{ width: "200px", display: "inline-block" }}
                          >
                            <UserCardImage
                              key={com?.id}
                              name={com?.name}
                              description={com?.description}
                              image={com?.image}
                              coverImage={com?.cover_image}
                              membersCount={com?.members?.length}
                              link={`/communities/${com?.id}`}
                              component={Link}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="joined" pt="xs">
                <div style={{ marginBottom: ".5rem" }}>
                  <div className="scrollMenu">
                    {joinedCommunities?.length > 0 &&
                      joinedCommunities.map((com) => {
                        return (
                          <div
                            style={{ width: "200px", display: "inline-block" }}
                          >
                            <UserCardImage
                              key={com?.id}
                              name={com?.name}
                              description={com?.description}
                              image={com?.image}
                              coverImage={com?.cover_image}
                              membersCount={com?.members?.length}
                              link={`/communities/${com?.id}`}
                              component={Link}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Tabs.Panel>

              <Tabs.Panel value="mine">
                <div style={{ marginBottom: "-.5rem" }}>
                  <div className="scrollMenu">
                    {userCommunities?.length > 0 &&
                      userCommunities.map((com) => {
                        return (
                          <div
                            style={{ width: "200px", display: "inline-block" }}
                          >
                            <UserCardImage
                              key={com?.id}
                              name={com?.name}
                              description={com?.description}
                              image={com?.image}
                              coverImage={com?.cover_image}
                              membersCount={com?.members?.length}
                              link={`/communities/${com?.id}`}
                              component={Link}
                            />
                          </div>
                        );
                      })}
                  </div>
                </div>
              </Tabs.Panel>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export const CommunitiesInFeedMobile = () => {
  const { data: communities, isLoading: loadingOne } = useQuery(
    ["all communities"],
    () => axios.get("/blog/communities/").then((res) => res.data)
  );

  return (
    <>
      <div className="mobileCards">
        <div style={{ marginBottom: "-.5rem" }}>
          <Text mb={10}>Communities</Text>
          <div
            className="scrollMenu"
            style={{
              overflow: "auto",
              whiteSpace: "nowrap",
              display: "flex",
              gap: "5px",
              width: "100%",
            }}
          >
            {communities?.length > 0 &&
              communities?.map((community) => (
                <Link
                  to={`/communities/${community?.id}`}
                  style={{ textDecoration: "none", color: "black" }}
                >
                  <div className="mobileCard" key={community?.id}>
                    <div
                      className="imageCard"
                      style={{
                        width: "70px",
                        height: "70px",
                        border: "2px solid #1245a1",
                        borderRadius: "8px",
                        margin: "0 auto",
                      }}
                    >
                      <Avatar
                        src={community?.image}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </div>
                    <div style={{ textAlign: "center" }} className="textCard">
                      <h5>{community?.name.slice(0, 12) + "..."}</h5>
                    </div>
                  </div>
                </Link>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

{
  /* {
userCommunities?.length > 0 ?
<div style={{ padding:"0.5rem 1rem 1.5rem 1rem", display:"flex", flexDirection:"column", gap:"1rem" }}>
  {
    userCommunities.map(com => {
      return(
        <div key={com.id} style={{ justifyContent:"space-between", textDecoration:"none", color:"black", display:"flex", alignItems:"center" }}>
          <div style={{ display:"flex", alignItems:"center", gap:".8rem" }}>
            <Image radius={"md"} width={140} height={80} src={com.image}/>
            <div>
              <Text size={18}>{com.name}</Text>
              <div style={{ color:"#868e96", display:"flex", alignItems:"center", gap:".5rem" }}>
                <Text size={14}>Members: {com?.members?.length}</Text>
                {
                  com?.members?.length > 1 ?
                  <Users size={16}/>
                  :
                  <User size={16}/>
                }
              </div>
            </div>
          </div>
          <Button component={Link} to={`/communities/${com.id}`}  radius={"md"} variant="outline" color="gray.6">View posts</Button>
        </div>
      )
    })
  }
  <Button component={Link} to={"/communities"} mt={5} mb={-10} size="xs" color="gray.8" variant="subtle">
    <Text size={14}>See all communities</Text>
  </Button>
</div>
:
<div style={{ display:"flex", flexDirection:"column", justifyContent:"center", gap:"1rem", alignItems:"center", padding:"1rem 0 3rem 0" }}>
  <Text align="center">You have not created a community yet</Text>
  <div style={{ display:"flex", justifyContent:"center", gap:".5rem", alignItems:"center" }}>
    <Button onClick={() => setCreateCommunityModalOpen(true)} radius={"md"} color="gray.8" leftIcon={<Plus/>}>Create</Button>
    <Button component={Link} to={"/communities"} radius={"md"} color="gray.8" leftIcon={<GridDots/>}>See all communities</Button>
  </div>
</div>
} */
}
