import { React, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {} from "../../../Components/Layout/LayoutHelper";
import { FirstPart } from "./FirstPart";
import { SecondPart } from "./SecondPart";
import { ThirdPart } from "./ThirdPart";
import { ForthPart } from "./ForthPart";
import { Newsletter } from "./Newsletter";
import { Partners } from "../../../Components/Partners";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getCookie } from "../../../Helpers/useCookie";
import axios from "../../../Helpers/Axios";
import { AppContext } from "../../../Helpers/Context";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";


const Landing = () => {
  const cookie = new Cookies()
  const [accessToken, setAccessToken] = useState(cookie.get("access"));
  const [redirect, setRedirect] = useState(false);
  
  const { mutate: sendToLocal } = useMutation({
    mutationFn: async () => {
      return await axios
        .post("api/v1/user-data/")
        .then((response) => response.data);
    },
    onSuccess: (data) => {
      localStorage.setItem("taskooluser", JSON.stringify(data));
      setRedirect(true);
    },
    onError: (error) => {},
    enabled: accessToken !== undefined,
  });

  useEffect(() => {
    accessToken && sendToLocal();
  }, [accessToken]);

  useEffect(() => {
    setAccessToken(cookie.get("access"));
  }, []);

  useEffect(() => {
    redirect && window.location.reload();
  }, [redirect]);

  return (
    <>
      <LandingStyle>
        <div className="wrapper">
          <FirstPart />
          <SecondPart />
          <ThirdPart />
          <ForthPart />
          <Newsletter />
          <Partners props={{ showHeader: true }} />
        </div>
      </LandingStyle>
    </>
  );
};

export default Landing;

const LandingStyle = styled.div`
  position: relative;
  overflow: hidden;
  max-width: 1600px;
  margin: 0 auto;
  .wrapper {
    width: 80%;
    margin: 0 auto;
  }
  @media screen and (max-width: 1024px) {
    .wrapper {
      width: 100%;
    }
  }
`;
