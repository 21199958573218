import { Link } from "react-router-dom"
import { Button, Divider, Menu, Text } from '@mantine/core';
import { Notification, Bell } from "tabler-icons-react"
import { isTaskool } from "../../../Helpers/RouterLogic";

export function NotificationIndicator(){

  const texts = isTaskool ?
  {
    nots:"Hələ heç bir bildirişiniz yoxdur",
    all:"Hamısı",
  }
  :
  {
    nots:"You don't have any notifications yet",
    all:"See all",
  }

  return(
    <Menu shadow="md"
      trigger="hover" delay={500}
      width={190}
      position="bottom-end"
      // placement="center"
      withArrow
      gutter={10}
      className="categorix"
    >
      <Menu.Target>
        <div className="catIcon"><Bell/></div>
      </Menu.Target>
      <Menu.Dropdown>
        <div style={{ textAlign: "center", padding: "0.5rem 0 0 0" }}>
          <Notification/>
          <Text mt={12} mb={20}>{texts.nots}</Text>
          <Divider/>
          <Button fullWidth component={Link} to="/notifications" variant="subtle" color={"gray.8"}>{texts.all}</Button>
        </div>
      </Menu.Dropdown>
    </Menu>
  )
}