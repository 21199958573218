import { Link } from "react-router-dom";
import {
  ChevronDown,
  Map,
  Logout,
  Settings,
  User,
  BrandAppleArcade,
  Shape2,
  BuildingBank,
  Social,
  Ticket,
  Home2,
  School,
} from "tabler-icons-react";
import { Button, Divider, Drawer, Image, Menu, Text } from "@mantine/core";
import { SearchBarComponent } from "../../SearchBar";
import taskilledShadowLogo from "../../../Assets/Images/taskilledShadowLogo.png";
import taskoolShadowLogo from "../../../Assets/Images/taskoolShadowLogo.png";
import circledaz from "../../../Assets/Images/flags-circled/az-circle-01-32.png";
import circledtr from "../../../Assets/Images/flags-circled/tr-circle-01-32.png";
import circledru from "../../../Assets/Images/flags-circled/ru-circle-01-32.png";
import circleden from "../../../Assets/Images/flags-circled/uk-circle-01-32.png";
import { useTranslation } from "react-i18next";
import "../../../Styles/index.css";
import React, { useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { t } from "i18next";

export function DesktopMenu({ props }) {
  const [selectedLang, setSelectedLang] = useState(
    localStorage.getItem("tsklng") || "az"
  );
  const { t, i18n } = useTranslation();

  const { logout } = props;

  const langs = [
    {
      iso: "az",
      icon: circledaz,
      click: () => changeLang("az"),
    },
    {
      iso: "en",
      icon: circleden,
      click: () => changeLang("en"),
    },
    {
      iso: "ru",
      icon: circledru,
      click: () => changeLang("ru"),
    },
    {
      iso: "tr",
      icon: circledtr,
      click: () => changeLang("tr"),
    },
  ];

  function changeLang(lng) {
    i18n.changeLanguage(lng);
    setSelectedLang(lng);
    localStorage.setItem("tsklng", lng);
  }

  return (
    <Menu
      trigger="hover"
      openDelay={100}
      closeDelay={400}
      width={200}
      position="top-end"
      withArrow
      gutter={10}
      className="categorix"
    >
      <Menu.Target>
        <Button px={12} variant="subtle" color={"gray.8"}>
          <ChevronDown />
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>{t("language")}</Menu.Label>
        <Menu.Item closeMenuOnClick={false} style={{ paddingLeft: "0.25rem" }}>
          {/* <Switch onLabel="TR" offLabel="RU" size="lg" radius="xs" color="dark"
            checked={i18n.language === "ru" ? true : false}
            onChange={(event) => event.currentTarget.checked === true ? changeLang("ru") : changeLang("tr")}
            thumbIcon={
              i18n.language === "tr" ?
                <img width={21} height={21} src={trflag} alt="tr" />
                :
                <img width={20} height={20} src={ruflag} alt="ru" />
            }
          /> */}
          <div style={{ display: "flex", justifyContent: "space-around" }}>
            {langs.map((lang, i) => (
              <a
                key={i}
                style={{ opacity: selectedLang == lang.iso ? 1.0 : 0.4 }}
                onClick={lang.click}
              >
                <img width={30} height={30} src={lang.icon} alt={lang.iso} />
              </a>
            ))}
          </div>
        </Menu.Item>
        <Divider />

        <Menu.Label>{t("routes")}</Menu.Label>
        <Menu.Item icon={<User size={20} />} component={Link} to="/profile">
          <Text>{t("profile")}</Text>
        </Menu.Item>
        <Menu.Item icon={<Shape2 size={20} />} component={Link} to="/courses">
          <Text>{t("courses")}</Text>
        </Menu.Item>
        <Menu.Item icon={<Map size={20} />} component={Link} to="/internships">
          <Text>{t("internships")}</Text>
        </Menu.Item>
        <Menu.Item
          icon={<BrandAppleArcade size={20} />}
          component={Link}
          to="/games"
        >
          <Text>{t("simulations")}</Text>
        </Menu.Item>

        <Divider />

        <Menu.Label>{t("app")}</Menu.Label>
        <Menu.Item
          icon={<Settings size={20} />}
          component={Link}
          to="/settings"
        >
          <Text>{t("settings")}</Text>
        </Menu.Item>

        <Menu.Item
          color="red"
          icon={<Logout size={20} />}
          onClick={() => logout()}
        >
          <Text>{t("log_out")}</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export function MobileMenu({ props }) {
  const { isTaskool, register, logout, mobileMenuOpen, setMobileMenuOpen } =
    props;

  let image = isTaskool ? taskoolShadowLogo : taskilledShadowLogo;

  // const texts = isTaskool
  //   ? {
  //       route: "Yollar",
  //       profile: "Hesab",
  //       course: "Kurslar",
  //       internship: "Bilik yarışması",
  //       simulation: "STEAM lab.",
  //       app: "Tətbiq",
  //       setting: "Tənzimləmələr",
  //       logOut: "Çıxış",
  //       logIn: "Giriş",
  //       register: "Qeydiyyat",
  //     }
  //   : {
  //       route: "Routes",
  //       profile: "Profile",
  //       course: "Skill Building",
  //       internship: "Internships",
  //       simulation: "Simulations",
  //       app: "Application",
  //       setting: "Settings",
  //       logOut: "Log Out",
  //       logIn: "Log In",
  //       register: "Regitser",
  //     };

  return (
    <Drawer
      opened={mobileMenuOpen}
      onClose={() => setMobileMenuOpen(false)}
      position="top"
      className="mobileMenu"
      size="800px"
      title={<Image width={150} src={image} alt="Logo" />}
    >
      <style>
        {`
          .mobileMenuDivs {
            max-height: calc(100vh - 100px);
            overflow-y: auto;
            scrollbar-width: thin;
          }

          .mobileMenuButton {
            height: 40px;
          }

          .mobileMenuDivs::-webkit-scrollbar {
            display:none;
          }

          .mobileMenuDivs::-webkit-scrollbar-track {
            background: #343a40;
          }

          .mobileMenuDivs::-webkit-scrollbar-thumb {
            background: #495057;
          }

          .mobileMenuDivs::-webkit-scrollbar-thumb:hover {
            background: #868e96;
          }
        `}
      </style>
      <div className="mobileMenuDivs">
        {register ? (
          <>
            <SearchBarComponent props={{ size: "md", width: "100%" }} />
            <Divider />
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              size="md"
              fullWidth
              variant="default"
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/register"
            >
              {t("register")}
            </Button>
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              size="md"
              fullWidth
              variant="filled"
              color="teal"
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/login"
            >
              {t("log_in")}
            </Button>
          </>
        ) : (
          <>
            <SearchBarComponent props={{ size: "md", width: "100%" }} />
            <Text size="md">{t("routes")}</Text>
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              fullWidth
              size="md"
              variant="default"
              leftIcon={<User size={20} />}
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/profile"
            >
              {t("profile")}
            </Button>
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              fullWidth
              size="md"
              variant="default"
              leftIcon={<Map size={20} />}
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/internships"
            >
              {t("internships")}
            </Button>
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              fullWidth
              size="md"
              variant="default"
              leftIcon={<Shape2 size={20} />}
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/courses"
            >
              {t("courses")}
            </Button>
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              fullWidth
              size="md"
              variant="default"
              leftIcon={<BuildingBank size={20} />}
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/bootcamps"
            >
              {t("Bootcamps")}
            </Button>
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              fullWidth
              size="md"
              variant="default"
              leftIcon={<BrandAppleArcade size={20} />}
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/games"
            >
              {t("simulations")}
            </Button>
            <Text size="md">{t("app")}</Text>
            <Button
              className="mobileMenuButton"
              onClick={() => setMobileMenuOpen(false)}
              fullWidth
              size="md"
              variant="default"
              leftIcon={<Settings size={20} />}
              component={Link}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
              to="/settings"
            >
              {t("settings")}
            </Button>
            <Button
              className="mobileMenuButton"
              onClick={() => {
                setMobileMenuOpen(false);
                logout();
              }}
              fullWidth
              size="md"
              variant="outline"
              color="red"
              leftIcon={<Logout size={20} />}
              style={{ margin: "0", fontWeight: "400", padding: "8px" }}
            >
              {t("log_out")}
            </Button>
          </>
        )}
      </div>
    </Drawer>
  );
}

export function MobileMenuNew() {
  const location = useLocation();
  let path = location.pathname;

  return (
    <>
      <MobileNavbar>
        <div className="navbar">
          <div
            className="elements"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Link className="element" to={"/"}>
              <Home2
                size={40}
                strokeWidth={2}
                className={`icon ${path === "/" ? "activeElement" : ""}`}
              />
            </Link>
            <Link className="element" to={"/courses"}>
              <Shape2
                component={Link}
                size={40}
                strokeWidth={2}
                className={`icon ${
                  path.includes("/courses") ? "activeElement" : ""
                }`}
              />
            </Link>
            <Link className="element" to={"/internships"}>
              <Map
                size={40}
                strokeWidth={2}
                className={`icon ${
                  path.includes("/internships") ? "activeElement" : ""
                }`}
              />
            </Link>
            <Link className="element" to={"/communities"}>
              <Social
                size={40}
                strokeWidth={2}
                className={`icon ${
                  path.includes("/communities") ? "activeElement" : ""
                }`}
              />
            </Link>
            <Link className="element" to={"/hackathons"}>
              <Ticket
                size={40}
                strokeWidth={2}
                className={`icon ${
                  path.includes("/hackathons") ? "activeElement" : ""
                }`}
              />
            </Link>
          </div>
          <Divider my={8} color={"gray"} />
        </div>
      </MobileNavbar>
    </>
  );
}
//
const MobileNavbar = styled.div`
  .element {
    width: 100%;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
  }
  .activeElement {
    color: #fde14c !important;
  }

  .icon {
    color: gray;
  }
`;
