import styled from "styled-components";
import axios from "axios";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next"
import { Image, Text, UnstyledButton } from "@mantine/core";

export const RecommendedPrograms = ({ props }) => {

  const { type } = props;

  const { t } = useTranslation()

  let link = type === "course" ? "/api/all-courses/" : "/internship/activeinternshiplist/"

  const { data:courses } = useQuery([type],
    async () => { return await axios.get(link).then(res => res.data) }
  )

  return (
    <div style={{ border:"1px solid #DEE2E6",backgroundColor:"#fff", borderRadius:"0.75rem", padding:"1rem 1rem" }}>
      <RecommendationStyle>
        {
          type === "course" ?
          <Text mb={15} size={18} weight={500}>{t("Courses")}</Text>
          :
          <Text mb={15} size={18} weight={500}>{t("Internships")}</Text>
        }
        <div className="attendings" style={{ display:"flex", flexDirection:"column", gap:".5rem" }}>
          {
            courses?.length > 0 &&
            [0,1,2]?.map((number, index) => {
              return (
                courses[number]?.id &&
                <UnstyledButton className="link" component={Link} to={`/${type}s/${courses[number]?.id}`} key={courses[number]?.id} style={{ display:"flex", alignItems:"center", textDecoration:"none", color:"#000", gridGap:"1rem" }}>
                  <Image withPlaceholder radius={"md"} height={70} width={100} src={courses[number]?.image} alt={courses[number]?.title}/>
                  <Text className="title">{courses[number]?.title}</Text>
                </UnstyledButton>
              )
            })
          }
        </div>
      </RecommendationStyle>
    </div>
  );
};

const RecommendationStyle = styled.div`
  ul {
    padding: 0;
    display: flex;
    flex-direction: column;
    grid-gap: .5rem;
  }
`;
