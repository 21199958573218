import { useContext, useState } from "react";
import { CreateCommunity } from "./CreateCommunity";
import {
  Button,
  Divider,
  Image,
  Skeleton,
  Tabs,
  Text,
  Grid,
} from "@mantine/core";
import { User, Users } from "tabler-icons-react";
import { ShowSidebar } from "../Layout/LayoutHelper";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import axios from "axios";
import { RecommendedPrograms } from "../RecommendedPrograms";
import { AppContext } from "../../Helpers/Context";
import { FilterCommunities } from "./CommunitiesInFeed";
import { UserCardImage } from "./CommunityCard";
import { useMediaQuery } from "@mantine/hooks";
import { MobileMenuNew } from "../Layout/Navbar/Menu";
import styled from "styled-components";

export const AllCommunities = () => {
  let [createCommunityModalOpen, setCreateCommunityModalOpen] = useState(false);

  const { user } = useContext(AppContext);
  const lg = useMediaQuery("(max-width: 1360px)");
  const md = useMediaQuery("(max-width: 768px)");
  const sm = useMediaQuery("(max-width: 400px)");

  const { data: communities, isLoading: loadingOne } = useQuery(
    ["communities"],
    () => axios.get("/blog/communities/").then((res) => res.data)
  );

  const { data: userCommunities, isLoading: loadingTwo } = useQuery(
    ["user communities"],
    () =>
      axios
        .get(`/blog/communitywithuserid/${user?.id}/`)
        .then((res) => res.data)
  );

  if (loadingOne || loadingTwo) {
    return (
      <ShowSidebar>
        <div
          style={{
            gap: ".5rem",
            display: "grid",
            gridTemplateColumns: "1fr .625fr",
          }}
        >
          <div>
            <FilterCommunities />
            <Skeleton radius={"md"} height={400} />
          </div>

          <div
            style={{ gap: "1.5rem", display: "flex", flexDirection: "column" }}
          >
            <RecommendedPrograms props={{ type: "internship" }} />
            <RecommendedPrograms props={{ type: "course" }} />
          </div>
        </div>
      </ShowSidebar>
    );
  }

  return (
    <ShowSidebar>
      <div
        style={{
          gap: "1.5rem",
          display: "grid",
          gridTemplateColumns: `${md ? "1fr" : "1fr .625fr"}`,
          padding: `${lg ? "0 10px" : "0"}`,
        }}
      >
        <CreateCommunity
          props={{ createCommunityModalOpen, setCreateCommunityModalOpen }}
        />

        <div>
          <MobileTab>
            <div className="mobileMenuMain">
              <MobileMenuNew />
              <h2 style={{ paddingLeft: "1rem" }}>Communities</h2>
            </div>
          </MobileTab>
          <FilterCommunities />

          <div
            style={{
              backgroundColor: "#fff",
              borderRadius: ".75rem",
              border: "1px solid #ddd",
              display: "flex",
              flexDirection: "column",
              gap: "0",
            }}
          >
            <div
              style={{
                padding: "1.5rem 1rem 0.5rem 1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Tabs
                  style={{ width: "100%" }}
                  radius={"md"}
                  color="gray"
                  defaultValue="all"
                  variant="pills"
                >
                  <Tabs.List
                    style={{
                      display: "flex",
                      justifyContent: `${sm ? "center" : "space-between"}`,
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: ".5rem",
                        flexWrap: `${sm ? "wrap" : "no-wrap"}`,
                        justifyContent: `${sm ? "center" : "space-between"}`,
                      }}
                    >
                      <Tabs.Tab value="all">
                        All communities: {communities?.length}
                      </Tabs.Tab>
                      <Tabs.Tab value="mine">
                        Managed communities: {userCommunities?.length}
                      </Tabs.Tab>
                    </div>
                    <Button
                      radius={"md"}
                      color="gray"
                      onClick={() => setCreateCommunityModalOpen(true)}
                    >
                      <Text weight={400}>Create community</Text>
                    </Button>
                  </Tabs.List>

                  <Divider my={15} color="#dee2e6" />

                  <Tabs.Panel value="all" pt="xs">
                    <div className="communities">
                      <Grid>
                        {communities?.length > 0 &&
                          communities.map((com) => {
                            return (
                              // <div
                              //   key={com.id}
                              //   style={{
                              //     justifyContent: "space-between",
                              //     textDecoration: "none",
                              //     color: "black",
                              //     display: "flex",
                              //     alignItems: "center",
                              //   }}
                              // >
                              //   <div
                              //     style={{
                              //       display: "flex",
                              //       alignItems: "center",
                              //       gap: ".8rem",
                              //     }}
                              //   >
                              //     <Image
                              //       radius={"md"}
                              //       width={140}
                              //       height={80}
                              //       src={com.image}
                              //     />
                              //     <div>
                              //       <Text size={18}>{com.name}</Text>
                              //       <div
                              //         style={{
                              //           color: "#868e96",
                              //           display: "flex",
                              //           alignItems: "center",
                              //           gap: ".5rem",
                              //         }}
                              //       >
                              //         <Text size={14}>
                              //           Members: {com?.members?.length}
                              //         </Text>
                              //         {com?.members?.length > 1 ? (
                              //           <Users size={16} />
                              //         ) : (
                              //           <User size={16} />
                              //         )}
                              //       </div>
                              //     </div>
                              //   </div>
                              //   <Button
                              //     component={Link}
                              //     to={`/communities/${com.id}`}
                              //     radius={"md"}
                              //     variant="outline"
                              //     color="gray.6"
                              //   >
                              //     View posts
                              //   </Button>
                              // </div>
                              <Grid.Col span={sm ? 12 : lg ? 6 : 4}>
                                <UserCardImage
                                  key={com?.id}
                                  name={com?.name}
                                  description={com?.description}
                                  image={com?.image}
                                  coverImage={com?.cover_image}
                                  membersCount={com?.members?.length}
                                  link={`/communities/${com?.id}`}
                                  component={Link}
                                />
                              </Grid.Col>
                            );
                          })}
                      </Grid>
                    </div>
                  </Tabs.Panel>

                  <Tabs.Panel value="mine" pt="xs">
                    <div
                      style={{
                        padding: "0.5rem .5rem 1.5rem .5rem",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <Grid>
                        {userCommunities?.length > 0 &&
                          userCommunities.map((com) => {
                            return (
                              <Grid.Col span={4}>
                                <UserCardImage
                                  key={com?.id}
                                  name={com?.name}
                                  description={com?.description}
                                  image={com?.image}
                                  coverImage={com?.cover_image}
                                  membersCount={com?.members?.length}
                                  link={`/communities/${com?.id}`}
                                  component={Link}
                                />
                              </Grid.Col>
                            );
                          })}
                      </Grid>
                    </div>
                  </Tabs.Panel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{
            gap: ".75rem",
            display: `${md ? "none" : "flex"}`,
            flexDirection: "column",
          }}
        >
          <RecommendedPrograms props={{ type: "internship" }} />
          <RecommendedPrograms props={{ type: "course" }} />
        </div>
      </div>
    </ShowSidebar>
  );
};

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
