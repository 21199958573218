import styled from "styled-components";
import {
  Avatar,
  Button,
  CopyButton,
  Divider,
  Paper,
  Spoiler,
  Text,
  Tooltip,
} from "@mantine/core";
import { ChevronDown, ChevronUp, Copy } from "tabler-icons-react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { QRCodeModal } from "../../../Components/QRmodal";

export const ProfileIntro = ({ props }) => {
  const { user } = props;

  const { t, i18n } = useTranslation();

  const location = useLocation();

  const profileUrl = `https://taskilled.com/profile/${user?.guid}`;

  return (
    <IntroStyle>
      <div className="wrapperIntro">
        {user?.profile_img?.endsWith("download.png") ? (
          <Avatar className="avatar" src={""} size={100} radius={100} />
        ) : (
          <Avatar
            className="avatar"
            src={user?.profile_img}
            size={100}
            radius={100}
          />
        )}
        <div className="title">
          <div>
            <Text size={23}>
              {user?.first_name} {user?.last_name !== "." && user?.last_name}
            </Text>
            {user?.speciality?.content && (
              <Text color={"dimmed"}>
                {user?.speciality?.content || `${t("speciality not chosen")}`}
              </Text>
            )}
          </div>
          <Button
            variant="subtle"
            color={"gray.7"}
            component={Link}
            to="/settings"
          >
            {t("edit profile")}
          </Button>
        </div>
      </div>
      {user?.bio && (
        <Spoiler
          maxHeight={120}
          showLabel={<ChevronDown size={30} />}
          hideLabel={<ChevronUp size={30} />}
          style={{ marginTop: user?.bio?.length > 160 ? "-.75rem" : "" }}
        >
          <Text mt={10}>{user?.bio}</Text>
        </Spoiler>
      )}
      <div style={{ display: "flex", flexWrap: "wrap",gridGap: ".5rem"  }}>
        <div
          style={{ display: "flex", alignItems: "center"}}
        >
          <Paper
            radius={"xs"}
            withBorder
            py={6}
            px={15}
            style={{ width: "fit-content" }}
          >
            <Text>taskilled.com/profile/{user?.guid}</Text>
          </Paper>
        </div>
        <CopyButton value={`taskilled.com/profile/${user?.guid}`}>
          {({ copied, copy }) => (
            <Tooltip
              label={copied ? `${t("copied")}` : `${t("copy profile url")}`}
            >
              <Button
                style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                px={"xs"}
                color={copied ? "teal" : "gray.8"}
                onClick={copy}
                ml={".5rem"}
              >
                <Copy />
              </Button>
            </Tooltip>
          )}
        </CopyButton>
        <Divider className="divider" color="gray.3" mx={"xs"} orientation="vertical" />

        <QRCodeModal url={profileUrl} />
      </div>
    </IntroStyle>
  );
};

const IntroStyle = styled.div`
  background-color: #fff;
  border-radius: 0.25rem;
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  grid-gap: 1rem;
  border-radius: 0.75rem;
  border: 1px solid #dee2e6;
  .wrapperIntro {
    display: flex;
    align-items: center;
    grid-gap: 1rem;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  @media screen and (max-width: 768px) {
    border-radius: 0;
    .wrapperIntro {
      width: 92%;
    }
  }
  @media screen and (max-width: 590px) {
    .divider{
      border-left-color:transparent !important;
    }
  }
`;
