import { Card, Image, Text, Badge, Button, Group, Grid } from "@mantine/core";
import { Link as LinkIcon } from "tabler-icons-react";
import styled from "styled-components";
import moment from "moment";
import { Link } from "react-router-dom";

export const HackathonCard = ({ hackathon }) => {
  const { id, title, image, start_date, end_date, language } = hackathon;

  return (
    <CardStyle>
      <Card
        key={id}
        className="card"
        shadow="sm"
        padding="lg"
        radius="md"
        withBorder
        component={Link}
        to={`/hackathons/${id}`}
      >
        <Card.Section>
          <Image src={image} height={160} alt="Norway" />
        </Card.Section>

        <Group justify="space-between" mt="md" mb="xs">
          <Text fw={500}>
            {title?.length > 48 ? title.substring(0, 48) + "..." : title}
          </Text>
        </Group>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="columns">
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "10px",
              }}
            >
              Start date:
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "10px",
              }}
            >
              End date:
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "10px",
              }}
            >
              Language:
            </p>
          </div>
          <div className="columns-values">
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "10px",
              }}
            >
              {moment(start_date).format("DD.MM.YYYY")}
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "10px",
              }}
            >
              {moment(end_date).format("DD.MM.YYYY")}
            </p>
            <p
              style={{
                fontSize: "16px",
                fontWeight: 600,
                lineHeight: "10px",
              }}
            >
              Azerbaijan
            </p>
          </div>
        </div>
      </Card>
    </CardStyle>
  );
};

const CardStyle = styled.div`
  .card {
    transition: transform 150ms ease, box-shadow 150ms ease;
    cursor: pointer;

    &:hover {
      transform: scale(1.01);
      box-shadow: 20px;
    }
  }
`;
