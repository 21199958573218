import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import cib from "../Assets/Images/partners/cib.png"
import emobile from "../Assets/Images/partners/emobile.png"
import fakir from "../Assets/Images/partners/fakir.png"
import hafele from "../Assets/Images/partners/hafele.png"
import logix from "../Assets/Images/partners/logix.png"
import virtualCard from "../Assets/Images/partners/virtualCard.png"
import kapital from "../Assets/Images/partners/kapital.png"
import learntor from "../Assets/Images/partners/learntor.png"
import ealogo from "../Assets/Images/partners/ealogo.png"
import gamepons from "../Assets/Images/partners/gamepons.png"
import { Image } from "@mantine/core";

export function PartnersCarousel() {
  return (
    <Carousel width={"160px"} infiniteLoop autoPlay showIndicators={false} showStatus={false} showThumbs={false} showArrows={false}>
      <Image fit="contain" width={125} height={100} src={cib} alt="" />
      <Image fit="contain" width={125} height={100} src={emobile} alt="" />
      <Image fit="contain" width={125} height={100} src={fakir} alt="" />
      <Image fit="contain" width={125} height={100} src={hafele} alt="" />
      <Image fit="contain" width={125} height={100} src={gamepons} alt="" />
      <Image fit="contain" width={125} height={100} src={virtualCard} alt="" />
      <Image fit="contain" width={125} height={100} src={learntor} alt="" />
      <Image fit="contain" width={125} height={100} src={kapital} alt="" />
      <Image fit="contain" width={125} height={100} src={ealogo} alt="" />
      <Image fit="contain" width={125} height={100} src={logix} alt="" />
    </Carousel>
  );
}