import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

function LinkPreview({ url }) {
  const key = "1428d0fb030657e9a658b3602e552bfe";

  const [linkData, setLinkData] = useState({
    title: "",
    description: "",
    image: "",
    url: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      const requestData = { q: url };
      try {
        const response = await axios.post(
          "https://api.linkpreview.net",
          requestData,
          {
            headers: {
              "X-Linkpreview-Api-Key": key,
            },
          }
        );

        const { title, description, image } = response.data;

        setLinkData({
          title,
          description,
          image,
        });
      } catch (error) {
        console.error("Error fetching link preview:", error);
      }
    };

    fetchData();
  }, [url]);

  const { title, description, image } = linkData;

  return (
    <Link
      style={{ maxWidth: "100%", textDecoration: "none", color: "black" }}
      to={url}
    >
      <div id="myurl" style={{ marginTop: "0.5rem" }}>
        {url}
      </div>
      <img
        src={image}
        style={{ maxWidth: "100%" }}
        id="myimage"
        alt="Link Preview"
      />
      <div className="is-clipped">
        <div id="mytitle" style={{ fontWeight: "bold" }}>
          {title}
        </div>
        <div id="mydescription" style={{ marginTop: "0.5rem" }}>
          {description}
        </div>
      </div>
    </Link>
  );
}

export default LinkPreview;
