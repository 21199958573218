import { Calendar, Clock, Language, Layout2 } from "tabler-icons-react";
import styled from "styled-components"
import { Link } from "react-router-dom"
import { Button, Group, Image, Text } from "@mantine/core"
import { useState } from "react";
import { useTranslation } from "react-i18next"
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { SkeletonLoader } from "./Loaders/SkeletonLoader";

export function RandomProgram({ props }){

  const { t } = useTranslation()

  const { type } = props;

  let [ chosen, setChosen ] = useState({});

  const { data, isLoading, isError, error } = useQuery([type],
    async () => await axios.get(type === "courses" ? `/api/all-courses/` : `/internship/activeinternshiplist/`).then(res => res.data),
    { onSuccess: data => setChosen(data[Math.floor(Math.random() * data?.length)]) }
  )

  if(isLoading){
    return(
      <SkeletonLoader visible={isLoading} width="100%" height="520px" />
    )
  }

  if(isError){
    return(
      <div>{error.message}</div>
    )
  }

  return(
    <RandomProgramStyle>
      <Image width={"100%"} height={300} withPlaceholder src={chosen?.image || chosen?.image} />
      <div className="info">
        <h3>{chosen?.title}</h3>
        <div className="details">
          <div className="detaWrap">
            <Group>
              <Language/>
              <Text>{chosen?.language?.language}</Text>
            </Group>
            <Group>
              <Calendar/>
              <Group>
                {
                  chosen?.start_date ?
                  <Text>{chosen?.start_date?.replaceAll("-","-")}</Text>
                  :
                  <Text>{chosen?.course_deadline?.replaceAll("-","-")}</Text>
                }
              </Group>
            </Group>
            <Group>
              <Clock/>
              <Group>
                {
                  chosen?.duration ?
                  <Text>{chosen?.duration > 1 ? (chosen?.duration + " hours") : (chosen?.duration + " hour")}</Text>
                  :
                  <Text>{chosen?.duration_time > 1 ? (chosen?.duration_time + " hours") : (chosen?.duration_time + " hour")}</Text>
                }
              </Group>
            </Group>
            <Group>
              <Layout2/>
              <Text>{chosen?.speciality ? chosen?.speciality[0]?.content : chosen?.category?.title}</Text>
            </Group>
          </div>
          <Button style={{ fontWeight:"500" }} px={50} radius={"sm"} color="gray.8" variant="outline" component={Link} to={`/${type}/${chosen?.id}`}>{t("Enter")}</Button>
        </div>
      </div>
    </RandomProgramStyle>
  )
}

const RandomProgramStyle = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #DEE2E6;
  border-radius: 0.75rem;
  overflow: hidden;
  h3,h2,strong{
    font-weight: 500;
  }
  img{
    width: 100%;
    height: 250px;
    object-fit: cover;
  }
  .info{
    background-color: #fff;
    padding: 0 1rem;
    .details{
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin: 1.75rem auto 1rem auto;
      .detaWrap{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1rem 1.5rem;
        div{
          display: flex;
          align-items: center;
          grid-gap: .5rem;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .info{
      .details{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        grid-gap: 1.5rem;
        a{
          width: 100%;
        }
      }
    }
  }
`