import { Link } from "react-router-dom"
import styled from "styled-components"

export const SchoolCard = ({school}) => {
  return (
    <SchoolCardStyle>
      <div className="cardWrapper">
        <Link to={`/bootcamp/${school.id}`}>
          <div className="gameImage">
            <img src={school?.profile_img} alt="" />
          </div>
          <div className="details">
            <p>{school?.title.length > 40 ? school?.title.substring(0,40).concat("...") : school?.title}</p>
          </div>
        </Link>
      </div>
    </SchoolCardStyle>
  )
}

const SchoolCardStyle = styled.div`
  transition: all .15s;
  margin: 0 .3rem;
  a{
    text-decoration: none;
    color: #000;
  }
  .cardWrapper{
    a{
      display: flex;
      flex-direction: column;
      width: 284px;
      max-height: 440px;
      background: none;
      background-color: #ffffffbb;
      border-radius: 0.7rem;
      margin: 0 .5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
      padding-bottom: 0.4rem;
      border: 1px solid #eee;
      overflow: hidden;
      box-shadow: 0 0 1rem 0 rgba(0,0,0,0.2);
      border: none;
      backdrop-filter: blur(1px);
      -webkit-backdrop-filter: blur(1px);
      .gameImage{
        display: flex;
        justify-content: center;
        overflow: hidden;
        height: 180px;
        img{
          transition: all .3s;
          border-radius: 0.5rem 0.5rem 0 0;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }:hover{
        transition: all .3s;
        background-color: #ffffff;
        .gameImage{
          img{
            transform: scale(1.1);
          }
        }
      }
      .details{
        margin: 1.2rem 1.2rem 0 1.2rem;
        min-height: 70px;
        p{
          font-weight: 500;
          font-size: 17px;
          margin-top: 0rem;
        }
        p:nth-child(2){
          font-size: 14px;
          color: #555;
          font-weight: 400;
        }
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        border: 1px solid #ddd;
        border-radius: .3rem;
        margin: 1rem;
        padding: .8rem 0rem;
        transition: all 0.1s;
        &:hover{
          background: #a891d1;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    .cardWrapper{
      display: flex;
      flex-direction: column;
      justify-content: center;
      border-radius: 0.5rem;
      margin: 0 1rem;
      padding-bottom: 0.4rem;
      .courseImage{
        display: flex;
        margin: 0.5rem;
        max-height: 200px;
        img{
          display: flex;
          margin: 0 auto;
          border-radius: 0.5rem;
          max-width: 100%;
          object-fit: cover;
        }
      }
      .details{
        margin: 1.2rem 1.2rem 0 1.2rem;
        p{
          font-weight: 500;
          font-size: 17px;
        }
      }
    }
  }
`