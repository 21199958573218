import React, { useState } from "react";
import { ShowSidebar } from "../../Components/Layout/LayoutHelper";
import { HackathonCard } from "./HackathonCard";
import { AnimateOpa } from "../../Components/AnimateOpa";
import styled from "styled-components";
import { MobileMenuNew } from "../../Components/Layout/Navbar/Menu";
import {
  Grid,
  Button,
  Text,
  TextInput,
  Container,
  Center,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import { AdjustmentsHorizontal, Search } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { ComingSoon } from "../../Components/ComingSoon";

const Hackathons = () => {
  const sm = useMediaQuery("(max-width: 400px)");
  const md = useMediaQuery("(min-width: 401px)");

  const [searchQuery, setSearchQuery] = useState("");

  function Errors({ error }) {
    return (
      <Center>
        <Container my={120}>
          <Text weight={600} mr={2} align="center" size={32}>
            Error
          </Text>
          <Text size={20}>{error}</Text>
        </Container>
      </Center>
    );
  }

  const {
    data: contents,
    isError,
    error,
    isLoading,
  } = useQuery(
    [
      "/hackathon/activate_hackatons_list/",
      "/hackathon/activate_hackatons_list/",
    ],
    async () =>
      await axios
        .get("/hackathon/activate_hackatons_list/")
        .then((res) => res.data)
  );

  if (isError) {
    return <Errors error={error?.message} />;
  }

  if (contents?.length === 0) {
    return <ComingSoon />;
  }

  // Filter hackathons based on searchQuery
  const filteredHackathons = contents?.filter((hackathon) =>
    hackathon?.title?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <ShowSidebar>
      <AnimateOpa>
        <MobileTab>
          <div className="mobileMenuMain">
            <MobileMenuNew />
            <h2 style={{ paddingLeft: "1rem" }}>HACKATHONS</h2>
          </div>
        </MobileTab>
        <div
          style={{
            backgroundColor: "#fff",
            padding: "1rem",
            borderRadius: ".75rem",
            border: "1px solid #ddd",
            marginBottom: ".5rem",
          }}
        >
          <Text weight={500} mb={10}>
            Find hackathons
          </Text>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              width: "100%",
              flexWrap: `${sm ? "wrap" : md ? "no-wrap" : ""}`,
            }}
          >
            <TextInput
              style={{ width: "100%" }}
              radius={"md"}
              placeholder="Search for hackathons"
              icon={<Search />}
              rightSection={
                <Button
                  size="xs"
                  px={5}
                  variant="default"
                  style={{ border: "0" }}
                >
                  <AdjustmentsHorizontal />
                </Button>
              }
              iconWidth={45}
              rightSectionWidth={45}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <div>
            <Grid mt={8}>
              {searchQuery === ""
                ? contents?.map((hackathon, index) => (
                    <Grid.Col key={index} lg={4} xs={4}>
                      <HackathonCard hackathon={hackathon} />
                    </Grid.Col>
                  ))
                : filteredHackathons?.map((hackathon, index) => (
                    <Grid.Col key={index} lg={4} xs={4}>
                      <HackathonCard hackathon={hackathon} />
                    </Grid.Col>
                  ))}
            </Grid>
          </div>
        </div>
      </AnimateOpa>
    </ShowSidebar>
  );
};

export default Hackathons;

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
