import { Link } from "react-router-dom"
import { Button, Menu, Text } from '@mantine/core';
import { BrandAppleArcade, GridDots, Map, Shape2 } from "tabler-icons-react"
import { useTranslation } from "react-i18next"

export function Categories(){

  const { t, i18n } = useTranslation()

  return(
    <Menu
      trigger="hover"
      openDelay={100}
      closeDelay={400}
      position="bottom"
      withArrow
      width={290}
    >
      <Menu.Target>
        <Button px={12} variant="subtle" color={"gray.8"} >
          <GridDots/>
        </Button>
      </Menu.Target>
      <Menu.Dropdown>
        <Menu.Item component={Link} to="/courses/purchased" icon={<Shape2 />}>
          <Text size={16}>{t("courses")}</Text>
        </Menu.Item>
        <Menu.Item component={Link} to="/internships/purchased" icon={<Map />}>
          <Text size={16}>{t("internships")}</Text>
        </Menu.Item>
        <Menu.Item component={Link} to="/games/purchased" icon={<BrandAppleArcade />}>
          <Text size={16}>{t("simulations")}</Text>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}