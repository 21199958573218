import React, { useState } from "react";
import styled from "styled-components";
import targetIcon from "../../../Assets/SVGs/profile/target.svg";
import pieChartIcon from "../../../Assets/SVGs/profile/pie-chart.svg";
import skillIcon from "../../../Assets/SVGs/profile/skill-building.svg";
import internIcon from "../../../Assets/SVGs/profile/internship.svg";
import schoolsIcon from "../../../Assets/SVGs/profile/schools.svg";
import accordionArrow from "../../../Assets/SVGs/profile/chevron-down.svg";
import profileView from "../../../Assets/Images/profile/profile-view.png";

function ProfileSidebar() {
  const [accordion, setAccordion] = useState(0);

  const handleAccordion = (index) => {
    setAccordion(index == accordion ? 0 : index);
  };

  return (
    <NewSidebarStyle>
      <div className="recomendations">
        <div className="recomendations-title">
          <div className="sidebar-icon">
            <img src={targetIcon} alt="" />
          </div>

          <h2>Recomendations</h2>
        </div>

        <div className="accordion-box">
          <div className="accordion-item">
            <div
              onClick={() => handleAccordion(1)}
              className="accordion-header"
            >
              <div className="accordion-title">
                <img src={skillIcon} alt="" />
                SkillBuilding
              </div>

              <div className="accordion-arrow">
                <img
                  className={`${accordion == 1 ? "active" : ""}`}
                  src={accordionArrow}
                  alt=""
                />
              </div>
            </div>

            
              <div className={`accordion-body ${accordion == 1 ? "active" : ""}`}>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius
                  reiciendis eum expedita voluptatibus ratione obcaecati
                  laboriosam tempora assumenda pariatur cupiditate eveniet quas
                  recusandae ducimus fugiat, mollitia saepe commodi alias
                  libero.
                </p>
              </div>
            
          </div>

          <div className="accordion-item">
            <div
              onClick={() => handleAccordion(2)}
              className="accordion-header"
            >
              <div className="accordion-title">
                <img src={internIcon} alt="" />
                Internship
              </div>

              <div className="accordion-arrow">
                <img
                  className={`${accordion == 2 ? "active" : ""}`}
                  src={accordionArrow}
                  alt=""
                />
              </div>
            </div>
            <div className={`accordion-body ${accordion == 2 ? "active" : ""}`}>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius
                  reiciendis eum expedita voluptatibus ratione obcaecati
                  laboriosam tempora assumenda pariatur cupiditate eveniet quas
                  recusandae ducimus fugiat, mollitia saepe commodi alias
                  libero.
                </p>
              </div>
          </div>

          <div className="accordion-item">
            <div
              onClick={() => handleAccordion(3)}
              className="accordion-header"
            >
              <div className="accordion-title">
                <img src={schoolsIcon} alt="" />
                Schools
              </div>

              <div className="accordion-arrow">
                <img
                  className={`${accordion == 3 ? "active" : ""}`}
                  src={accordionArrow}
                  alt=""
                />
              </div>
            </div>

            <div className={`accordion-body ${accordion == 3 ? "active" : ""}`}>
                <p>
                  Lorem ipsum dolor, sit amet consectetur adipisicing elit. Eius
                  reiciendis eum expedita voluptatibus ratione obcaecati
                  laboriosam tempora assumenda pariatur cupiditate eveniet quas
                  recusandae ducimus fugiat, mollitia saepe commodi alias
                  libero.
                </p>
              </div>
          </div>
        </div>
      </div>

      <div className="profile-view">
        <div className="profile-view-title">
          <div className="sidebar-icon">
            <img src={pieChartIcon} alt="" />
          </div>

          <h2>Profile view</h2>
        </div>

        <div className="profile-view-body">
          <div className="profile-view-total">
            <h4>Total view</h4>
            <p>From 1-6 Dec 2021</p>
          </div>
          <img src={profileView} alt="" />
        </div>
      </div>
    </NewSidebarStyle>
  );
}

export default ProfileSidebar;

const NewSidebarStyle = styled.div`
  .recomendations {
    width: 310px;

    border-radius: 20px;
    background: #fff;
    margin-bottom: 24px;
    .recomendations-title {
      padding: 24px;
      display: flex;
      gap: 16px;
      align-items: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #cdd5df;
      h2 {
        margin: 0px;
        color: #202939;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
      }

      .sidebar-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ffd400;
        text-align: center;
        line-height: 50px;
      }
    }

    .accordion-box {
      padding: 24px;
      padding-top: 0px;
      .accordion-item {
        margin-top: 24px;

        .accordion-header {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 8px 16px;
          color: #202939;
          font-family: "Euclid";
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px; /* 175% */
          .accordion-title {
            display: flex;
            align-items: center;
            gap: 12px;
          }

          .accordion-arrow {
            img {
              transition: 0.3s;
              &.active {
                transform: rotate(180deg);
              }
            }
          }
        }

        .accordion-body {
          max-height: 0px;
          overflow: hidden;
          transition:0.4s ease;
          &.active {
            max-height:320px;
          }
        }
      }
    }
  }

  .profile-view {
    width: 310px;
    /* height: 400px; */
    border-radius: 20px;
    background: #fff;
    .profile-view-title {
      padding: 24px;
      display: flex;
      gap: 16px;
      align-items: center;
      padding-bottom: 24px;
      border-bottom: 1px solid #cdd5df;
      h2 {
        margin: 0px;
        color: #202939;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
      }

      .sidebar-icon {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ffd400;
        text-align: center;
        line-height: 50px;
      }
    }

    .profile-view-body {
      padding: 24px;
      .profile-view-total {
        margin-bottom: 24px;
        h4 {
          color: #000;
          font-family: "Inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          line-height: 30px; /* 150% */
          margin: 0px;
          margin-bottom: 4px;
        }

        p {
          opacity: 0.5;
          color: #000;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
        }
      }
    }
  }
`;
