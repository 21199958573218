import {
  Button,
  Checkbox,
  FileInput,
  Input,
  Skeleton,
  TextInput,
} from "@mantine/core";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../Helpers/Context";
import styled from "styled-components";
import parse from "html-react-parser";
import { RichTextEditor } from "@mantine/rte";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

const AnswerSection = ({ props }) => {
  const { showPart } = props;
  const { user } = useContext(AppContext);

  const [editAnswer, setEditAnswer] = useState(false);

  const [textInput, setTextInput] = useState("");
  const [linkInput, setLinkInput] = useState("");

  const [currentAnswer, setCurrentAnswer] = useState(null);

  const { data: userAnswers } = useQuery(
    ["userAnswers", showPart?.section?.id],
    async () =>
      await axios
        .get(
          `/hackathon/section/parts/answer/${showPart?.entry?.id}/?user_id=${user?.id}&section_id=${showPart?.section?.id}`
        )
        .then((res) => res.data),
    {
      enabled: !!showPart?.section?.id,
    }
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (userAnswers) {
      let answer = userAnswers?.find((answer) => {
        return answer?.section_part === showPart?.entry?.id;
      });
      setCurrentAnswer(answer);
      setTextInput(answer?.answer_text);
      setLinkInput(answer?.answer_link);
    }
    return () => {
      setCurrentAnswer(null);
    };
  }, [userAnswers]);

  const {
    mutate: mutateSend,
    isLoading: sendingAnswer,
    isError: errorSendingAnswer,
    error: errorAnswer,
  } = useMutation(
    ["sendAnswer"],
    async (e) => {
      e.preventDefault();

      if (
        textInput?.length > 0 ||
        linkInput?.length > 0 ||
        e.target.answerFile.files[0] !== undefined
      ) {
        let formX = new FormData();
        formX.append("user", user?.id);
        formX.append("section", showPart?.section?.id);
        formX.append("section_part", showPart?.entry?.id);
        formX.append("answer_text", textInput);
        formX.append("answer_link", linkInput);
        if (e.target.checkbox.checked) {
          formX.append("is_finish", true);
        }
        if (e.target.answerFile.files[0] !== undefined) {
          formX.append("answer_file", e.target.answerFile.files[0]);
        }
        if (showPart?.entry?.type === "chart") {
          formX.append("section_chart", showPart?.entry?.id);
        }
        if (showPart?.entry?.type === "video") {
          formX.append("section_video", showPart?.entry?.id);
        }
        if (showPart?.entry?.type === "text") {
          formX.append("section_text", showPart?.entry?.id);
        }

        if (currentAnswer?.id) {
          let response = await axios.patch(
            `/hackathon/section/parts/answer/edit/${currentAnswer?.id}/`,
            formX
          );
          return response.data;
        }

        if (!currentAnswer?.id) {
          let response = await axios.post(
            "/hackathon/section/parts/answer_create/",
            formX
          );
          return response.data;
        }

        queryClient.invalidateQueries(["userAnswers", showPart?.section?.id]);
      }
    },
    {
      onSuccess: () => {
        setEditAnswer(false);
        queryClient.invalidateQueries(["userAnswers", showPart?.section?.id]);
      },
    }
  );

  if (currentAnswer === null) {
    return (
      <>
        <br />
        <br />
        <Skeleton width="750px" height="50px" mb={20} />
        <Skeleton width="750px" height="100px" />
      </>
    );
  }

  if (
    editAnswer === false &&
    currentAnswer !== undefined &&
    currentAnswer !== null &&
    (currentAnswer?.section_text === showPart?.entry?.id ||
      currentAnswer?.section_video === showPart?.entry?.id ||
      currentAnswer?.section_chart === showPart?.entry?.id)
  ) {
    return (
      <AnswerStyle>
        <div className="wrapperX">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0rem 0 2rem 0",
            }}
          >
            <h2 style={{ margin: "0" }}>User answer</h2>
            <ins
              style={{
                fontWeight: "lighter",
                fontSize: "14px",
                margin: ".25rem 0 0 .5rem",
                color: "#555",
                textDecoration: "none",
              }}
            >
              [{currentAnswer?.created_at.substring(0, 10)} {"-"}{" "}
              {currentAnswer?.created_at.substring(11, 16)}]
            </ins>
          </div>
          {currentAnswer?.is_finish === false && (
            <Button onClick={() => setEditAnswer(true)}>Edit</Button>
          )}
          {currentAnswer?.answer_text && parse(currentAnswer?.answer_text)}
          {currentAnswer?.answer_link && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "1rem",
              }}
            >
              <p style={{ margin: "0 0.5rem 0 0" }}>Link: </p>
              <a
                target="_blank"
                rel="noreferrer"
                href={currentAnswer?.answer_link}
              >
                {currentAnswer?.answer_link}
              </a>
            </div>
          )}
          {currentAnswer?.answer_file && (
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <p style={{ margin: "0 0.5rem 0 0" }}>File: </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={currentAnswer?.answer_file}
                >
                  {currentAnswer?.answer_file?.substring(100, 200)}
                </a>
              </div>
            </div>
          )}
        </div>
      </AnswerStyle>
    );
  }

  return (
    <div>
      <hr />
      <AnswerFormStyle>
        <form onSubmit={(e) => mutateSend(e)} style={{ width: "60%" }}>
          <h2>Submit your answer</h2>
          {/* <p style={{ color:"red"}}>You can only submit your answer once! Act accordingly.</p> */}
          <Input.Wrapper label="Answer">
            <RichTextEditor
              value={textInput}
              onChange={(e) => setTextInput(e)}
            />
          </Input.Wrapper>
          <br />
          <TextInput
            label="Link"
            placeholder="Answer link"
            value={linkInput}
            onChange={(e) => setLinkInput(e.target.value)}
          />
          <br />
          {currentAnswer?.answer_file && (
            <a
              target="_blank"
              rel="noreferrer"
              href={currentAnswer?.answer_file}
            >
              {currentAnswer?.answer_file?.substring(100, 200)}
            </a>
          )}
          <FileInput
            name="answerFile"
            placeholder="Pick a file"
            label={currentAnswer?.answer_file ? "Change file" : "File upload"}
          />
          <br />
          {errorSendingAnswer && (
            <div>
              <p style={{ color: "red" }}>{errorAnswer?.message}</p>
            </div>
          )}
          <Input.Wrapper label="Final version">
            <Checkbox
              name="checkbox"
              label="By checking this box, you will not be able to edit your answer."
              color="ts"
              styles={{
                root: {
                  border: "1px solid #ddd",
                  padding: ".5rem",
                  borderRadius: ".25rem",
                  backgroundColor: "#fff",
                },
                icon: { color: "#555" },
                label: { display: "flex" },
              }}
            />
          </Input.Wrapper>
          <Button
            fullWidth
            loading={sendingAnswer}
            className="submitAnswer"
            type="submit"
          >
            Submit
          </Button>
          {editAnswer && (
            <Button
              onClick={() => setEditAnswer(false)}
              mt={10}
              fullWidth
              color="gray.8"
              loading={sendingAnswer}
              type="button"
            >
              Cancel
            </Button>
          )}
        </form>
      </AnswerFormStyle>
    </div>
  );
};

export default AnswerSection;

const AnswerStyle = styled.div`
  margin-top: 2rem;
  .wrapperX {
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2 {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider {
      margin-top: 1rem;
      p {
        margin-top: 0;
      }
    }
    button {
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      &:hover {
        background-color: var(--yellow-shadow);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .wrapperX {
      max-width: 100%;
      padding: 1.5rem;
    }
  }
`;

const AnswerFormStyle = styled.div`
  margin-top: 2rem;
  form {
    background-color: #e3ebf5;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2 {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider {
      margin-top: 1rem;
      p {
        margin-top: 0;
      }
    }
    .submitAnswer {
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      border-radius: 0.25rem;
      color: #000;
      font-weight: 500;
      &:hover {
        background-color: var(--yellow-shadow);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    border-bottom: 1px solid #eee;
    form {
      background: none;
      padding: 1.5rem 0rem;
      min-width: 100%;
      margin: 0 auto;
      border-radius: 0.5rem;
      border: none;
      h2 {
        margin-top: 0;
        margin-bottom: 2rem;
      }
      .slider {
        margin-top: 1rem;
        p {
          margin-top: 0;
        }
      }
      .submitAnswer {
        width: 100%;
        background-color: var(--yellow);
        padding: 0.5rem 3rem;
        margin-top: 1.5rem;
        border: 0;
        font-size: 15px;
        border-radius: 0.25rem;
        &:hover {
          background-color: var(--yellow-shadow);
        }
      }
    }
  }
`;
