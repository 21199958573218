import React, { useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../Helpers/Context";
//import icons
import close from "../../Assets/SVGs/sidebar/close-sidebar.svg";
import home from "../../Assets/SVGs/sidebar/home.svg";
import profile from "../../Assets/SVGs/sidebar/profile.svg";
import internship from "../../Assets/SVGs/sidebar/internship.svg";
import bootcamp from "../../Assets/SVGs/sidebar/internship.svg";
import commmunities from "../../Assets/SVGs/sidebar/communities.svg";
import skillBuilding from "../../Assets/SVGs/sidebar/skillbuilding.svg";
import hackathons from "../../Assets/SVGs/sidebar/hacakthons.svg";
import simulations from "../../Assets/SVGs/sidebar/simulations.svg";

function NewSidebar() {
  const { newSidebarOpen, setNewSidebarOpen } = useContext(AppContext);
  return (
    <NewNavbarStyle>
      <div className={`sidebar-overlay ${newSidebarOpen ? "active" : ""}`}>
        <div className={`sidebar ${newSidebarOpen ? "active" : ""}`}>
          <div className="sidebar-header">
            <div className="menu">Menu</div>

            <div
              onClick={() => setNewSidebarOpen(!newSidebarOpen)}
              className="close-sidebar"
            >
              <img src={close} alt="" />
            </div>
          </div>

          <div className="sidebar-menu">
            <ul>
              <li className="active">
                <img src={home} alt="" />
                Home
              </li>
              <li>
                <img src={profile} alt="" />
                Profile
              </li>
              <li>
                <img src={skillBuilding} alt="" />
                Skillbuiding
              </li>
              <li>
                <img src={internship} alt="" />
                Internship
              </li>
              <li>
                <img src={commmunities} alt="" />
                Communities
              </li>
              <li>
                <img src={hackathons} alt="" />
                Hackathons
              </li>
              <li>
                <img src={bootcamp} alt="" />
                Bootcamp
              </li>
              <li>
                <img src={simulations} alt="" />
                Simulations
              </li>
            </ul>
          </div>
        </div>
      </div>
    </NewNavbarStyle>
  );
}

export default NewSidebar;

const NewNavbarStyle = styled.div`
  * {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
  }
  .sidebar-overlay {
    z-index: 99;
    background-color: #0000005a;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    transition: 0.3s;
    opacity:0;
    visibility: hidden;

    &.active {
      opacity: 1;
      visibility:visible;
    }

    .sidebar {
      background: #fff;
      height: 100vh;
      width:0px;
      padding: 18px 12px;
      transition: 0.3s; /* Added transition property */
      overflow: hidden;
      &.active {
      width: 280px;

      }

      .sidebar-header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;
        .menu {
          color: #121926;
          font-family: "Inter";
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 28px; /* 155.556% */
        }

        .close-sidebar {
          cursor: pointer;
        }
      }

      .sidebar-menu {
        ul {
          list-style: none;
          li {
            display: flex;
            align-items: center;
            padding: 8px 16px;
            margin-bottom: 8px;

            color: #121926;
            font-family: "Euclid";
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 175% */
            cursor: pointer;
            transition: 0.3s ease;
            border-radius: 8px;

            &.active {
              background: #ffe500;
            }
            :hover {
              background: #ffe500;
            }
            img {
              margin-right: 12px;
            }
          }
        }
      }
    }

  }
`;
