import axios from "axios";
import { Button, TextInput } from "@mantine/core";
import { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { UlduzumStyle } from "./PaymentStyle";

export function Ulduzum({ props }) {

  const { entryData, phoneNumber, setPhoneNumber, ulduzumResponse, setUlduzumResponse, noUlduzumPromo, setNoUlduzumPromo, setCoursePrice } = props;

  useEffect(() => {
    if(phoneNumber && phoneNumber.length > 9 && phoneNumber[0] === '0'){
      setPhoneNumber(phoneNumber.substring(1,phoneNumber.length))
    }
  }, [phoneNumber])

  const { mutate:checkPromoMutate, isLoading } = useMutation([""],
    async (e) => {
      e.preventDefault();
      if(phoneNumber){
        const response = await axios.get('https://backend.taskilled.com/api/getpromocodeulduzumpercent/')
        if(noUlduzumPromo === true){
          setNoUlduzumPromo(false);
        }

        setUlduzumResponse(response.data);
        if(response?.data[0]?.discount_percent){
          setCoursePrice(entryData?.price - ((entryData?.price * response?.data[0]?.discount_percent) / 100).toFixed(2))
        } else {
          setCoursePrice(entryData?.price)
        }

        if(response.status === 204){
          setNoUlduzumPromo(true);
        }
      }
    }
  )

  return(
    <UlduzumStyle>

      <form onSubmit={(e) => checkPromoMutate(e)}>
        <TextInput
          id="promo"
          value={phoneNumber}
          placeholder="Enter phone number"
          icon={"+994"}
          onChange={(e) => setPhoneNumber(e.target.value)}
        />
        <Button disabled={isLoading} loading={isLoading} variant="default" onClick={(e) => checkPromoMutate(e)}>Check</Button>
      </form>

      {
        (noUlduzumPromo || ulduzumResponse) &&
        <div className="submit">
          { noUlduzumPromo && <h3 className="noPromo">Ulduzum promo code does not exist</h3>}
          { ulduzumResponse && <h3 className="yesPromo">15% of discount applied!</h3>}
        </div>
      }

    </UlduzumStyle>
  )
}