import axios from "axios";
import { React, useState, useEffect, useContext } from "react";
import {  ShowSidebar } from '../../../Components/Layout/LayoutHelper'
import { AppContext } from "../../../Helpers/Context";
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Plus } from 'tabler-icons-react'
import { SchoolCard } from "../../../Components/SchoolCard";
import { AnimateOpa } from "../../../Components/AnimateOpa";

const AllSchools = () => {

  const { user } = useContext(AppContext);

  const [ schoolAmount, setSchoolAmount ] = useState(7);
  const [ allSchools, setAllSchools ] = useState([]);

  useEffect(() => {

    (async () => {
      try {
        const response = await axios.get(
          `/school/school_list/`
        );

        setAllSchools(response.data);
      } catch (error) {

      }
    })()
    return () => {
      setAllSchools([]);
    }

  }, []);

  return (
    <div>

      <ShowSidebar>
        <AnimateOpa>

          <AllSchoolStyle>
            <div className="wrapperSchools">
              <div className="schoolsHeader">
                {
                  // (user?.user_type?.id === 2 || user?.user_type?.type === "Teacher" ) ?
                  // <h2>Owned Schools</h2>
                  // :
                  <h2>Bootcamps</h2>
                }
                {
                  (user?.user_type?.id === 2 || user?.user_type?.type === "Teacher" ) &&
                  <Link className="new" to="/create-bootcamp">
                    <Plus/>
                    <p>Create a new bootcamp</p>
                  </Link>
                }
              </div>
              <div className="schools">
                {allSchools.map((e, index) => {
                  if (index < schoolAmount && e.id !== 6) {
                    return <SchoolCard key={e.id} school={e} />;
                  }
                })}
              </div>
              {
                allSchools[schoolAmount] &&
                <button
                  className="more"
                  onClick={() => setSchoolAmount(schoolAmount + 8)}
                  style={{
                    backgroundColor: !allSchools[schoolAmount] && "#aaa",
                    cursor: !allSchools[schoolAmount] && "default",
                  }}
                >
                  more
                </button>
              }
            </div>
          </AllSchoolStyle>
        </AnimateOpa>
      </ShowSidebar>

    </div>
  )
}

export default AllSchools

const AllSchoolStyle = styled.div`
  display: flex;
  h2 {
    font-weight: 500;
    margin-top: 0;
  }
  .wrapperSchools {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0rem auto;
    .schoolsHeader {
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      .new {
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: var(--meadow);
        border-radius: 0.3rem;
        display: flex;
        align-items: center;
        padding: 0 0.5rem;
        color: #fff;
        text-decoration: none;
        cursor: pointer;
        transition: all 0.1s;
        ins {
          padding: 0.35rem 0.15rem 0.15rem 0.35rem;
        }
        p {
          padding: 0 0.5rem;
          font-size: 16px;
        }
        :hover {
          background-color: var(--meadow-shadow);
        }
      }
    }
    .schools {
      max-width: 1400px;
      margin: 0rem auto;
      margin-top: 2rem;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .more {
      display: flex;
      margin: 0rem auto;
      padding: 1rem 3rem;
      font-weight: 500;
      color: #fff;
      border-radius: 0.3rem;
      font-size: 14px;
      background-color: var(--purple);
      &:hover {
        background-color: var(--purple-shadow);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
    .wrapperSchools {
      display: block;
      .schoolsHeader {
        margin-bottom: 2rem;
        display: block;
      }
      .schools {
        width: 100%;
        margin: 0 auto;
        margin-bottom: 2rem;
        .cardWrapper {
          background-color: transparent;
          div {
            display: block;
          }
        }
      }
    }
  }
`