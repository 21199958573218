import { Button } from "@mantine/core";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { FreeCourseStyle } from "./PaymentStyle";

export function FreeCourse({ props }){

  const { type, texts, links, userLoggedIn, user, entryData } = props;

  const navigate = useNavigate();

  async function buyFreeCourse(){

    const formX = new FormData();
    if(type === "internship"){
      formX.append("intershipinfo", entryData?.id);
    }
    if(type === "course"){
      formX.append("course", entryData?.id);
    }
    if(type === "hackathon"){
      formX.append("hackathon", entryData?.id);
    }
    formX.append("user", user?.id);
    // formX.append("team", 2);
    formX.append("is_free", true);
    formX.append("promocode", "");

    const response = await axios.post(links.create_order , formX)
    if(response.status === 201){
      if(type === "internship"){
        navigate(`/internship/${entryData?.id}`)
      }
      if(type === "course"){
        navigate(`/course/${entryData?.id}`)
      }
    }

  }

  return(
    <FreeCourseStyle>

      {/* <div className="price">
        <ins>Price:</ins>
        <h2 style={{marginTop:"0rem"}}>FREE</h2>
      </div> */}

      {
        userLoggedIn ?
        <Button fullWidth color="gray.7" size="lg" onClick={() => buyFreeCourse()}>{texts.apply}: FREE</Button>
        :
        <Button fullWidth size="lg" className="loggedOutPayButton" component={Link} to="/login">{texts.log_in}</Button>
      }

    </FreeCourseStyle>
  )
}