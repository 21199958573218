import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { AppContext } from "../../Helpers/Context";
import axios from "axios";
import styled from "styled-components";
import { useQuery } from "@tanstack/react-query";
import { NotificationsProvider } from "@mantine/notifications";
import { SkeletonLoader } from "../Loaders/SkeletonLoader";
import { AboutAuthor } from "./AboutAuthor";
import { ProgramFiller } from "./ProgramFiller";
import { ProgramInfo } from "../ProgramInfo/ProgramInfo";
import { BuyProgramFiller } from "./BuyProgramFiller";
import { ProgramSyllabus } from "../ProgramInfo/Syllabus/_Syllabus";
import { motion } from "framer-motion";
import { AnimateOpa } from "../AnimateOpa";

export const Program = ({ props }) => {
  const { isTaskool, type } = props;

  const { id } = useParams();
  const { user } = useContext(AppContext);
  const [entryData, setEntryData] = useState([]);

  window.scrollTo(0, 0, "smooth");

  useQuery(
    [
      `${
        type === "course"
          ? "course-info"
          : type === "hackathon-info"
          ? ""
          : "internship-info"
      }`,
      id,
    ],
    async () =>
      await axios.get(
        type === "course"
          ? `/api/course/${id}/`
          : type === "hackathon"
          ? `/hackathon/hackathonbyid/${id}/`
          : `/internship/${id}/`
      ),
    {
      onSuccess: (data) => {
        setEntryData(
          type === "course" || type === "hackathon" ? data.data[0] : data.data
        );
      },
    }
  );

  if (entryData?.is_shared === undefined) {
    return (
      <NotificationsProvider>
        <LoadContent>
          <div className="loadWrap">
            <div className="loadX">
              {[...Array(3)].map((e, i) => {
                return (
                  <motion.div
                    animate={{ y: [100, 0] }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                  >
                    <SkeletonLoader
                      key={i}
                      width="100%"
                      height={100}
                      mb={40}
                      visible={true}
                    />
                  </motion.div>
                );
              })}
            </div>
            <div className="loadX">
              {[...Array(4)].map((e, i) => {
                return (
                  <motion.div
                    animate={{ y: [100, 0] }}
                    transition={{ ease: "easeOut", duration: 0.3 }}
                  >
                    <SkeletonLoader
                      key={i + "x"}
                      width="100%"
                      height={140}
                      mb={40}
                      visible={true}
                    />
                  </motion.div>
                );
              })}
            </div>
          </div>
        </LoadContent>
      </NotificationsProvider>
    );
  }

  if (entryData?.is_shared !== undefined) {
    return (
      <AnimateOpa>
        <NotificationsProvider>
          <div style={{ borderTop: "1px solid #eee" }}>
            <ProgramInfo props={{ user, type, entryData }} />
            <ProgramSyllabus props={{ user, type, entryData, setEntryData }} />
            <ProgramFiller
              props={{ isTaskool, course: false, internship: true }}
            />
            <AboutAuthor props={{ entryData, type }} />
            <BuyProgramFiller props={{ user, entryData }} />
          </div>
        </NotificationsProvider>
      </AnimateOpa>
    );
  }
};

const LoadContent = styled.div`
  border-top: 1px solid #eee;
  .loadWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1.5rem;
    justify-content: center;
    width: 90%;
    padding: 3rem 0;
    margin: 0 auto;
  }
  @media (max-width: 768px) {
    .loadWrap {
      grid-template-columns: 1fr;
      grid-gap: 0rem;
      padding: 2rem 0;
    }
  }
`;
