import React, { useEffect, useState } from "react";
import axios from "../../../Helpers/Axios";
import styled from "styled-components";
import { Group, Title } from "@mantine/core";

const Grade = ({ props }) => {
  const { type, userAnswer } = props;
  const [grade, setGrade] = useState({ text: "", point: 0 });
  console.log("grade", grade);
  async function getGrade() {
    if (userAnswer?.id) {
      try {
        const response = await axios.get(
          `/grade/${type}/answer/${userAnswer?.id}/`
        );
        if (response?.data.id) {
          setGrade({
            text: response.data.description,
            point: response.data.grade,
            id: response.data.id,
          });
        }
      } catch (error) {}
    }
  }
  useEffect(() => {
    getGrade();
  }, []);

  if (!grade.point) {
    return null;
  }
  return (
    <GradeStyle>
      <div className="gradeWrapper">
        <div className="header">
          <h2>Grade</h2>
        </div>
        <Group noWrap align="center">
          <Title size={18} weight={500}>Text:</Title>
          <div dangerouslySetInnerHTML={{ __html: grade.text }} />
        </Group>
        <Title size={18} weight={500}>Point: {grade?.point}</Title>
      </div>
    </GradeStyle>
  );
};

export default Grade;

const GradeStyle = styled.div`
  margin-top: 2rem;
  .gradeWrapper {
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    .header {
      margin-bottom: 2rem;
      p {
        color: blue;
        cursor: pointer;
        max-width: max-content;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
      h3 {
margin :0;
padding 0;
      }
    }
    .slider {
      margin-top: 1rem;
      p {
        margin-top: 0;
      }
    }
    .subBut {
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      cursor: pointer;
      border: 0;
      font-size: 15px;
      &:hover {
        background-color: var(--yellow-shadow);
      }
    }
  }
`;
