import { useEffect } from "react"
import CertEmpty from "../Assets/Images/certEmpty.png"
import logoX from "../Assets/Images/taskilledShadowLogo.png"
import virtualCard from "../Assets/Images/certPartners/virtualCard.png"
import cib from "../Assets/Images/certPartners/cib.png"
import kapital from "../Assets/Images/certPartners/kapital.png"
import emobile from "../Assets/Images/certPartners/emobile.png"
import fakir from "../Assets/Images/certPartners/fakir.png"
import logix from "../Assets/Images/certPartners/logix.png"
import hafele from "../Assets/Images/certPartners/hafele.png"
import { Button, Modal } from "@mantine/core"
import { Download } from "tabler-icons-react"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"

export default function PDFCert({ props }){

  const { setShowCertificate, username, programName, programPartners } = props

  const { data:certCode } = useQuery(["certification code"], async () => {
    return await axios.get("/user/getcertificatecode/").then(res => res.data)
  })

  useEffect(() => {
    createCert()
    setTimeout(() => {
      createCert()
    }, 500);
  }, [certCode])

  const certTemplate = new Image();
  certTemplate.src = CertEmpty
  certTemplate.onload = () => {createCert()}

  function createCert(){

    let partners = []

    programPartners.map(partner => {
      if(partner.name === "Cib"){
        const cibX = new Image();
        cibX.src = cib
        partners.push(cibX)
      }
      if(partner.name === "Kapital Bank"){
        const kapitalX = new Image();
        kapitalX.src = kapital
        partners.push(kapitalX)
      }
      if(partner.name === "Fakir"){
        const fakirX = new Image();
        fakirX.src = fakir
        partners.push(fakirX)
      }
    })

    const logo = new Image();
    logo.src = logoX

    // const vcard = new Image();
    // vcard.src = virtualCard
    // partners.push(vcard)

    // const logixX = new Image();
    // logixX.src = logix
    // partners.push(logixX)

    // const emobileX = new Image();
    // emobileX.src = emobile
    // partners.push(emobileX)

    // const hafeleX = new Image();
    // hafeleX.src = hafele
    // partners.push(hafeleX)

    const canvas = document?.getElementById("skcanvas")
    const ctx = canvas?.getContext("2d");

    let cw = 1559
    let ch = 1102

    if(ctx){

      ctx.drawImage(certTemplate, 0, 0, cw, ch)

      ctx.drawImage(logo, (cw / 2) - (logo?.width / (4.2 * 2)), 300, logo?.width / 4.2, logo?.height / 4.2)

      ctx.font = '100 24px monospace'
      ctx.fillStyle = '#67748d'
      ctx.textAlign = 'center'
      certCode && ctx.fillText(`Certification code: ${certCode[0]?.code}`, cw / 2, 160);

      ctx.font = '100 36px monospace'
      ctx.fillStyle = '#424b5c'
      ctx.textAlign = 'center'
      ctx.fillText("Certificate presented to", cw / 2, (ch / 2) - 60)

      ctx.font = 'bold 100px sans-serif'
      ctx.fontWeight = '600'
      ctx.fillStyle = '#1d2f51'
      ctx.textAlign = 'center'
      ctx.fillText(username, cw / 2, (ch / 2) + 60)

      ctx.font = '40px sans-serif'
      ctx.fillStyle = '#424b5c'
      ctx.textAlign = 'center'
      ctx.width = '400px'
      ctx.fillText(`For successfully completing online "${programName}"`, cw / 2, (ch / 2) + 185)
      ctx.fillText(`internship by expert review`, cw / 2, (ch / 2) + 245)

      const sp = 270;
      const ep = 1050;
      const xp = ep - sp

      if( partners?.length > 0){

        let allImagesWidth = 0
        let container = xp / partners.length

        partners?.map((image, index) => {

          let padding = index * 30

          if(partners.length > 1){
            allImagesWidth += image.width
          }

          let formula
          if(partners.length === 1){
            formula = ( container + allImagesWidth - image.width / 2 + padding )
          } else if (partners.length === 2){
            formula = ( container * 1.4 + allImagesWidth - image.width + padding )
          } else if (partners.length === 3){
            formula = ( container * 1.6 + allImagesWidth - image.width + padding )
          } else if (partners.length === 4){
            formula = ( container * 1.8 + allImagesWidth - image.width + padding )
          } else if (partners.length === 5){
            formula = ( container * 1.9 + allImagesWidth - image.width + padding )
          }

          ctx.drawImage( image, formula, 950, image?.width, image?.height )
        })
      }

    }
  }

  function downloadImg(){
    var link = document.createElement('a');
    link.download = `${username} - ${programName} certificate`;
    link.href = document.getElementById('skcanvas').toDataURL()
    link.click();
  }

  return (
    <Modal
      opened={true}
      size={1000}
      withCloseButton={false}
      lockScroll
      padding={0}
      onClose={() => setShowCertificate(false)}
    >
      <div id="certStyle">
        <canvas id="skcanvas" width={1559} height={1102}/>
        <Button size="xl" variant="" color="gray" onClick={downloadImg} leftIcon={<Download size={45}/>}>Download</Button>
      </div>
    </Modal>
  )
}