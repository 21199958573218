import {
  Button,
  Center,
  Container,
  Divider,
  Group,
  Image,
  Loader,
  Menu,
  Modal,
  Spoiler,
  Text,
  useMantineTheme,
} from "@mantine/core";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import axios from "axios";
// import { AnimatePresence, motion } from "framer-motion"
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { useContext, useRef, useState, useEffect } from "react";
import {
  Clock,
  DotsVertical,
  ThumbUp,
  Trash,
  X,
  ChevronRight,
} from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";
import { CommentSection } from "./CommentSection";
import { ProgramCard } from "./ProgramCard";
import LinkPreview from "./LinkPreview";
import { AnimatePresence, motion } from "framer-motion";
import { useParams } from "react-router-dom";
import { UserCardImage } from "./Communities/CommunityCard";
import { Link } from "react-router-dom";
import { ShowSidebar } from "./Layout/LayoutHelper";

export function Post() {
  TimeAgo.addLocale(en);
  const timeAgo = new TimeAgo("en-US");
  const queryClient = useQueryClient();
  const { user } = useContext(AppContext);

  const { id } = useParams();

  const { data: post } = useQuery(
    [`get post`],
    async () => (await axios.get(`/blog/blog/${id}`)).data[0]
  );

  // console.log(post);

  let [postReview, setPostReview] = useState({
    current: 0,
    count: 0,
  });

  useEffect(() => {
    setPostReview({
      current: post?.user_like,
      count: post?.likes_c,
    });
  }, [post]);

  const { data: comments, refetch } = useQuery(
    [`get comments ${post?.id}`],
    async () =>
      axios.get(`/blog/comments/?post_id=${post?.id}`).then((res) => res?.data)
  );

  const { mutate: deletePost, isLoading } = useMutation(
    ["delete post"],
    async () => await axios.delete(`/blog/${post?.id}/`),
    { onSuccess: () => queryClient.invalidateQueries([`feed`]) }
  );

  const { data: communityOfPost } = useQuery(
    [`community ${post?.community ? post?.community : ""}`],
    () =>
      axios
        .get(`/blog/communitywithid/${post?.community ? post?.community : ""}/`)
        .then((res) => res.data[0])
  );

  const { mutate: ratePost } = useMutation(
    ["rate post"],
    async () => {
      if (postReview.current === false) {
        setPostReview((prev) => ({ current: true, count: prev.count + 1 }));
      } else {
        setPostReview((prev) => ({ current: false, count: prev.count - 1 }));
      }
      return await axios.patch(
        `/blog/${post?.id}/?${
          post?.user_like === true ? "dislikes" : "likes"
        }=${user?.id}`
      );
    }
    // { onSuccess: () => queryClient.invalidateQueries("feed") }
  );

  const [imageFull, setImageFull] = useState(false);

  if (post == undefined) {
    return (
      <ShowSidebar>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader size={"xl"} type="dots" color="yellow" />
        </div>
      </ShowSidebar>
    );
  }

  if (post?.community && communityOfPost?.member == false) {
    return (
      <ShowSidebar>
        <div>
          <Text
            style={{ textAlign: "center", fontSize: "28px", marginTop: "20px" }}
          >
            To see the post of{" "}
            <span style={{ color: "#F2C94C" }}>"{communityOfPost?.name}"</span>{" "}
            community, please join:
          </Text>
          <div style={{ width: "300px", margin: "0 auto", padding: "100px 0" }}>
            <UserCardImage
              key={communityOfPost?.id}
              name={communityOfPost?.name}
              description={communityOfPost?.description}
              image={communityOfPost?.image}
              coverImage={communityOfPost?.cover_image}
              membersCount={communityOfPost?.members?.length}
              link={`/communities/${communityOfPost?.id}`}
              component={Link}
            />
          </div>
        </div>
      </ShowSidebar>
    );
  }

  return (
    <AnimatePresence mode="wait">
      <ShowSidebar>
        <div
          style={{
            border: "1px solid #DEE2E6",
            backgroundColor: "#fff",
            padding: ".5rem",
            borderRadius: "0.75rem",
          }}
        >
          <Group
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              gap: "0.5rem",
              padding: ".5rem",
            }}
          >
            <Group>
              <Link
                style={{
                  color: "black",
                  textDecoration: "none",
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "0.5rem",
                  alignItems: "center",
                }}
                to={`/profile/${post?.user?.guid}`}
              >
                <Image
                  radius={100}
                  src={post?.user?.profile_img}
                  width={45}
                  height={45}
                  withPlaceholder
                />
                <div>
                  <Text size={15}>
                    {post?.user?.first_name}{" "}
                    {post?.user?.last_name !== "." && post?.user?.last_name}
                  </Text>
                  <Group style={{ gap: "0.25rem" }}>
                    <Clock color="gray" size={14} />
                    <Text size={14} color="dimmed">
                      {timeAgo.format(new Date(post?.created_at))}
                    </Text>
                  </Group>
                </div>
              </Link>
              {post?.community && communityOfPost?.member == true && (
                <>
                  <ChevronRight />
                  <Link
                    style={{
                      color: "black",
                      textDecoration: "none",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "0.5rem",
                      alignItems: "center",
                    }}
                    to={`/communities/${communityOfPost?.id}`}
                  >
                    <Image
                      radius={100}
                      src={communityOfPost?.image}
                      width={35}
                      height={35}
                      withPlaceholder
                    />
                    <div>
                      <Text style={{ fontWeight: 500 }} size={15}>
                        {communityOfPost?.name}{" "}
                      </Text>
                    </div>
                  </Link>
                </>
              )}
            </Group>

            {post?.user?.id === user?.id && (
              <div>
                <Menu trigger="hover">
                  <Menu.Target>
                    <Button px={12} variant="subtle" color={"gray.8"}>
                      <DotsVertical color="#495057" />
                    </Button>
                  </Menu.Target>

                  <Menu.Dropdown>
                    <Menu.Item
                      color={"red"}
                      icon={
                        isLoading ? (
                          <Loader size={"sm"} color="red" />
                        ) : (
                          <Trash />
                        )
                      }
                      onClick={() => deletePost()}
                    >
                      Delete
                    </Menu.Item>
                  </Menu.Dropdown>
                </Menu>
              </div>
            )}
          </Group>

          <Container my={20} px={10}>
            {post?.video && post?.video.includes("youtube.com/watch?") ? (
              <iframe
                src={`https://www.youtube.com/embed/${
                  post.video.split("/watch?v=")[1].split("&t=")[0]
                }`}
                allow="autoplay; encrypted-media"
                allowFullScreen
                width="100%"
                height="320"
                title="video"
                style={{ border: "0", borderRadius: ".25rem" }}
              />
            ) : (
              // :
              // post?.video && post?.video.includes("youtube.com/shorts") ?
              // <iframe src={`https://www.youtube.com/embed/${post?.video}`}
              //   allow='autoplay; encrypted-media'
              //   allowFullScreen
              //   width="100%" height="320"
              //   title='video'
              //   style={{ border:"0", borderRadius:".25rem" }}
              // />
              post?.video && <a href={post?.video}>{post?.video}</a>
            )}

            {post?.description !== "." && (
              <Spoiler
                mt={-10}
                mb={10}
                maxHeight={120}
                showLabel="Show more"
                hideLabel="Hide"
                styles={{ control: { color: "#868E96" } }}
              >
                {post?.description.startsWith("http://") ||
                post?.description.startsWith("https://") ? (
                  <LinkPreview url={post?.description} />
                ) : (
                  <Text size={15}>{post?.description}</Text>
                )}
              </Spoiler>
            )}

            {post?.image && (
              <Center
                mt={5}
                style={{ cursor: "pointer", backgroundColor: "#F1F3F5" }}
                onClick={() => setImageFull(true)}
              >
                <Image
                  fit="contain"
                  height={"100%"}
                  src={post?.image}
                  styles={{ image: { maxHeight: "500px" } }}
                />
              </Center>
            )}

            <Modal
              size="auto"
              opened={imageFull}
              onClose={() => setImageFull(false)}
              withCloseButton={false}
              bg={"0"}
              overlayBlur={"2"}
              overlayColor="#495057"
              shadow="0"
              transitionDuration={200}
              styles={{ modal: { backgroundColor: "#00000000" } }}
            >
              <div
                style={{
                  display: "flex",
                  borderRadius: "0 1rem 1rem 0",
                  backgroundColor: "#f1f3f5",
                  alignItems: "start",
                  gap: "1rem",
                }}
              >
                <Image
                  fit="contain"
                  height={"100%"}
                  src={post?.image}
                  styles={{ image: {} }}
                />
                <div style={{ margin: "1rem 1rem 1rem 0", minWidth: "450px" }}>
                  <Group
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        gap: "0.5rem",
                        padding: ".25rem .5rem",
                      }}
                    >
                      <Image
                        radius={100}
                        src={post?.user?.profile_img}
                        width={45}
                        height={45}
                        withPlaceholder
                      />
                      <div>
                        <Text size={15}>
                          {post?.user?.first_name}{" "}
                          {post?.user?.last_name !== "." &&
                            post?.user?.last_name}
                        </Text>
                        <Group style={{ gap: "0.25rem" }}>
                          <Clock color="gray" size={14} />
                          <Text size={14} color="dimmed">
                            {timeAgo.format(new Date(post?.created_at))}
                          </Text>
                        </Group>
                      </div>
                    </div>
                    {post?.user?.id === user?.id && (
                      <div>
                        <Menu trigger="hover" position="left" radius={"lg"}>
                          <Menu.Target>
                            <Button px={12} variant="subtle" color={"gray.8"}>
                              <DotsVertical color="#495057" />
                            </Button>
                          </Menu.Target>

                          <Menu.Dropdown>
                            <Menu.Item
                              color={"red"}
                              icon={
                                isLoading ? (
                                  <Loader size={"sm"} color="red" />
                                ) : (
                                  <Trash />
                                )
                              }
                              onClick={() => deletePost()}
                            >
                              Delete
                            </Menu.Item>
                          </Menu.Dropdown>
                        </Menu>
                      </div>
                    )}
                  </Group>
                  {post?.description !== "." && (
                    <Spoiler
                      mx=".5rem"
                      my={"md"}
                      maxHeight={120}
                      showLabel="Show more"
                      hideLabel="Hide"
                      styles={{ control: { color: "#868E96" } }}
                    >
                      <Text size={15}>{post?.description}</Text>
                    </Spoiler>
                  )}
                  <Divider mt={"sm"} mb={"lg"} />
                  <CommentSection
                    props={{ post, ratePost, postReview, comments, refetch }}
                  />
                </div>
              </div>
            </Modal>
          </Container>
          {(post?.course || post?.internship) && (
            <Center mb={10}>
              <ProgramCard
                props={{
                  link: `${post?.course ? "/courses/" : "/internships/"}${
                    post?.course ? post?.course?.id : post?.internship?.id
                  }`,
                  image: post?.course?.image || post?.internship?.image,
                  title: post?.course?.title || post?.internship?.title,
                }}
              />
            </Center>
          )}

          <Group ml={10} mb={5}>
            <Text>{postReview.count} people liked this</Text>
          </Group>

          <CommentSection
            props={{
              post,
              ratePost,
              postReview,
              comments,
              refetch,
            }}
          />
        </div>
      </ShowSidebar>
    </AnimatePresence>
  );
}
