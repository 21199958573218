import { Avatar, Text, Group } from "@mantine/core";
import { Phone, Mail } from "tabler-icons-react";
import { useMediaQuery } from "@mantine/hooks";

export const Speaker = ({ speaker }) => {
  const isMobile = useMediaQuery("(max-width: 332px)");

  const { first_name, last_name, profile_img, email } = speaker;

  return (
    <div
      style={{
        border: "0.2px solid gray",
        borderRadius: "10px",
        marginTop: "8px",
      }}
    >
      <Group
        wrap="nowrap"
        style={{
          display: isMobile ? "flex" : "",
          justifyContent: isMobile ? "center" : "",
          textAlign: isMobile ? "center" : "",
          padding: isMobile ? "1rem" : "",
        }}
      >
        <Avatar src={profile_img} size={94} radius="md" />
        <div>
          <Text fz="lg" fw={500}>
            {first_name} {last_name}
          </Text>

          <Group wrap="nowrap" gap={10} mt={3}>
            <Mail size="1rem" />
            <Text fz="xs" c="dimmed">
              {email}
            </Text>
          </Group>
        </div>
      </Group>
    </div>
  );
};
