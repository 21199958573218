import { useContext } from "react";
import { AppContext } from "./Context";
import { Routes, Route, Navigate, BrowserRouter } from "react-router-dom";
import { ShowFooter, ShowNavbar } from "../Components/Layout/LayoutHelper";
// import { AnimatePresence } from "framer-motion";

// Auth
import Login from "../Pages/Authentication/Login/_Login";
import Register from "../Pages/Authentication/Register/_Register";
// import { NewRegister } from "../Pages/Authentication/Register/_NewRegister";
import ResetPasswordSuccess from "../Pages/Authentication/ResetPassword/_ResetPassword";

// Users
import ProfilePage from "../Pages/User/Profile/_Profile";
import PublicProfilePage from "../Pages/User/PublicProfile/_Profile";
import UserSettings from "../Pages/User/UserSettings/_UserSettings";

// Informative Pages
import ErrorPage from "../Pages/Informative/ErrorPage";
import About from "../Pages/Informative/About";
import PrivacyPolicy from "../Pages/Informative/PrivacyPolicy";
import TermsOfService from "../Pages/Informative/TermsAndConds";
import Dashboard from "../Pages/Informative/Dashboard";
import Notifications from "../Pages/Informative/Notifications";
import CurrentVersion from "../Components/CurrentVersion";

// Programs
import { AllContents } from "../Components/AllContents";
import { Program } from "../Components/ProgramInfo/_Program";

// Internship
import InternshipContent from "../Pages/Internship/InternshipContent/_InternshipContent";

// Course
import CourseContent from "../Pages/Course/CourseContent/_CourseContent";

// Game
import Game from "../Pages/Game/SingleGame";

// Schools
import Schools from "../Pages/Schools/AllSchools/_AllSchools";
import SchoolInfo from "../Pages/Schools/SchoolInfo/_SchoolInfo";

// Grade
import { MentorContent } from "../Components/MentorContent/MentorContent";
import Mentoring from "../Pages/Mentoring/Mentoring";

import PDFCert from "../Components/Certification";
import { Partners } from "../Components/Partners";
import { CompanyPage } from "../Components/CompanyPage";

// Informative Pages
import Landing from "../Pages/Informative/Landing/_Landing";
import { CreateCommunity } from "../Components/Communities/CreateCommunity";
import { AllCommunities } from "../Components/Communities/AllCommunities";
import { Community } from "../Components/Communities/Community";
import Teams from "../Components/Teams";
import { AnimatePresence } from "framer-motion";
import AllTeams from "../Components/AllTeams";
import HackathonContent from "../Pages/Hackathon/HackathonContent/_HackathonContent";

// TRY
import { MainHackathon } from "../Pages/MainHackathon/Hackathon";
import { MainHackathonPublic } from "../Pages/MainHackathon/PublicHackathon";
import { HackathonProject } from "../Pages/MainHackathon/Project";
import { CommunitiesInFeedMobile } from "../Components/Communities/CommunitiesInFeed";
import { MobileMenuNew } from "../Components/Layout/Navbar/Menu";

import { AllComponents } from "../Components/HackathonComponents/AllComponents";

import { AllPosts } from "../Components/AllPosts";
import { Post } from "../Components/ScpecificBlogPost";

import CommunityPostPublic from "../Components/RedirectToLogin/CommunityPostPublic";
import Hackathons from "../Pages/MainHackathon/Hackathons";
import NewProfile from "../Pages/User/NewProfile/NewProfile";
import NewPayment from "../Pages/NewPayment/NewPayment";
import ScrollToTop from "../Components/ScrollToTop";
import GoogleLogin from "../Pages/Authentication/Login/GoogleLogin";
import EmailVerify from "../Pages/Authentication/EmailVerify";
import {useQuery} from '@tanstack/react-query'
import { getCookie } from "./useCookie";
import axios from '../Helpers/Axios'

export let isTaskool = false;

export function RouteLogic() {
  let { user ,postURL,setPostURL } = useContext(AppContext);

  const testDesign = false;

  // useQuery(
  //   ["user-data", aTokenX],
  //   async () => {
  //     if (aTokenX) {
  //       const httpClient = axios.create({
  //         headers: {
  //           Authorization: `Bearer ${getCookie("access")}`,
  //         },
  //       });

  //       return await httpClient
  //         .post("api/v1/user-data/")
  //         .then((response) => response.data);
  //     }
  //   },
  //   {
  //     onSuccess: (data) => {
  //       localStorage.setItem("taskooluser", JSON.stringify(data));
  //       // navigate(-2);
  //       // window.location.href = "/";
  //       // if (postURL.length == 0) {
  //       //   window.location.href = "/";
  //       // } else {
  //       //   window.location.href = postURL;
  //       //   setPostURL("");
  //       // }
  //     },
  //     onError: (error) => {
  //       console.log('error: ' + error);
  //     },
  //   },
  //   {
  //     enabled: !!aTokenX,
  //   }
  // );
  return (
    <BrowserRouter>
      <ScrollToTop />
      {!testDesign ? (
        <>
          <ShowNavbar />
          {user?.id ? <PrivatePages /> : <PublicPages />}
          <ShowFooter />
        </>
      ) : (
        <Routes>
          {/* <Route path="/newprofile"  props={{ isTaskool }} element={<NewProfile />} /> */}
          <Route path="/newpayment" element={<NewPayment />} />
        </Routes>
      )}
    </BrowserRouter>
  );
}

function PrivatePages() {
  let { user } = useContext(AppContext);

  // let location = useLocation()

  return (
    <AnimatePresence>
      <Routes>
        {/* Informational Pages */}

        <Route path="/" element={<Dashboard />} />

        {/* Taskool GoogleRegister */}
        <Route path="/google" element={<GoogleLogin />} />

        <Route path="/about" element={<About />} />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route
          path="/partners"
          element={<Partners props={{ showHeader: true }} />}
        />

        <Route path="/terms-of-usage" element={<TermsOfService />} />
        <Route path="/version" element={<CurrentVersion />} />
        <Route path="/notifications" element={<Notifications />} />
        <Route path="*" element={<ErrorPage />} />

        <Route path="/company/:id" element={<CompanyPage props={{}} />} />

        {/* Community */}
        <Route path="/communities" element={<AllCommunities />} />
        <Route path="/communities/:id" element={<Community />} />

        {/* Users */}
        <Route
          path="/profile"
          element={<ProfilePage props={{ isTaskool }} />}
        />
        <Route
          path="/newprofile"
          element={<NewProfile props={{ isTaskool }} />}
        />
        <Route
          path="/profile/:id"
          element={<PublicProfilePage props={{ isTaskool }} />}
        />
        <Route
          path="/settings"
          element={<UserSettings props={{ isTaskool }} />}
        />
        <Route
          path="/password-reset/:uuid/:token"
          element={<ResetPasswordSuccess />}
        />

        {/* Course */}
        <Route
          path="/courses"
          element={
            <AllContents props={{ defaultValue: "all", type: "course" }} />
          }
        />
        <Route
          path="/courses/purchased"
          element={
            <AllContents
              props={{ defaultValue: "purchased", type: "course" }}
            />
          }
        />
        <Route
          path="/courses/:id"
          element={<Program props={{ isTaskool, type: "course" }} />}
        />
        <Route path="/course/:courseId" element={<CourseContent />} />

        <Route
          path="/bootcamp/courses/:id"
          element={<Program props={{ isTaskool, type: "course" }} />}
        />
        <Route path="/bootcamp/course/:courseId" element={<CourseContent />} />

        {/* Teams */}
        {/* <Route
          path="/hackathons"
          element={
            <AllContents props={{ defaultValue: "all", type: "hackathon" }} />
          }
        />
        <Route
          path="/hackathons/purchased"
          element={
            <AllContents
              props={{ defaultValue: "purchased", type: "hackathon" }}
            />
          }
        />
        <Route
          path="/hackathons/:id"
          element={<Program props={{ isTaskool, type: "hackathon" }} />}
        />
        <Route path="/hackathon/:hackathonId" element={<HackathonContent />} />

        <Route path="/teams/:id" element={<Teams />} />
        <Route path="/teams/" element={<AllTeams />} /> */}

        <Route path="/hackathons" element={<Hackathons />} />

        <Route path="/project/:id" element={<HackathonProject />} />

        {/* Posts */}
        <Route path="/posts" element={<AllPosts />} />
        <Route path="/posts/:id" element={<Post />} />

        {/* <Route
          path="/hackathons/purchased"
          element={
            <AllContents
              props={{ defaultValue: "purchased", type: "hackathon" }}
            />
          }
        /> */}
        <Route
          path="/hackathons/:id"
          element={<MainHackathon props={{ isTaskool }} />}
        />
        {/* <Route path="/hackathon/:hackathonId" element={<HackathonContent />} /> */}

        {/* <Route path="/teams/:id" element={<Teams />} /> */}
        {/* <Route path="/teams/" element={<AllTeams />} /> */}

        {/* Internships */}
        <Route
          path="/internships"
          element={
            <AllContents props={{ defaultValue: "all", type: "internship" }} />
          }
        />
        <Route
          path="/internships/purchased"
          element={
            <AllContents
              props={{ defaultValue: "purchased", type: "internship" }}
            />
          }
        />
        <Route
          path="/internships/:id"
          element={<Program props={{ isTaskool, type: "internship" }} />}
        />
        <Route
          path="/internship/:internshipId"
          element={<InternshipContent props={{type: "internship" }} />}
        />

        {/* Game */}
        <Route
          path="/games"
          element={
            <AllContents props={{ defaultValue: "all", type: "game" }} />
          }
        />
        <Route
          path="/games/purchased"
          element={
            <AllContents props={{ defaultValue: "purchased", type: "game" }} />
          }
        />
        <Route path="/games/:id" element={<Game />} />

        {/* Grade */}
        <Route
          path="/mentor/course/:courseId"
          element={<MentorContent props={{ type: "course" }} />}
        />
        <Route
          path="/mentor/internship/:internshipId"
          element={<MentorContent props={{ type: "internship" }} />}
        />
        <Route path="/mentor" element={<Mentoring />} />

        <Route
          path="pdf"
          element={
            <PDFCert
              props={{
                username: `${user?.first_name} ${user?.last_name}`,
                programName: "Digital Marketing",
                certCode: "314159",
              }}
            />
          }
        />

        {/* Schools */}
        <Route path="/bootcamps" element={<Schools />} />
        <Route path="/bootcamp/:id" element={<SchoolInfo />} />

        {/* Redirects to the "/" page if the user is logged in */}
        <Route path="/login" element={<Navigate to="/" replace />} />
        <Route path="/register" element={<Navigate to="/" replace />} />
        {/* <Route path="/email-verify/:token?" element={<EmailVerify />} /> */}
      </Routes>
    </AnimatePresence>
  );
}

function PublicPages() {
  // let location = useLocation()

  return (
    <AnimatePresence>
      <Routes>
        {/* Site Auth */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/email-verify/:token?" element={<EmailVerify />} />


        {/* Taskool GoogleRegister */}
        <Route path="/google" element={<GoogleLogin />} />

        {/* Informative */}
        <Route path="/" element={<Landing />} />
        <Route path="/home" element={<Dashboard />} />
        <Route path="/about" element={<About />} />

        <Route
          path="/partners"
          element={<Partners props={{ showHeader: true }} />}
        />
        <Route path="/privacy_policy" element={<PrivacyPolicy />} />
        <Route path="/terms-of-usage" element={<TermsOfService />} />
        <Route path="/version" element={<CurrentVersion />} />
        <Route path="*" element={<ErrorPage />} />

        {/* Communities */}
        <Route path="/communities" element={<AllCommunities />} />
        <Route path="/communities/:id" element={<Community />} />

        {/* Teams */}
        <Route path="/teams" element={<Teams />} />

        {/* Users */}
        {/* <Route path="/newprofile" element={<NewProfile />} /> */}
        <Route
          path="/profile/:id"
          element={<PublicProfilePage props={{ isTaskool }} />}
        />
        <Route
          path="/password-reset/:uuid/:token"
          element={<ResetPasswordSuccess />}
        />

        <Route
          path="/hackathons"
          element={
            <AllComponents props={{ defaultValue: "all", type: "hackathon" }} />
          }
        />
        {/* <Route
          path="/hackathons/purchased"
          element={
            <AllContents
              props={{ defaultValue: "purchased", type: "hackathon" }}
            />
          }
        /> */}
        <Route
          path="/hackathons/:id"
          element={<MainHackathonPublic props={{ isTaskool }} />}
        />

        {/* Posts */}
        {/* <Route path="/posts" element={<AllPosts />} /> */}
        <Route path="/posts/:id" element={<CommunityPostPublic />} />

        {/* Course */}
        <Route
          path="/courses"
          element={
            <AllContents
              props={{
                defaultValue: "all",
                type: "course",
                showSidebar: false,
              }}
            />
          }
        />
        <Route
          path="/courses/:id"
          element={<Program props={{ isTaskool, type: "course" }} />}
        />

        {/* Internships */}
        <Route
          path="/internships"
          element={
            <AllContents
              props={{
                defaultValue: "all",
                type: "internship",
                showSidebar: false,
              }}
            />
          }
        />
        <Route
          path="/internships/:id"
          element={<Program props={{ isTaskool, type: "internship" }} />}
        />

        {/* Game */}
        <Route
          path="/games"
          element={
            <AllContents
              props={{
                defaultValue: "all",
                type: "game",
                showSidebar: false,
              }}
            />
          }
        />
        <Route path="/games/:id" element={<Game />} />
      </Routes>
    </AnimatePresence>
  );
}
