import { useState, useContext } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Button, LoadingOverlay, Modal, MultiSelect } from '@mantine/core';
import defaultImage from "../../Assets/Images/defaultImage.webp";
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AppContext } from '../../Helpers/Context';
import { X } from 'tabler-icons-react';

export const CourseUsers = ({ props }) => {

  const { setStudentToTest } = props;
  const { user } = useContext(AppContext);

  const courseId = useParams();

  const [ showModal, setShowModal ] = useState(true);

  const queryX = useQueryClient()

  const { data: students } = useQuery(["students"],
    async () => { return await axios.get(`/mentor/course/mentor_detail/${user?.id}/`).then(res => res.data) }
  )

  const { data: nonStudents } = useQuery(["nonStudents"],
    async () => { return await axios.get(`/mentor/course/without_mentor_studentlist/${courseId.courseId}/`).then(res => res.data) }
  )

  const { mutate: addStudent, isLoading:addingStudent } = useMutation(["addStudent"],
    async (userX) => { return await axios.patch(`/mentor/course/${user?.email}/?students=${userX.id}`).then(res => res.data) },
    {
      onSuccess: () => {
        queryX.invalidateQueries("nonStudents")
        queryX.invalidateQueries("students")
      }
    }
  )

  const { mutate: removeStudent, isLoading:removingStudent } = useMutation(["removeStudent"],
    async (userX) => { return await axios.delete(`/mentor/course/${user?.email}/?students=${userX.id}`).then(res => res.data) },
    {
      onSuccess: () => {
        queryX.invalidateQueries("nonStudents")
        queryX.invalidateQueries("students")
      }
    }
  )

  function setUser(user){
    setShowModal(false)
    setStudentToTest(user)
  }

  function closeModal(){
    setShowModal(false)
    window.history.back();
  }

  if(students){
    return (
      <Modal
        title="Mentor students manager"
        opened={showModal}
        onClose={() => closeModal()}
        size={'92%'}
      >
        <div className="mentorStudents" style={{ display:"grid", gridTemplateColumns:"1fr 1fr", gridGap:"2rem" }}>

          <LoadingOverlay visible={removingStudent || addingStudent} loaderProps={{ color: 'var(--yellow)' }}/>

          <div className="listWrapper">
            <h2>Students ({students[0]?.students?.length})</h2>
            <MultiSelect
              data={
                students?.length > 0 ?
                students[0]?.students?.map((user) => {
                  return {
                    value: user?.id,
                    label: `${user?.first_name} ${user?.last_name}`,
                  };
                })
                :
                []
              }
              searchable
              onChange={(e) => {
                setUser(
                  students?.find((user) => user?.id === e[0])
                )}
              }
              label={'Select user'}
              placeholder={'Select user'}
            />
            <br />
            <div className="userListWrapper">
              {
                students?.length > 0 ?
                students[0].students?.map((user, index) => {
                  return (
                    <div key={index} className='userListModal'>
                      <div className="studentDiv">
                        <img src={user?.profile_img?.endsWith('download.png') ? defaultImage : user?.profile_img} alt="profile_img" />
                        <h3>{user?.first_name} {user?.last_name}</h3>
                      </div>
                      <div className="buttons">
                        <Button color="teal" disabled={removingStudent || addingStudent} className="studentButton" onClick={() => setUser(user)}>Open</Button>
                        <Button color="red" disabled={removingStudent || addingStudent} className="studentButton delete" onClick={() => removeStudent(user)}><X size={20}/></Button>
                      </div>
                    </div>
                  )
                }
                )
                :
                <h2>No users have bought this course yet</h2>
              }
            </div>
          </div>

          <div className="listWrapper">
            <h2>Non-students ({nonStudents?.length})</h2>
            <MultiSelect
              data={
                nonStudents?.length > 0 ?
                nonStudents?.map((user) => {
                  return {
                    value: user?.id,
                    label: `${user?.first_name} ${user?.last_name}`,
                  };
                })
                : []
              }
              searchable
              onChange={(e) => setUser(
                nonStudents?.find((user) => user?.id === e[0])
              )}
              label={'Select user'}
              placeholder={'Select user'}
            />
            <br />
            <div className="userListWrapper">
              {
                nonStudents?.length > 0 ?
                  nonStudents?.map((user, index) => {
                    return (
                      <div key={index} className='userListModal'>
                        <div className="studentDiv" onClick={() => setUser(user)}>
                          <img src={user?.profile_img?.endsWith('download.png') ? defaultImage : user?.profile_img} alt="profile_img" />
                          <h3>{user?.first_name} {user?.last_name}</h3>
                        </div>
                        <Button color="gray" disabled={removingStudent || addingStudent} className="add studentButton" onClick={() => addStudent(user)}>Add</Button>
                      </div>
                    )
                  }
                )
                :
                <h2>No users have bought this course yet</h2>
              }
            </div>
          </div>

        </div>
      </Modal>
    )
  }
}