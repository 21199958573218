import React from "react";
import { Card, Image, Text, Avatar, Rating, Stack, Group } from "@mantine/core";
import styled from "styled-components";
import projectPlaceholder from "../../../Assets/Images/project-placeholder.png";
import { Link } from "react-router-dom";

export const ProjectCard = ({ project }) => {
  return (
    <Card
      shadow="sm"
      padding="xl"
      component={Link}
      to={`/project/${project?.id}`}
    >
      <Card.Section>
        <Image
          src={project?.image}
          height={160}
          withPlaceholder
          fit="cover"
          alt={`project ${project?.id}`}
        />
      </Card.Section>

      <Text weight={500} size="lg" mt="md">
        {project?.name}
      </Text>

      <Text mt="-4px" color="dimmed" size="sm">
        Team: {project?.team?.name}
      </Text>
      <Text mt="-4px" color="dimmed" size="sm">
        Average rate:{" "}
        {project?.sum_rating > 0 ? project?.sum_rating : "Not rated."}
      </Text>
      <Avatar.Group mt={16} spacing="sm">
        {project?.team?.members &&
          project?.team?.members?.map((member) => (
            <Avatar
              src={member?.profile_img}
              alt={`member ${member?.id}`}
              radius="xl"
            />
          ))}
      </Avatar.Group>
      {/* <RatingSystem>
        <div className="rating">
          <div className="avgRate">
            <Rating value={3.5} fractions={2} readOnly />
            <p>4.0</p>
          </div>
        </div>
      </RatingSystem> */}
    </Card>
  );
};

const RatingSystem = styled.div`
  .rating {
    margin-top: 10px;

    .avgRate {
      padding-left: 5px;
      display: flex;
      align-items: center;

      p {
        color: #545454;
        font-size: 16px;
        font-weight: 400;
        margin-left: 10px;
      }
    }
  }

  .system {
    border-radius: 8px;
    background: #fff7e0;
    padding: 5px;
    margin-top: 6px;

    .group {
      display: flex;
      justify-content: space-between;
      p {
        color: #1a1d1c;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
      }
    }
  }

  button {
    width: 100%;
    height: 44px;
    flex-shrink: 0;
    border-radius: 8px;
    background: #484848;
    color: white;
    font-size: 16px;
    cursor: pointer;
    margin-top: 6px;
  }
`;
