import { useContext } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";
import {
  BrandAppleArcade,
  BuildingBank,
  School,
  Map,
  Settings,
  Shape2,
  Home,
  Social,
  Ticket,
  Users,
} from "tabler-icons-react";
import { Avatar, Button, Center, Text } from "@mantine/core";
import { PartnersCarousel } from "../PartnersCarousel";

export const Sidebar = () => {
  const { user } = useContext(AppContext);

  const location = useLocation();

  const { t, i18n } = useTranslation();

  return (
    <SidebarStyle>
      {user?.id && (
        <Link to={"/profile"} className="userInfo">
          {user?.profile_img?.endsWith("download.png") ? (
            <Avatar src={""} size={78} radius={100} />
          ) : (
            <Avatar src={user?.profile_img} size={78} radius={100} />
          )}
          <div className="title">
            <Text className="name" color={"dark.9"} size={18}>
              {user?.first_name} {user?.last_name !== "." && user?.last_name}
            </Text>
            {user?.speciality?.content && (
              <Text size={15} color={"dimmed"}>
                {user?.speciality?.content || "Speciality not chosen"}
              </Text>
            )}
          </div>
        </Link>
      )}
      <div className="links">
        <Button
          radius={"md"}
          variant={location.pathname.length < 2 ? "filled" : "subtle"}
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.length < 2 ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/"
          leftIcon={<Home />}
        >
          <Text size={17} weight={400}>
            {t("home")}
          </Text>
        </Button>
        <Button
          radius={"md"}
          variant={
            location.pathname.includes("internships") ? "filled" : "subtle"
          }
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.includes("internships") ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/internships"
          leftIcon={<Map />}
        >
          <Text size={17} weight={400}>
            {t("internships")}
          </Text>
        </Button>
        <Button
          radius={"md"}
          variant={location.pathname.includes("courses") ? "filled" : "subtle"}
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.includes("courses") ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/courses"
          leftIcon={<Shape2 />}
        >
          <Text size={17} weight={400}>
            {t("courses")}
          </Text>
        </Button>

        <Button
          radius={"md"}
          variant={
            location.pathname.includes("hackathons") ? "filled" : "subtle"
          }
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.includes("hackathons") ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/hackathons"
          leftIcon={<Ticket />}
        >
          <Text size={17} weight={400}>
            {t("hackathons-events")}
          </Text>
        </Button>

        <Button
          radius={"md"}
          variant={
            location.pathname.includes("communities") ? "filled" : "subtle"
          }
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.includes("communities") ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/communities"
          leftIcon={<Social />}
        >
          <Text size={17} weight={400}>
            {t("Communities")}
          </Text>
        </Button>

        <Button
          radius={"md"}
          variant={
            location.pathname.includes("bootcamps") ? "filled" : "subtle"
          }
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.includes("bootcamps") ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/bootcamps"
          leftIcon={<BuildingBank />}
        >
          <Text size={17} weight={400}>
            {t("Bootcamps")}
          </Text>
        </Button>
        <Button
          radius={"md"}
          variant={location.pathname.includes("games") ? "filled" : "subtle"}
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.includes("games") ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/games"
          leftIcon={<BrandAppleArcade />}
        >
          <Text size={17} weight={400}>
            {t("simulations")}
          </Text>
        </Button>
        {user?.user_type?.type === "Mentor" && (
          <Button
            radius={"md"}
            variant={location.pathname.includes("mentor") ? "filled" : "subtle"}
            styles={{
              icon: { paddingRight: ".5rem", color: "#000" },
              label: { color: "#000" },
            }}
            color={location.pathname.includes("mentor") ? "ts" : "gray"}
            size="md"
            component={Link}
            to="/mentor"
            leftIcon={<School />}
          >
            <Text size={17} weight={400}>
              {t("Mentor")}
            </Text>
          </Button>
        )}
        <Button
          radius={"md"}
          variant={
            location.pathname.includes("/settings") ? "filled" : "subtle"
          }
          styles={{
            icon: { paddingRight: ".5rem", color: "#000" },
            label: { color: "#000" },
          }}
          color={location.pathname.includes("/settings") ? "ts" : "gray"}
          size="md"
          component={Link}
          to="/settings"
          leftIcon={<Settings />}
        >
          <Text size={17} weight={400}>
            {t("settings")}
          </Text>
        </Button>
      </div>
      <Text color={"dimmed"}>Partners</Text>
      <Center>
        <PartnersCarousel />
      </Center>
    </SidebarStyle>
  );
};

const SidebarStyle = styled.div`
  display: flex;
  flex-direction: column;
  grid-gap: 0.65rem;
  min-width: 280px;
  /* min-width: 321px;
  position: fixed; */
  .userInfo {
    border: 1px solid #dee2e6;
    background: #fff;
    padding: 1rem 1rem;
    border-radius: 0.75rem;
    display: flex;
    justify-content: space-between;
    grid-template-columns: 1fr 1.5fr;
    align-items: center;
    text-decoration: none;
    text-align: left;
    grid-gap: 1rem;
    .title {
      width: 100%;
      .name {
        transition: all 10.15s;
      }
    }
    :hover {
      .name {
        text-decoration: underline;
      }
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
    border-radius: 0.75rem;
    padding: 1rem 1rem;
    background: #fff;
    border: 1px solid #dee2e6;
    a {
      display: flex;
      align-items: center;
      /* color: #000; */
      transition: all 0.15s;
      /* border: 1px solid transparent;
      &:hover{
        border: 1px solid #aaa;
        filter: brightness(1.15);
      } */
    }
  }
  @media screen and (max-width: 1024px) {
    .links,
    .userInfo {
      display: none;
    }
  }
  @media screen and (max-width: 800px) {
    padding: 2rem 1rem;
  }
`;
