import { React, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { Minus, Plus } from "tabler-icons-react";
import { Button, TextInput, Input } from "@mantine/core";
import { Partition } from "../../../Components/Partition";

export const Certifications = ({ props }) => {
  const { user, isTaskool } = props;
  const [addSign, setAddSign] = useState(true);

  let texts = isTaskool
    ? {
        header: "Sertifikatlar",
        bSend: "Göndər",
        bUpload: "Yüklə",
        bEdit: "Redaktə et",
        bDelete: "Sil",
        bCancel: "Ləğv et",
        name: "Sertifikatın adı",
        photo: "Sertifikatın şəkli",
        nt1: "Sertifikat əlavə edildi",
        nt2: "Sertifikat redaktə edildi",
        nt3: "Sertifikat silindi",
        ntm1: "Sertifikat uğurla əlavə edildi",
        ntm2: "Sertifikat uğurla redaktə edildi",
        ntm3: "Sertifikat uğurla silindi",
        e1: "Sertifikat əlavə edilərkən xəta baş verdi",
        e2: "Zəhmət olmasa tələb olunan xanaları doldurun",
      }
    : {
        header: "Certifications",
        bSend: "Submit",
        bEdit: "Edit",
        bDelete: "Remove",
        bCancel: "Cancel",
        name: "Certification name",
        photo: "Certification photo",
        nt1: "Certification added",
        nt2: "Certification edited",
        nt3: "Certification removed",
        ntm1: "Certification successfully added",
        ntm2: "Certification successfully edited",
        ntm3: "Certification successfully removed",
        e1: "Error occured while adding certification",
        e2: "Please fill in the required fields",
      };

  let [certArray, setCertArray] = useState([]);
  let [loading, setLoading] = useState(false);
  let [currentEditing, setCurrentEditing] = useState(0);

  // const notifications = useNotifications();

  async function fetchData() {
    try {
      const fetchedCerts = await axios.get("api/certificate/");
      // console.log(fetchedCerts);
      fetchedCerts.data.sort((a, b) => a.id - b.id);
      setCertArray(
        Object.keys(fetchedCerts.data).map(function (key) {
          return fetchedCerts.data[key];
        })
      );
    } catch (error) {}
  }

  // Fetches data when the page is loaded
  useEffect(() => {
    fetchData();
    return () => {
      setCertArray([]);
    };
  }, []);

  // Adds new skill
  function addInput() {
    // If there is no current editing, set current editing to the last index

    let certIds = certArray.map(function (cert) {
      return cert.id;
    });

    if (certIds.includes(currentEditing.id)) {
      setCurrentEditing(0);
      setCertArray((certs) => {
        const newCertificate = [...certs];
        newCertificate.push({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        setCurrentEditing({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        return newCertificate;
      });
    }

    if (currentEditing === 0) {
      // setCurrentEditing(0)
      setCertArray((certs) => {
        const newCertificate = [...certs];
        newCertificate.push({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        setCurrentEditing({
          id: certArray.length ? certArray[certArray?.length - 1]?.id + 1 : 2,
        });
        return newCertificate;
      });
    }
  }

  function handleEdit(index) {
    if (addSign === false) {
      setAddSign(true);
    }
    setCurrentEditing(certArray[index]);
    setCertArray(certArray.filter((cert) => cert.certificate !== undefined));
  }

  // Deletes skill
  async function handleDelete(e, index) {
    setCurrentEditing(0);
    setCertArray(certArray.filter((cert) => cert.job_position !== undefined));

    try {
      // deletes skill from database
      const deletedCert = await axios.delete(
        `api/certificate/${certArray[index].id}/`
      );
      // "🚀 | handleDelete | deletedCert", deletedCert)

      setCertArray(
        certArray.filter(
          (certificate) => certificate.id !== certArray[index].id
        )
      );

      if (deletedCert.status === 204) {
        showNotification({
          title: texts?.nt3,
          message: texts?.ntm3,
          color: "white",
        });
      }

      // reFetches certs and sorts them in ascending order after deleting one
      // const fetchedCerts = await axios.get("api/certificate/");
      // fetchedCerts.data.sort((a, b) => a.id - b.id);

      // setCertArray(
      //   Object.keys(fetchedCerts.data).map(function (key) {
      //     return fetchedCerts.data[key];
      //   })
      // );
    } catch (error) {}
  }

  // currentEditing);

  async function updateCert(e, index) {
    setLoading(true);

    try {
      let formX = new FormData();
      formX.append("id", currentEditing.id);
      formX.append("user", user?.id);
      currentEditing.title && formX.append("title", currentEditing.title);
      currentEditing.certificate.name &&
        formX.append("certificate", currentEditing.certificate);

      const response = await axios.patch(
        `api/certificate/${currentEditing.id}/`,
        formX
      );

      if (response.status === 200) {
        fetchData();
        setLoading(false);
        showNotification({
          title: texts?.nt2,
          message: texts?.ntm2,
          color: "white",
        });

        setCurrentEditing(0);
      }
    } catch (error) {
      if (error?.response?.status !== 200) {
        postCert();
      }
    }
  }

  async function postCert() {
    setLoading(true);

    try {
      let formX = new FormData();
      formX.append("id", currentEditing.id);
      formX.append("user", user?.id);
      formX.append("title", currentEditing.title);
      formX.append("certificate", currentEditing.certificate);

      const response = await axios.post("api/create-certificate/", formX);

      if (response.status === 201) {
        setLoading(false);
        setCurrentEditing(0);
        fetchData();
        showNotification({
          title: texts?.nt1,
          message: texts?.ntm1,
          color: "green",
        });
      }
    } catch (error) {}
  }

  // Cancel editing
  function handleCancel() {
    setCurrentEditing(0);
    setCertArray(certArray.filter((cert) => cert.certificate !== undefined));
  }

  return (
    <Partition>
      <CertStyle>
        <div className="cert">
          <div className="title">
            <h2>{texts?.header}</h2>
            {addSign ? (
              <Button
                variant="default"
                className="addItem"
                type="button"
                onClick={() => {
                  setAddSign(false);
                  addInput(true);
                }}
              >
                <Plus />
              </Button>
            ) : (
              <Button
                variant="default"
                className="addItem"
                type="button"
                onClick={() => {
                  handleCancel();
                  setAddSign(true);
                }}
              >
                <Minus />
              </Button>
            )}
          </div>

          <form onSubmit={(e) => e.preventDefault()}>
            {certArray.map((certification, index) => {
              return currentEditing?.id === certification?.id ? (
                <div
                  key={index}
                  className="editing insideWrapper"
                  id={certification?.id}
                >
                  <div>
                    <TextInput
                      size="md"
                      type="text"
                      placeholder="Sertifikatın adı"
                      label="Sertifikatın adı"
                      className="certificationLink"
                      value={currentEditing?.title}
                      onChange={(e) =>
                        setCurrentEditing({
                          ...currentEditing,
                          title: e.target.value,
                        })
                      }
                    />
                    <br />

                    <Input.Wrapper label="Sertifikatın şəkli">
                      <br />
                      <input
                        type="file"
                        onChange={(e) =>
                          setCurrentEditing({
                            ...currentEditing,
                            certificate: e.target.files[0],
                          })
                        }
                      />
                    </Input.Wrapper>
                    <br />

                    {currentEditing?.certificate?.name && (
                      <div>
                        <img
                          src={URL.createObjectURL(currentEditing?.certificate)}
                          alt="certificate"
                        />
                        <br />
                        <br />
                      </div>
                    )}

                    {currentEditing?.certificate &&
                      !currentEditing?.certificate?.name && (
                        <div>
                          <img
                            src={currentEditing?.certificate}
                            alt="certificate"
                          />
                          <br />
                          <br />
                        </div>
                      )}
                  </div>

                  <div className="buttons">
                    <Button
                      loading={loading}
                      variant="outline"
                      color="teal"
                      type="button"
                      onClick={(e) => {
                        setAddSign(true);
                        updateCert(e, index);
                      }}
                    >
                      {texts?.bSend}
                    </Button>
                    <Button
                      variant="outline"
                      color="gray"
                      type="button"
                      onClick={() => {
                        setAddSign(true);
                        handleCancel();
                      }}
                    >
                      {texts?.bCancel}
                    </Button>
                  </div>
                </div>
              ) : (
                <div
                  key={index}
                  id={certification?.id}
                  className="insideWrapper"
                >
                  <div className="box">
                    <div className="info">
                      <img src={certification?.certificate} alt="" />
                      <h2>
                        {certification?.title?.length > 80
                          ? certification?.title?.substring(0, 80) + ".."
                          : certification?.title}
                      </h2>
                    </div>
                    <div className="manageEntry">
                      <Button
                        variant="subtle"
                        type="button"
                        onClick={() => handleEdit(index)}
                      >
                        {texts?.bEdit}
                      </Button>
                      <Button
                        variant="outline"
                        color="red"
                        type="button"
                        className="removeSkill"
                        onClick={(e) => handleDelete(e, index)}
                      >
                        {texts?.bDelete}
                      </Button>
                    </div>
                  </div>
                  <hr />
                </div>
              );
            })}
          </form>
        </div>
      </CertStyle>
    </Partition>
  );
};

const CertStyle = styled.div`
  button {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  img {
    object-fit: cover;
    border-radius: 0.25rem;
    width: 240px;
    height: 140px;
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .cert {
    .insideWrapper {
      width: 95%;
      margin: 0 auto;
      margin-top: 1rem;
      hr {
        border: none;
        border-bottom: 1px solid #ddd;
        margin-top: 1rem;
      }
    }
    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .addItem {
        background: none;
        font-size: 17px;
        color: #2f80ed;
        font-weight: 400;
        border: none;
        cursor: pointer;
      }
      svg {
        color: #000;
      }
    }
    .box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .manageEntry {
        display: flex;
        button {
          display: flex;
          border: none;
          font-weight: 500;
        }
      }
      .info {
        display: flex;
        align-items: flex-start;
        width: 100%;
        align-items: center;
        h2 {
          font-size: clamp(1rem, 2vw, 1.1rem);
          margin: 0 auto;
          margin-left: 1rem;
          margin-right: 1rem;
        }
        p {
          margin: 0.5rem 0;
        }
        ins {
          margin: 0;
          text-decoration: none;
          font-size: 14px;
          color: #666;
        }
      }
    }
    .editing {
      padding: 1rem 1.25rem;
      border: 1px solid #ccc;
      border-radius: 0.5rem;
      align-items: center;
      .buttons {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
        button {
          display: flex;
          align-items: center;
          text-align: center;
          justify-content: center;
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    img {
      width: max(140px, 30%);
      height: 70px;
      order: 2;
    }
    .cert {
      .insideWrapper {
        width: 100%;
        margin: 0 auto;
      }
      .title {
        width: 100%;
        margin: 0 auto;
      }
      .box {
        margin: 0;
        .manageEntry {
          display: flex;
          margin-right: 0.5rem;
        }
        .info {
          display: flex;
          align-items: flex-start;
          width: 100%;
          align-items: flex-start;
          justify-content: left;
          flex-direction: column;
          h2 {
            font-size: clamp(1.1rem, 2vw, 1.1rem);
            margin: 0;
            margin-bottom: 1rem;
            order: 1;
          }
        }
      }
      .editing {
        margin: 0 1rem;
      }
    }
  }
  @media screen and (max-width: 335px) {
    .cert {
      .box {
        display: block;
        .manageEntry {
        }
      }
    }
  }
`;
