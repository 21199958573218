import { ChevronRight } from "tabler-icons-react";
import { Link, useLocation } from "react-router-dom";
import { Breadcrumbs } from "@mantine/core";

export const CurrentLocation = ({ props }) => {
  const { entryData, type } = props;
  const { title } = entryData;

  const location = useLocation();

  let bootcamp = location?.pathname?.includes("bootcamp");
  let link = bootcamp
    ? `/bootcamp/${entryData?.school?.id}`
    : type === "course"
    ? "/courses"
    : type === "hackathon"
    ? "/hackathons"
    : "/internships";

  const items = [
    { title: bootcamp ? "Bootcamp" : "All programs", href: link },
    { title: title.slice(0, 16) + "...", href: "" },
  ].map((item, index) => (
    <Link
      to={item.href}
      key={index}
      style={{ textDecoration: "none", color: "#111" }}
    >
      {item.title}
    </Link>
  ));

  return (
    <Breadcrumbs
      my={10}
      styles={{ separator: { margin: "0 0.25rem" } }}
      separator={<ChevronRight color="#aaa" />}
    >
      {items}
    </Breadcrumbs>
  );
};
