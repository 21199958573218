import { React, useContext } from "react";
import styled from "styled-components";
import { AppContext } from "../../../Helpers/Context";
import { Education } from "./Education";
import { SideBarNav } from "../../../Components/SideBarNav";
import { Experience } from "./Experience";
import { Certifications } from "./Certificates";
import { Tasks } from "./Tasks";
import { ProfileIntro } from "./ProfileIntro";
import { NotificationsProvider } from "@mantine/notifications";
import { NewSkills } from "./NewSkills";
import { AnimateOpa } from "../../../Components/AnimateOpa";
import SoftSkillsQuiz from "./SoftSkillsQuiz";
import { NewLanguages } from "./Languages";

const StudentProfile = ({ props }) => {
  const { isTaskool } = props;

  let { user } = useContext(AppContext);

  return (
    <AnimateOpa>
      <NotificationsProvider>
        <ProfileStyle>
          <div className="wrapperAll">
            <div className="profileParts">
              <ProfileIntro props={{ user, isTaskool }} />
              {/* {
                !isTaskool &&
                <Internship props={{ user, isTaskool }}/>
              } */}
              <Tasks props={{ user, isTaskool }} />
              <NewSkills props={{ user, isTaskool }} />
              <NewLanguages props={{ user, isTaskool }} />
              <SoftSkillsQuiz />
              <Experience props={{ user, isTaskool }} />
              <Education props={{ user, isTaskool }} />
              <Certifications props={{ user, isTaskool }} />
            </div>
            <div className="profileParts">
              <SideBarNav props={{ isTaskool }} />
              {/* <RecommendedCourses props={{ isTaskool }} /> */}
            </div>
          </div>
        </ProfileStyle>
      </NotificationsProvider>
    </AnimateOpa>
  );
};

export default StudentProfile;

const ProfileStyle = styled.div`
  display: flex;
  background-color: #eeeeee;
  .wrapperAll {
    width: 90%;
    margin: 1.5rem auto;
    background-color: #eeeeee;
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    grid-gap: 1.5rem;
    .profileParts {
      display: flex;
      flex-direction: column;
      grid-gap: 0.65rem;
    }
  }
  @media (max-width: 880px) {
    display: block;
    padding: 20px;
    .wrapperAll {
      width: 100%;
      margin: 0 auto;
      display: block;
      background-color: #f2f2f2;
      .profileParts {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
      }
    }
  }
  @media (max-width: 768px) {
    padding: 0px;
  }
`;
