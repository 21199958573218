import { BackgroundImage, Button, Group, Image, LoadingOverlay, Text } from "@mantine/core"
import { useQuery } from "@tanstack/react-query"
import axios from "axios"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { AddressBook, AlignJustified, MapPin } from "tabler-icons-react"
import { ProgramCard } from "./ProgramCard"

export const CompanyPage = () => {

  const { id } = useParams()

  const { data:userId, isLoading:userGuidLoading } = useQuery(["user id"], async () => await axios.get(`/api/v1/user-data-with-guid/${id}/`).then(res => res.data) )

  const { data:courses, isLoading:coursesLoading } = useQuery(["user courses", userId],
    async () =>  await axios.get(`/api/usercourselist/${userId?.id}/`).then(res => res.data) ,
    { enabled: !!userId?.id }
  )

  const { data:internships, isLoading:internshipsLoading } = useQuery(["user internships", userId],
    async () =>  await axios.get(`/internship/userinternshiplist/${userId?.id}/`).then(res => res.data),
    { enabled: !!userId?.id }
  )

  const { data:user, isLoading:userLoading } = useQuery(["user", userId],
    async () =>  await axios.get(`/public-user/${userId?.id}/`).then(res => res.data) ,
    { enabled: !!userId?.id }
  )

  if(userLoading || userGuidLoading || coursesLoading || internshipsLoading){
    return( <LoadingOverlay visible={true} variant='bars' loaderProps={{ color: 'var(--yellow)' }}/> )
  }

  return (
    <ContentStyle>

      <BackgroundImage py={30} src="https://images.unsplash.com/photo-1504275107627-0c2ba7a43dba?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80">
        <Group mx={100}>
          <Image mr={15} radius={100} height={100} width={100} src={""} withPlaceholder />
          <Text color={"white"} size={45} weight={500}>{user?.User[0]?.first_name} {user?.User[0]?.last_name !== "."  && user?.User[0]?.last_name}</Text>
        </Group>
      </BackgroundImage>

      <div className="wrapper">

        <div className="parts">
          <div style={{ marginBottom:".5rem" }}>
            <Text size={22} weight={500}>Information</Text>
          </div>

          <Group my={5} ml={10} style={{ gap:"0.5rem" }}>
            <MapPin color="gray" size={18}/>
            <Text color={"dimmed"} size={16}>Location:</Text>
            <Text size={16}>  </Text>
          </Group>
          <Group my={5} ml={10} style={{ gap:"0.5rem" }}>
            <AddressBook color="gray" size={18}/>
            <Text color={"dimmed"} size={16}>Email:</Text>
            <Text size={16}>  </Text>
          </Group>
          <Group my={5} ml={10} style={{ gap:"0.5rem" }}>
            <AddressBook color="gray" size={18}/>
            <Text color={"dimmed"} size={16}>Phone:</Text>
            <Text size={16}>  </Text>
          </Group>
          <Group my={5} ml={10} style={{ gap:"0.5rem" }}>
            <AlignJustified color="gray" size={18}/>
            <Text color={"dimmed"} size={16}>About:</Text>
            <Text size={16}>  </Text>
          </Group>
        </div>

        <div className="parts">
          <div style={{ marginBottom:".5rem", display:"flex", alignItems:"center", justifyContent:"space-between" }}>
            <Text size={22} weight={500}>Latest post</Text>
            <Button styles={{ root:{ border:"0", textDecoration:"underline" } }} variant="default"><Text weight={500} size={15}>See all</Text></Button>
          </div>
          {/* { feed?.map(post => <Post props={{ post }}/>) } */}
        </div>
      </div>

      <div style={{ margin:"1.5rem auto", display:"flex", flexDirection:"column" }}>
        <Text mb={20} align="center" size={26}>Internships</Text>
        <Group style={{ justifyContent:"center" }}>
          {
            internships?.map(internship =>
              internship.is_shared === true &&
              <ProgramCard key={internship?.id} props={{ link:`/internships/${internship?.id}`, image:internship?.image, title:internship.title }}/>
            )
          }
        </Group>
      </div>

      <div style={{ margin:"1.5rem auto", display:"flex", flexDirection:"column" }}>
        <Text mb={20} align="center" size={26}>Courses</Text>
        <Group style={{ justifyContent:"center" }}>
          {
            courses?.map(course =>
              course.is_shared === true &&
              <ProgramCard key={course?.id} props={{ link:`/courses/${course?.id}`, image:course?.image, title:course.title }}/>
            )
          }
        </Group>
      </div>

    </ContentStyle>
  )
}

const ContentStyle = styled.div`
  .wrapper{
    width: 90%;
    margin: 2rem auto 2rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1.5rem;
  }
`;