import { forwardRef, useContext, useEffect, useRef } from "react";
import axios from "axios";
import styled from "styled-components";
import { useState } from "react";
import { AppContext } from "../../../Helpers/Context";
import {
  Avatar,
  Button,
  Group,
  NumberInput,
  Select,
  Skeleton,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import countries from "../../../Components/Countries.json";
import { Pencil } from "tabler-icons-react";

function NumberSelection() {
  const SelectItem = forwardRef(
    ({ image, label, description, ...others }, ref) => (
      <div ref={ref} {...others}>
        <Group noWrap>
          <Text size="lg">{image}</Text>
          <Text size="sm">{label}</Text>
        </Group>
      </div>
    )
  );

  return (
    <div>
      <Group align={"center"} style={{ gap: 0 }}>
        <Select
          clearable
          searchable
          size="md"
          placeholder="Country code"
          nothingFound="No options"
          name="countryCode"
          itemComponent={SelectItem}
          data={countries.map((c) => ({
            value: `${c.dial_code} ${c.code}`,
            label: c.dial_code,
            image: c.flag,
            description: c.name,
          }))}
          styles={{
            input: { borderTopRightRadius: "0", borderBottomRightRadius: "0" },
          }}
        />

        <NumberInput
          size="md"
          hideControls
          placeholder="Phone number"
          styles={{
            input: { borderTopLeftRadius: "0", borderBottomLeftRadius: "0" },
          }}
        />
      </Group>
    </div>
  );
}

export const EditUserInfo = ({ props }) => {
  const { isTaskool } = props;

  let texts = isTaskool
    ? {
        title: "Tənzimləmələr",
        name: "Ad",
        surname: "Soyad",
        email: "Email",
        speciality: "Vəzifə",
        phone: "Telefon",
        bio: "Bioqrafiya",
        buttonSave: "Yadda saxla",
        emailTaken: "Bu e-mail ünvanı ilə artıq qeydiyyatdan keçilmişdir.",
        bioTooLong: "Bioqrafiya 500 simvol üstü olmamalıdır.",
        nameBlank: "Ad boş ola bilməz.",
        surnameBlank: "Soyad boş ola bilməz.",
        addBio: "Bioqrafiya əlavə et",
      }
    : {
        title: "Settings",
        name: "Name",
        surname: "Surname",
        email: "Email",
        speciality: "Speciality",
        phone: "Phone",
        bio: "Biography",
        buttonSave: "Save",
        emailTaken: "This email address is already registered.",
        bioTooLong: "Biography cannot be longer than 500 characters.",
        nameBlank: "Name cannot be blank.",
        surnameBlank: "Surname cannot be blank.",
        addBio: "Add biography",
      };

  const { user, setUser } = useContext(AppContext);

  const [emailTaken, setEmailTaken] = useState(false);
  const [bioTooLong, setBioTooLong] = useState(false);
  const [nameBlank, setNameBlank] = useState(false);
  const [surnameBlank, setSurnameBlank] = useState(false);
  const [loaderVisible, setLoaderVisible] = useState(false);
  const [userInfoX, setUserInfoX] = useState({ ...user });

  const { data: specialities } = useQuery(["specialities"], async () => {
    return await axios.get("api/v1/speciality/").then((res) => res.data);
  });

  const submitForm = async (e) => {
    setLoaderVisible(true);
    e.preventDefault();

    try {
      const sendForm = await axios.patch(`api/v1/edit-user/${user?.id}/`, {
        ...userInfoX,
        profile_img: undefined,
        speciality_level: 1,
        speciality: userInfoX?.speciality?.content
          ? specialities.find(
              (speciality) =>
                speciality.content === userInfoX.speciality.content
            ).id
          : null,
      });
      setLoaderVisible(false);

      const response = await axios.post("api/v1/user-data/");
      setUser(response.data);
      localStorage.setItem("taskooluser", JSON.stringify(response.data));
      setEmailTaken(false);
      setBioTooLong(false);
      setNameBlank(false);
      setSurnameBlank(false);
    } catch (error) {
      if (error?.response?.data?.email) {
        setEmailTaken(true);
      }
      if (error?.response?.data?.bio) {
        setBioTooLong(true);
      }
      if (error?.response?.data?.first_name) {
        setNameBlank(true);
      }
      if (error?.response?.data?.last_name) {
        setSurnameBlank(true);
      }
      setLoaderVisible(false);
    }
  };

  useQuery(
    ["getData", user?.profile_img],
    async () => {
      return await axios.post("api/v1/user-data/").then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (data.profile_img) {
          setUserInfoX({ ...userInfoX, profile_img: data.profile_img });
          localStorage.setItem("taskooluser", JSON.stringify(data));
        }
      },
    },
    { enabled: !!user?.profile_img }
  );

  const FileUploader = (props) => {
    const hiddenFileInput = useRef(null);
    const handleClick = () => {
      hiddenFileInput.current.click();
    };

    const handleChange = async (event) => {
      setLoaderVisible(true);
      const fileUploaded = await event.target.files[0];
      const formX = new FormData();
      formX.append("profile_img", fileUploaded, fileUploaded.name);

      try {
        const sendImage = await axios.patch(
          `api/v1/edit-user/${user?.id}/`,
          formX
        );
        setLoaderVisible(false);

        const response = await axios.post("api/v1/user-data/");
        setUser(response.data);
      } catch (error) {
        setLoaderVisible(false);
      }
    };

    return (
      <div
        onClick={handleClick}
        className="fileUploader"
        style={{ width: "100px" }}
      >
        {props.children}
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleChange}
          style={{ display: "none" }}
        />
      </div>
    );
  };

  if (!specialities) {
    return <Skeleton height={620} visible={true} />;
  }

  return (
    <EditInfoStyle>
      <div className="containerSettings">
        <form onSubmit={(e) => submitForm(e)}>
          <div className="user">
            <div style={{ cursor: "pointer" }} className="userImage">
              <div style={{ position: "relative" }} className="img">
                <FileUploader>
                  <Pencil
                    color="white"
                    style={{
                      position: "absolute",
                      zIndex: "99999",
                      top: "69%",
                      left: "37%",
                      border: "3px solid",
                      borderRadius: "50%",
                      backgroundColor: "black",
                      opacity: 0.6,
                    }}
                  />
                  <Avatar
                    radius={100}
                    styles={{ root: { width: "100%", height: "100%" } }}
                    src={user?.profile_img ? user?.profile_img : ""}
                  />
                </FileUploader>
              </div>
            </div>
            <div className="userInfo">
              <h2>
                {user?.first_name} {user?.last_name !== "." && user?.last_name}
              </h2>
              {!isTaskool && <p>{user?.speciality?.content}</p>}
            </div>
          </div>

          {user?.user_type?.type === "Business" ? (
            <div className="firstInputGroup">
              <div className="groupOne">
                <TextInput
                  size="md"
                  name="firstName"
                  label={texts?.name}
                  placeholder={texts?.name}
                  value={userInfoX?.first_name}
                  maxLength={40}
                  error={nameBlank}
                  onChange={(e) =>
                    setUserInfoX({ ...userInfoX, first_name: e.target.value })
                  }
                />

                <TextInput
                  size="md"
                  name="email"
                  label={texts?.email}
                  placeholder={texts?.email}
                  value={userInfoX?.email}
                  error={emailTaken}
                  onChange={(e) => {
                    setUserInfoX({ ...userInfoX, email: e.target.value });
                  }}
                />
              </div>

              <div className="groupTwo">
                <Textarea
                  size="md"
                  name="bio"
                  label={
                    texts?.bio + " (" + (userInfoX?.bio?.length || 0) + "/5000)"
                  }
                  placeholder={texts?.bio}
                  minRows={4}
                  autosize
                  value={userInfoX?.bio ? userInfoX?.bio : ""}
                  error={bioTooLong}
                  onChange={(e) => {
                    if (e.target.value.length < 5001) {
                      setUserInfoX({ ...userInfoX, bio: e.target.value });
                    }
                  }}
                  styles={{
                    label: {
                      color: userInfoX?.bio?.length > 5000 ? "red" : "dark",
                    },
                  }}
                />
              </div>
            </div>
          ) : (
            <div className="firstInputGroup">
              <div className="groupOne">
                <TextInput
                  size="md"
                  name="firstName"
                  label={texts?.name}
                  placeholder={texts?.name}
                  value={userInfoX?.first_name}
                  maxLength={40}
                  error={nameBlank}
                  onChange={(e) =>
                    setUserInfoX({ ...userInfoX, first_name: e.target.value })
                  }
                />
                <TextInput
                  size="md"
                  name="lastName"
                  label={texts?.surname}
                  placeholder={texts?.surname}
                  value={userInfoX?.last_name}
                  maxLength={40}
                  error={surnameBlank}
                  onChange={(e) =>
                    setUserInfoX({ ...userInfoX, last_name: e.target.value })
                  }
                />
              </div>

              {nameBlank && <p className="error">{texts?.nameBlank}</p>}
              {surnameBlank && <p className="error">{texts?.surnameBlank}</p>}

              {/* <NumberSelection /> */}

              <div className="groupOne">
                {!isTaskool && (
                  <Select
                    placeholder="Pick one"
                    nothingFound="No options"
                    searchable
                    size="md"
                    name="speciality"
                    label={texts?.speciality}
                    value={userInfoX?.speciality?.content}
                    data={
                      specialities
                        ? specialities?.map((speciality) => speciality?.content)
                        : []
                    }
                    onChange={(e) =>
                      setUserInfoX({ ...userInfoX, speciality: { content: e } })
                    }
                  />
                )}

                <TextInput
                  size="md"
                  name="email"
                  label={texts?.email}
                  placeholder={texts?.email}
                  value={userInfoX?.email}
                  error={emailTaken}
                  onChange={(e) => {
                    setUserInfoX({ ...userInfoX, email: e.target.value });
                  }}
                />
              </div>

              {emailTaken && <p className="error">{texts?.emailTaken}</p>}

              <div className="groupTwo">
                <Textarea
                  size="md"
                  name="bio"
                  label={
                    texts?.bio + " (" + (userInfoX?.bio?.length || 0) + "/5000)"
                  }
                  placeholder={texts?.bio}
                  minRows={4}
                  autosize
                  value={userInfoX?.bio ? userInfoX?.bio : ""}
                  error={bioTooLong}
                  onChange={(e) => {
                    if (e.target.value.length < 5001) {
                      setUserInfoX({ ...userInfoX, bio: e.target.value });
                    }
                  }}
                  styles={{
                    label: {
                      color: userInfoX?.bio?.length > 5000 ? "red" : "dark",
                    },
                  }}
                />
              </div>
            </div>
          )}

          <Button
            loading={loaderVisible}
            color="dark.6"
            size="md"
            variant=""
            fullWidth
            type="submit"
            className="submit"
          >
            {texts?.buttonSave}
          </Button>
        </form>
      </div>
    </EditInfoStyle>
  );
};

const EditInfoStyle = styled.div`
  h2 {
    margin-top: 0;
  }
  h2,
  h3 {
    font-weight: 500;
  }
  img {
    width: 6rem;
    height: 6rem;
  }
  .error {
    color: red;
    margin: 0;
  }
  .containerSettings {
    padding: 2rem;
    margin-bottom: 2rem;
    background-color: #fff;
    border-radius: 0.75rem;
    border: 1px solid #dee2e6;
    h3 {
      margin-top: 3rem;
    }
    .editPhoto {
      margin-top: 0.5rem;
      padding: 0.25rem 0;
      border-radius: 0.25rem;
      width: 100%;
      text-align: center;
      cursor: pointer;
      transition: all 0.1s;
      border: 1px solid #ced4da;
      &:hover {
        background-color: #e9ecef;
      }
    }
    .user {
      display: flex;
      width: max-content;
      align-items: center;
      grid-gap: 1.5rem;
      .userImage {
        display: flex;
        flex-direction: column;
        .img {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 6.5rem;
          height: 6.5rem;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            /* border-radius: 0.25rem 0.25rem 0 0; */
          }
        }
      }
      .userInfo {
        width: 80%;
        display: block;
        margin: 0;
        h2 {
          font-size: clamp(1.5rem, 2vw, 1.5rem);
          color: #4f4f4f;
          margin: 0;
          padding: 0;
        }
        p {
          font-size: 1rem;
          color: #666;
          margin: 0;
          padding: 0;
        }
        .about {
          font-size: 15px;
          font-size: clamp(1rem, 1vw, 1.1rem);
          color: #000;
          margin: 0;
          margin-top: 0.3rem;
          max-width: 600px;
        }
      }
    }
    .firstInputGroup {
      margin-top: 2rem;
      .groupOne,
      .groupTwo {
        margin: 1.25rem 0;
      }
      .groupOne {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1.5rem;
      }
    }
    button {
      margin-top: 1rem;
    }
  }
  @media screen and (max-width: 800px) {
    h2 {
      text-align: center;
    }
    .containerSettings {
      padding: 0 1rem 2rem 1rem;
      border-radius: 0;
      .user {
        width: 100%;
        flex-direction: column;
        padding: 2rem 0 0 0;
        .userImage {
          margin: 0 auto;
          .img {
          }
        }
        .userInfo {
          width: 100%;
          padding: 0;
          margin: 0 auto;
          margin-top: 1rem;
          .group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
          }
          .about {
            display: flex;
            max-width: 100%;
          }
        }
      }
      .firstInputGroup {
        margin-top: 3rem;
        .groupOne,
        .groupTwo {
          margin: 1rem 0;
        }
        .groupOne {
          display: grid;
          grid-template-columns: 1fr;
          grid-gap: 1rem;
        }
      }
    }
  }
`;
