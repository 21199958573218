import axios from "axios";
import styled from "styled-components";
import { Plus, X } from "tabler-icons-react";
import { useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Accordion, Button, Select, Text } from "@mantine/core";
import { Partition } from "../../../Components/Partition";

export function SkillForm({ props }) {
  const { user, queryClient } = props;

  const { data: skillsList } = useQuery(["skillsList"], async () => {
    return await axios.get(`/api/skill_list/`).then((res) => res.data);
  });

  const { data: rankList } = useQuery(["ranks"], async () => {
    return await axios.get(`/api/rank_list/`).then((res) => res.data);
  });

  const { data: userSkills } = useQuery(["userSkills"], async () => {
    return await axios.get(`/api/userskill/`).then((res) => res.data);
  });

  const [chosenSkill, setChosenSkill] = useState({ skill: null, rank: null });

  const { mutate: postSkill, isLoading: postLoading } = useMutation(
    ["deleteSkill"],
    async () => {
      return await axios.post(`/api/create-userskill/`, {
        user: user?.id,
        skill: chosenSkill.skill,
        rank: chosenSkill.rank,
      });
    },
    {
      onSuccess: () => {
        setChosenSkill({ skill: null, rank: null });
        queryClient.invalidateQueries("userSkills");
      },
    }
  );

  return (
    <Accordion variant="separated" radius="sm" chevron={<Plus />}>
      <Accordion.Item value="x">
        <Accordion.Control style={{ paddingRight: "1rem" }}>
          <p className="formHead">
            <Text size={22} weight={500}>
              Skills
            </Text>
          </p>
        </Accordion.Control>
        <Accordion.Panel>
          <div className="addSkillForm">
            <Select
              searchable
              clearable
              label="Skill"
              placeholder="Search for a skill"
              data={
                skillsList
                  ? skillsList
                      ?.filter((skill) => skill?.header?.id != 10)
                      ?.filter((skill) =>
                        userSkills.find((_skill) => _skill.skill.id == skill.id)
                          ?.id
                          ? false
                          : true
                      )
                      ?.map((skill) => {
                        return {
                          value: skill?.id,
                          label: skill?.skill,
                          group: skill?.header?.header,
                        };
                      })
                  : []
              }
              value={chosenSkill && chosenSkill.skill}
              onChange={(e) => {
                setChosenSkill((prev) => {
                  return { rank: prev?.rank, skill: e };
                });
              }}
            />

            <Select
              searchable
              clearable
              label="Level"
              placeholder="Select your level"
              data={
                rankList
                  ? rankList?.map((rank) => {
                      return { value: rank?.id, label: rank?.rank };
                    })
                  : []
              }
              value={chosenSkill && chosenSkill.rank}
              onChange={(e) => {
                setChosenSkill((prev) => {
                  return { skill: prev?.skill, rank: e };
                });
              }}
            />

            <Button
              loading={postLoading}
              onClick={() => postSkill()}
              disabled={chosenSkill.rank === null || chosenSkill.skill === null}
              color="green"
              fullWidth
              variant="outline"
            >
              Submit
            </Button>
          </div>
        </Accordion.Panel>
      </Accordion.Item>
    </Accordion>
  );
}

export function AddedSkills({ props }) {
  const { queryClient } = props;

  const { data: userSkills } = useQuery(["userSkills"], async () => {
    return await axios.get(`/api/userskill/`).then((res) => res.data);
  });

  const { mutate: deleteSkill, isLoading: deleteLoading } = useMutation(
    ["deleteSkill"],
    async (id) => {
      return await axios
        .delete(`/api/userskill/${id}/`)
        .then((res) => res.data);
    },
    { onSuccess: queryClient.invalidateQueries("userSkills") }
  );

  return (
    <div className="skillsList">
      {userSkills
        ?.filter((skill) => skill.skill.header != 10)
        ?.map((skill, index) => {
          return (
            <div key={skill?.id}>
              {index !== 0 && <hr />}
              <div className="skillWrap">
                <div>
                  <b>{skill?.skill?.skill}</b>
                  <p>-</p>
                  <p>{skill?.rank?.rank}</p>
                </div>
                <Button
                  disabled={deleteLoading}
                  variant="subtle"
                  size="xs"
                  color="dark"
                  onClick={() => deleteSkill(skill?.id)}
                >
                  <X size={20} />
                </Button>
              </div>
            </div>
          );
        })}
    </div>
  );
}

export const NewSkills = ({ props }) => {
  const { user } = props;

  const queryClient = useQueryClient();

  return (
    <Partition>
      <SkillsStyle>
        <SkillForm props={{ user, queryClient }} />
        <AddedSkills props={{ user, queryClient }} />
      </SkillsStyle>
    </Partition>
  );
};

const SkillsStyle = styled.div`
  .addSkillForm {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
  }
  .skillsList {
    margin-top: 1rem;
    .skillWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      div {
        display: flex;
        align-items: center;
        grid-gap: 0.5rem;
        p,
        b {
          margin: 0.5rem 0;
          font-size: 17px;
        }
        b {
          font-weight: 500;
        }
      }
    }
  }
`;
