import { Center, Container, Text } from '@mantine/core'
import { Train } from 'tabler-icons-react'

export const ComingSoon = ({ margin }) => {

  return (
    <Center>
      <Container mt={margin < 0 ? margin : 120} pb={margin < 0 ? margin : 120}>
        <Center>
          <Text mr={2} align="center" size={32}>Coming soon... </Text>
          <Train size={32} color="#ffe01b"/>
        </Center>
        <Text size={20}>The page is under construction</Text>
      </Container>
    </Center>
  )
}
