import { Slider } from "@mantine/core";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../Helpers/Context";
import styled from "styled-components";
import RichTextEditor from "@mantine/rte";
import parse from "html-react-parser";

const GiveGrade = ({ props }) => {
  const { user } = useContext(AppContext);
  const { userAnswer, forUsers, type } = props;

  const [teacherGrade, setTeacherGrade] = useState(null);
  const [editGrade, setEditGrade] = useState(false);
  const [grade, setGrade] = useState({ text: "", point: 0 });

  async function getGrade() {
    if (userAnswer?.id) {
      try {
        const response = await axios.get(
          `/grade/${type}/answer/${userAnswer?.id}/`
        );
        if (response?.data.id) {
          setTeacherGrade({ ...response?.data, answered: true });
          setGrade({
            text: response.data.description,
            point: response.data.grade,
            id: response.data.id,
          });
        }
      } catch (error) {
        setTeacherGrade(false);
      }
    }
  }
  console.log('teacher grade',teacherGrade);

  useEffect(() => {
    getGrade();
    return () => {
      setTeacherGrade(null);
    };
  }, [userAnswer]);

  async function submitGrade() {
    await axios.post(`/grade/${type}/`, {
      description: grade.text,
      grade: JSON.stringify(grade.point),
      answer: userAnswer?.id,
      user: user?.id,
    });
    getGrade();
  }

  async function updateGrade() {
    await axios.patch(`/grade/${type}/${grade?.id}/`, {
      description: grade.text,
      grade: JSON.stringify(grade.point),
      answer: userAnswer?.id,
      user: user?.id,
    });
    getGrade();
    setEditGrade(false);
  }

  if (teacherGrade === null) {
    return "x12";
  }

  if ((teacherGrade === false && forUsers === false) || editGrade === true) {
    return (
      <GradeStyle>
        <div className="gradeWrapper">
          <h2>Give grade</h2>
          <p>Note for the student</p>
          <RichTextEditor
            value={grade.text}
            onChange={(e) => setGrade((prev) => ({ ...prev, text: e }))}
          />
          <br />
          <div className="slider">
            <p>Points: {grade.point}</p>
            <Slider
              min={0}
              max={10}
              marks={[
                { value: 1, label: "1" },
                { value: 2, label: "2" },
                { value: 3, label: "3" },
                { value: 4, label: "4" },
                { value: 5, label: "5" },
                { value: 6, label: "6" },
                { value: 7, label: "7" },
                { value: 8, label: "8" },
                { value: 9, label: "9" },
                { value: 10, label: "10" },
              ]}
              value={grade.point}
              onChange={(e) => setGrade((prev) => ({ ...prev, point: e }))}
            />
          </div>
          <br />
          <br />
          {editGrade ? (
            <button className="subBut" onClick={() => updateGrade()}>
              Update
            </button>
          ) : (
            <button className="subBut" onClick={() => submitGrade()}>
              Submit
            </button>
          )}
        </div>
      </GradeStyle>
    );
  }

  if (teacherGrade?.answered !== undefined) {
    return (
      <GradeStyle>
        <div className="gradeWrapper">
          <div className="header">
            <h2>Grade</h2>
            {user?.user_type.type === "Mentor" && (
              <p onClick={() => setEditGrade(true)}>Edit</p>
            )}
          </div>
          <h3>Point(s): {teacherGrade?.grade}</h3>
          {teacherGrade?.description && parse(teacherGrade?.description)}
        </div>
      </GradeStyle>
    );
  }
};

export default GiveGrade;

const GradeStyle = styled.div`
  margin-top: 2rem;
  .gradeWrapper {
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    .header {
      margin-bottom: 2rem;
      p {
        color: blue;
        cursor: pointer;
        max-width: max-content;
      }
      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
    }
    .slider {
      margin-top: 1rem;
      p {
        margin-top: 0;
      }
    }
    .subBut {
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      cursor: pointer;
      border: 0;
      font-size: 15px;
      &:hover {
        background-color: var(--yellow-shadow);
      }
    }
  }
`;
