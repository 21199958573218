import React,{useContext} from "react";
import styled from "styled-components";
import { AppContext } from "../../Helpers/Context";
//import logo
import newLogo from "../../Assets/SVGs/new-logo.svg";

//import Icons
import menuIcon from "../../Assets/SVGs/menu-icon.svg";
import subscriptionIcon from "../../Assets/SVGs/subscription-icon.svg";

import searchIcon from "../../Assets/SVGs/header/search.svg";
import userIcon from "../../Assets/SVGs/header/user.svg";
import notificationIcon from "../../Assets/SVGs/header/notification.svg";
import arrowDownIcon from "../../Assets/SVGs/header/chevron-down.svg";

function NewNavbar() {

  const{newSidebarOpen,setNewSidebarOpen}=useContext(AppContext)
  return (
    <NewNavbarStyle>
      <div className="container">
        <div className="header-left">
          <div onClick={()=>setNewSidebarOpen(!newSidebarOpen)}className="menu-icon">
            <img src={menuIcon} alt="menu-icons" /> Menu
          </div>
          <div className="subscription-icon">
            <img src={subscriptionIcon} alt="subscription-icon" />
            Subscription
          </div>
        </div>

        <div className="logo">
          <img src={newLogo} alt="taskilled logo" />
        </div>

        <div className="header-icons">
          <div className="icon-item">
            <img src={searchIcon} alt="" />
          </div>
          <div className="icon-item">
            <img src={userIcon} alt="" />
          </div>
          <div className="icon-item">
            <img src={notificationIcon} alt="" />
          </div>
          <div className="icon-item language">
            AZ
            <img src={arrowDownIcon} alt="" />
          </div>
        </div>
      </div>
    </NewNavbarStyle>
  );
}

export default NewNavbar;

const NewNavbarStyle = styled.div`

  border-bottom: 1px solid #d2d6db;
  box-shadow: 0px 8px 12px 0px #0000000a;

  .container {
    width: 1300px;
    margin: 0 auto;
    height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* background-color: #eee; */

    .header-left {
      display: flex;
      gap: 16px;

      .menu-icon {
        font-family: "Euclid";
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        padding: 8px 12px 8px 16px;
        img{
          margin-right: 8px;
        }
      }

      .subscription-icon {
        display: flex;
        align-items: center;
        gap: 4px;
        color: #eaaa08;
        font-family: "Euclid";
        font-size: 16px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0em;
        text-align: left;
        padding: 8px 12px 8px 16px;
        img{
          margin-right: 8px;
        }
      }
    }

    .header-icons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap:8px;

      .icon-item {
        padding: 10px;
        display: flex;
      }

      .language{
        padding:8px 12px 8px 16px;
        display: flex;
        gap:8px;
        }

      font-family: "Euclid";
      font-size: 16px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;
