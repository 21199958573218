import React, { useContext, useState } from "react";
import styled from "styled-components";
import NewNavbar from "../../../Components/NewNavbar/NewNavbar";
import NewFooter from "../../../Components/NewFooter/NewFooter";

import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import moreIcon from "../../../Assets/SVGs/profile/more.svg";
import locationIcon from "../../../Assets/SVGs/profile/location.svg";
import tagIcon from "../../../Assets/SVGs/profile/tag.svg";
import readMoreIcon from "../../../Assets/SVGs/profile/read-more.svg";

import profileImg from "../../../Assets/Images/profile/profile.png";
import backgroundImg from "../../../Assets/Images/profile/background.png";
import ProfileHeader from "./ProfileHeader";
import AboutGoals from "./AboutGoals";
import ProjectTasks from "./ProjectTasks";
import Analytics from "./Analytics";
import Experience from "./Experience";
import Volunteering from "./Volunteering";
import Education from "./Education";
import {Footer} from "../../../Components/Layout/Footer"
import ProfileSidebar from "./ProfileSidebar";
import NewSidebar from "../../../Components/NewSidebar/NewSidebar";
import { AppContext } from "../../../Helpers/Context";

function NewProfile({ props }) {

  const { isTaskool } = props;
  let { user } = useContext(AppContext);

  return (
    <div>
      <NewNavbar />
      <NewProfileStyle>
        <NewSidebar/>
        <div className="container">
          <div className="profile">
            <ProfileHeader props={{ user, isTaskool }} />
            {/* <AboutGoals props={user}/> */}
            {/* <ProjectTasks props={user}/> */}
            {/* <Analytics props={user}/> */}
            <Experience props={{ user, isTaskool }} />
            <Volunteering props={{ user, isTaskool }} />
            <Education props={{ user, isTaskool }} />
          </div>

      <ProfileSidebar/>
        </div>
      </NewProfileStyle>

     <Footer/>
    </div>
  );
}

export default NewProfile;

const NewProfileStyle = styled.div`
*{
  box-sizing: border-box;
}


  background-color: #f9fafb;
  .container {
    width: 1300px;
    margin: 0 auto;
    padding-top: 32px;
    display: flex;
    justify-content: space-between;
    .profile {
      border-radius: 20px;
      width: 970px;
      overflow: hidden;
    }
  }
`;
