import axios from "axios";
import { Button, TextInput, Select } from "@mantine/core";
import { PromoStyle } from "./PaymentStyle";
import { useState, useContext } from "react";
import { AppContext } from "../../Helpers/Context";

export function PayWithInstallment({ props }) {
  const { user } = useContext(AppContext);

  const {
    courseInstallmentData,
    links,
    promoEntered,
    setPromoEntered,
    promoResponse,
    setPromoResponse,
    promoDoesNotExist,
    setPromoDoesNotExist,
    entryData,
    coursePrice,
    setCoursePrice,
    sections,
  } = props;

  async function checkPromo(e) {
    e.preventDefault();

    if (promoEntered) {
      const response = await axios.get(links.get_promocode);

      if (promoDoesNotExist === true) {
        setPromoDoesNotExist(false);
      }

      setPromoResponse(response.data);
      if (response?.data?.price) {
        setCoursePrice(response?.data?.price);
      } else {
        setCoursePrice(entryData?.price);
      }

      if (response.status === 204) {
        setPromoDoesNotExist(true);
      }
    }
  }

  let formX = {
    user: user?.id,
    course: entryData?.id,
    bank_installment_paid: true,
    price: Number(entryData?.price),
    promocode:
      promoResponse?.promocode &&
      promoResponse?.promocode?.filter((p) => p.code == promoEntered)[0]
        ?.discount_percent
        ? promoEntered
        : "",
  };

  const [selectedMonth, setSelectedMonth] = useState(null);

  const handleSelectChange = (selected) => {
    setSelectedMonth(selected);
  };

  const getPriceForSelectedMonth = () => {
    if (selectedMonth) {
      const selectedData = courseInstallmentData.find(
        (item) => item.month === selectedMonth
      );
      if (promoResponse && promoResponse?.promocode) {
        return selectedData
          ? selectedData.price -
              (selectedData.price / 100) *
                promoResponse?.promocode?.filter(
                  (p) => p.code == promoEntered
                )[0]?.discount_percent
          : "";
      } else {
        return selectedData ? selectedData.price : "";
      }
    }
    return "";
  };

  const getMonthNumber = (monthString) => {
    const match = monthString.match(/\d+/);
    return match ? parseInt(match[0]) : "";
  };

  formX.bank_installment_month = selectedMonth && getMonthNumber(selectedMonth);

  const selectOptions = courseInstallmentData.map((item) => item.month);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await axios.post("/api/create-order/", formX);

    window.location = response?.data?.url;
  };

  return (
    <PromoStyle>
      {selectedMonth && (
        <div className="promoPart">
          <p> Promo kodu daxil et, daha az ödə </p>

          <form onSubmit={(e) => checkPromo(e)}>
            <TextInput
              id="promo"
              value={promoEntered}
              placeholder="Enter promo code"
              onChange={(e) => setPromoEntered(e.target.value)}
            />
            <Button variant="default" onClick={(e) => checkPromo(e)}>
              Check
            </Button>
          </form>

          {(promoDoesNotExist || promoResponse) && (
            <div className="submit">
              {promoDoesNotExist && (
                <h3 className="noPromo">This promo code does not exist</h3>
              )}
              {promoResponse && (
                <h3 className="yesPromo">
                  {promoResponse?.promocode[0]
                    ? promoResponse?.promocode?.filter(
                        (p) => p.code == promoEntered
                      )[0]?.discount_percent
                    : 0}
                  % of discount applied!
                </h3>
              )}
            </div>
          )}
        </div>
      )}

      <form onSubmit={handleSubmit}>
        <Select
          label="Neçə aylıq taksitlə ödəmək istəyirsiniz?"
          placeholder="Pick value"
          data={selectOptions}
          onChange={handleSelectChange}
        />
        <Button
          style={{ backgroundColor: "yellow" }}
          variant="default"
          type="submit"
          disabled={!selectedMonth}
        >
          Buy
        </Button>
      </form>
      {selectedMonth && (
        <p style={{ textAlign: "left" }}>
          Aylıq ödəniş: <b>{getPriceForSelectedMonth()}</b> AZN
        </p>
      )}
    </PromoStyle>
  );
}
