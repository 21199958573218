import { Link } from "react-router-dom";
import styled from "styled-components";
import pdfImage from "../../Assets/SVGs/pdfImage.svg";

export function BuyProgramFiller({ props }){

  const { user, entryData } = props;

  return(
    <BuyProgramStyle>
      <div className="wrapperLastSection">
        <div className="texts">
          <h1>Start Learning <br />With the best Platform</h1>
          <h3>Interested in this program?</h3>
        </div>
        <div className="links">
          {
            user?.id ?
            <button onClick={() => window.scrollTo(0, 450)}>Buy Program</button>
            :
            <Link className="loginLink" to="/login">Buy Program</Link>
          }
          <a className="downloadSyllabus" rel="noreferrer" target="_blank" href={entryData?.file}><img src={pdfImage} alt=""/>Download full syllabus</a>
        </div>
      </div>
    </BuyProgramStyle>
  )
}

const BuyProgramStyle = styled.div`
  width: 74%;
  margin: 0 auto;
  margin-bottom: 10rem;
  padding: 2rem;
  background-color: #6a5ae0;
  border-radius: 3.5rem;
  .wrapperLastSection{
    display: flex;
    width: 92%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    h1{
      font-size: clamp(1.6rem, 2.5vw, 2rem);
      color: #fff;
      font-weight: 500;
    }
    h3{
      color: #e0e0e0;
      font-weight: 500;
      font-size: clamp(1.2rem, 2.5vw, 1.4rem);
    }
    .links{
      display: flex;
      grid-gap: 1rem;
      a, button{
        border: none;
        text-decoration: none;
        color: #000;
        padding: 1rem;
        border-radius: 0.25rem;
        font-size: clamp(.9rem, 2.5vw, 1rem);
        cursor: pointer;
        transition: all 0.15s;
        &:hover{
          background-color: #ddd;
        }
      }
      button{
        background-color: #ffe01b;
        padding: 1rem 2rem;
      }
      a{
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        img{
          margin-right: .5rem;
        }
      }
    }
  }
  @media screen and (max-width:1024px){
    width: 100%;
    background-color: #6a5ae0;
    border-radius: 0;
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 1rem 0 2rem 0;
    margin: 0;
    .wrapperLastSection{
      flex-direction: column;
      .links{
        flex-direction: column;
      }
    }
  }
`