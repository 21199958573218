import {
  Button,
  Center,
  Container,
  FileButton,
  Group,
  Image,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useContext, useState } from "react";
import {
  AlignJustified,
  BoxMultiple,
  BrandGoogleAnalytics,
  Checkbox,
  Photo,
  Send,
  Video,
} from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";
import { useParams } from "react-router-dom";

export const PostCreator = ({ props }) => {
  const { id: community } = useParams();

  const { user } = useContext(AppContext);

  const [enteredText, setEnteredText] = useState("");
  const [chosenImage, setChosenImage] = useState(null);
  const [postVideo, setPostVideo] = useState({ active: false, content: "" });
  const [shareProgram, setShareProgram] = useState(false);

  const { data: courses, isLoading: coursesLoading } = useQuery(
    ["user courses"],
    async () =>
      await axios
        .get(`/api/usercourselist/${user?.id}/`)
        .then((res) => res.data)
  );
  const { data: internships, isLoading: internshipsLoading } = useQuery(
    ["user internships"],
    async () =>
      await axios
        .get(`/internship/userinternshiplist/${user?.id}/`)
        .then((res) => res.data)
  );

  const queryClient = useQueryClient();

  const { mutate, isLoading, isError, error } = useMutation(
    ["post"],
    async () => {
      let formX = new FormData();
      formX.append("user", user?.id);

      if (enteredText || chosenImage?.name) {
        formX.append(
          "description",
          chosenImage?.name && enteredText.length === 0 ? "." : enteredText
        );
      }

      if (
        shareProgram &&
        shareProgram.substring(shareProgram.length - 1, shareProgram.length) ===
          "c"
      ) {
        formX.append(
          "course",
          shareProgram.substring(0, shareProgram.length - 1)
        );
      }
      if (
        shareProgram &&
        shareProgram.substring(shareProgram.length - 1, shareProgram.length) ===
          "i"
      ) {
        formX.append(
          "internship",
          shareProgram.substring(0, shareProgram.length - 1)
        );
      }
      if (shareProgram && enteredText?.length < 1) {
        formX.append("description", ".");
      }
      if (community) {
        formX.append("community", community);
      }

      chosenImage?.name && formX.append("image", chosenImage);

      postVideo?.active && formX.append("video", postVideo?.content);
      postVideo?.active && formX.append("description", ".");

      return await axios.post("/blog/", formX);
    },
    {
      onSuccess: () => {
        setEnteredText("");
        setChosenImage(null);
        setPostVideo({ active: false, content: "" });
        setShareProgram(false);
        queryClient.invalidateQueries("feed");
      },
    }
  );

  let returnedCourses = [];
  returnedCourses = courses?.map((course) => {
    return {
      value: course.id + "c",
      label: course.title,
      group: "Courses",
    };
  });

  let returnedInternships = [];
  returnedInternships = internships?.map((internship) => {
    return {
      value: internship.id + "i",
      label: internship.title,
      group: "Internships",
    };
  });

  return (
    courses &&
    internships && (
      <>
        <Group mt={15} style={{ justifyContent: "space-between" }}>
          <Text>Create a post</Text>
          <Text>{enteredText?.length}/1000</Text>
        </Group>

        {isError && <Text color={"red"}>{error?.message}</Text>}

        <div
          p={0}
          m={0}
          style={{
            borderRadius: "0.75rem",
            overflow: "hidden",
            border: "1px solid #DEE2E6",
            backgroundColor: "#fff",
          }}
        >
          {postVideo?.active ? (
            <TextInput
              value={postVideo.content}
              placeholder={"Enter video url - https://..."}
              onChange={(e) =>
                enteredText.length < 1000 &&
                setPostVideo({ active: true, content: e.currentTarget.value })
              }
              m={5}
              mb={0}
              radius={"md"}
            />
          ) : (
            <Textarea
              value={enteredText}
              onChange={(e) =>
                enteredText.length < 1000 &&
                setEnteredText(e.currentTarget.value)
              }
              placeholder={
                chosenImage
                  ? "Add a title for your image"
                  : "What's on your mind?"
              }
              styles={{ input: { border: "none", borderRadius: "0" } }}
              minRows={chosenImage || shareProgram ? 1 : 3}
              autosize
              maxRows={6}
            />
          )}

          {chosenImage && (
            <Center>
              <Image
                mb={5}
                width={320}
                height={320}
                radius={"sm"}
                withPlaceholder
                src={URL?.createObjectURL(chosenImage)}
              />
            </Center>
          )}

          {shareProgram && (
            <Select
              label="Pick one of your programs"
              placeholder="Choose one"
              clearable
              nothingFound={"You haven't published any programs yet"}
              data={[...returnedInternships, ...returnedCourses]}
              styles={{ root: { padding: ".75rem", backgroundColor: "#fff" } }}
              onChange={(e) => setShareProgram(e)}
            />
          )}

          <Group
            style={{
              backgroundColor: "#fff",
              gap: 0,
              width: "100%",
              display: "grid",
              gridTemplateColumns: "1fr",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Group
              className="addPostButtons"
              p={10}
              style={{
                gap: "0.25rem",
                width: "100%",
                gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
              }}
            >
              {(chosenImage || postVideo.active || shareProgram) && (
                <Button
                  onClick={() => {
                    setChosenImage(null);
                    setShareProgram(false);
                    setPostVideo((prev) => ({ ...prev, active: false }));
                  }}
                  style={{ fontWeight: "500" }}
                  radius={".5rem"}
                  size="xs"
                  color={"gray.4"}
                  variant="default"
                  leftIcon={<AlignJustified />}
                >
                  Text
                </Button>
              )}

              {!chosenImage && (
                <div
                  onClick={() => {
                    setShareProgram(false);
                    setPostVideo((prev) => ({ ...prev, active: false }));
                  }}
                >
                  <FileButton
                    onChange={setChosenImage}
                    accept="image/png,image/jpeg"
                  >
                    {(props) => (
                      <Button
                        fullWidth
                        {...props}
                        style={{ fontWeight: "500" }}
                        radius={".5rem"}
                        size="xs"
                        color={"gray.4"}
                        variant="default"
                        leftIcon={<Photo />}
                      >
                        Photo
                      </Button>
                    )}
                  </FileButton>
                </div>
              )}

              {!postVideo.active && (
                <Button
                  onClick={() => {
                    setPostVideo((prev) => ({ ...prev, active: !prev.active }));
                    setShareProgram(false);
                    setChosenImage(null);
                  }}
                  style={{ fontWeight: "500" }}
                  radius={".5rem"}
                  size="xs"
                  color={"gray.4"}
                  variant="default"
                  leftIcon={<Video />}
                >
                  Video
                </Button>
              )}

              {!shareProgram && (
                <Button
                  onClick={() => {
                    setShareProgram((e) => !e);
                    setPostVideo((prev) => ({ ...prev, active: false }));
                    setChosenImage(null);
                  }}
                  style={{ fontWeight: "500" }}
                  radius={".5rem"}
                  size="xs"
                  color={"gray.4"}
                  variant="default"
                  leftIcon={<BoxMultiple size={22} />}
                >
                  Program
                </Button>
              )}

              <Button
                disabled
                style={{ fontWeight: "500" }}
                radius={".5rem"}
                size="xs"
                color={"gray.4"}
                variant="default"
                leftIcon={<BrandGoogleAnalytics size={22} />}
              >
                Poll
              </Button>
              <Button
                className="datamining"
                disabled
                style={{ fontWeight: "500" }}
                radius={".5rem"}
                size="xs"
                color={"gray.4"}
                variant="default"
                leftIcon={<Checkbox size={22} />}
              >
                Data mining
              </Button>
            </Group>
          </Group>

          <Button
            loading={isLoading}
            onClick={() => mutate()}
            fullWidth
            disabled={
              !postVideo.active &&
              enteredText?.length === 0 &&
              chosenImage === null &&
              shareProgram === false
            }
            radius={".5rem"}
            style={{ color: "black", fontWeight: "500", borderRadius: "0" }}
            size="sm"
            color={"ts"}
            variant="filled"
            leftIcon={<Send size={20} />}
          >
            Post
          </Button>
        </div>
      </>
    )
  );
};
