import axios from 'axios'
import styled from 'styled-components'
import { useState, useEffect } from 'react'
import { SectionContent } from './Sections/SectionContent'
import { Material } from './Material/_Material'
import { Link, useParams } from 'react-router-dom'
import { ChatDrawer } from '../../../Components/Chat/_Chat'
import { Button } from '@mantine/core'
import { ChevronRight, Messages, Lock, LockOpen } from "tabler-icons-react";
import { useQuery } from '@tanstack/react-query'

const CourseContent = ({props}) => {

  const { internshipId } = useParams();
  const {type} = props;
  const [ opened, setOpened ] = useState(false);
  const [ courseData, setEntryData ] = useState([]);
  const [ singleSectionContentFiltered, setSingleSectionContentFiltered ] = useState([])
  const [ showPart, setShowPart ] = useState({})
  const [ materialLoading, setMaterialLoading ] = useState(false)
  const [ currentSection, setCurrentSection ] = useState({ id:0, purchased: false, section_active:false })
  const [ visibilityModalOpen, setVisibilityModalOpen ] = useState(false)

  useEffect(() => {
    (async () => {
      setMaterialLoading(true)
      try {
        const response = await axios.get(`internship/${internshipId}/`)
        setEntryData(response.data);
        setMaterialLoading(false)
      } catch (error) {

        setMaterialLoading(false)
      }
    })()
    return () => {
      setEntryData([]);
    }
  }, []);

  const { data: tasksVisibility } = useQuery(["tasksVisibility"],
    async () => { return await axios.get(`/user/completed_course_internship/${internshipId}`).then(res => res.data) }
  )

  const { data:mentorId } = useQuery(['mentorId'],
    async () => {
      let response = await axios.get(`/mentor/interns/check_your_mentor/${courseData?.id}/`).then(res => res?.data)
      return response
    },
    { enabled: ( !!courseData.id ) }
  )

  return (
    <CourseContentStyled>

      <ChatDrawer props={{ mentorId, opened, setOpened, entryData:courseData, type:"internship", isMentorChat:false, studentToTest:{} }}/>
      <div className='weekInsideX'>
        <div className="currentLocation">
          <div>
            <Link to="/internships">All internships</Link>
            <ChevronRight/>
            <Link to="/internships">{courseData?.speciality && courseData?.speciality[0]?.content}</Link>
            <ChevronRight/>
            <Link to={`/internship/${courseData?.id}`}>{courseData?.title}</Link>
          </div>
        </div>
        <div className="wrapperX2">
          {
            <SectionContent props={{ mentorId, tasksVisibility, visibilityModalOpen, setVisibilityModalOpen, currentSection, setCurrentSection, materialLoading, setMaterialLoading, setOpened, showPart, setShowPart, singleSectionContentFiltered, setSingleSectionContentFiltered}}/>
          }
          {
            materialLoading ?
            <h2>Loading...</h2>
            :
            <Material props={{type, courseData, currentSection, showPart, singleSectionContentFiltered }}/>
          }
        </div>
        <Button disabled={mentorId?.length < 1} variant='default' className="openChatButton openChatButtonMobile" onClick={() => setOpened(true)} leftIcon={<Messages/>}>Discuss</Button>
        <div className="gradeVisibility gradeVisibilityMobile">
          {
            tasksVisibility?.internship == internshipId ?
            <Button color={"dark"} variant="outline" onClick={() => setVisibilityModalOpen(true)} leftIcon={<LockOpen/>}>Your tasks are visible to companies</Button>
            :
            <Button color={"dark"} variant="outline" onClick={() => setVisibilityModalOpen(true)} leftIcon={<Lock/>}>Show internship on profile</Button>
          }
        </div>
      </div>
    </CourseContentStyled>
  )
}

export default CourseContent

const CourseContentStyled = styled.div`
  h3,h2{
    font-weight: 500;
  }
  .gradeVisibility{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-top: 1rem;
    button{
      width: 96%;
      font-weight: 500;
    }
  }
  .gradeVisibilityMobile{
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-top: 1rem;
    button{
      width: 92%;
      font-weight: 500;
    }
  }
  .weekInsideX{
    width: 100%;
    background-color: #fff;
    border-top: 1px solid #eee;
    margin: 0 auto 5rem auto;
    .openChatButton{
      font-weight: 500;
    }
    .openChatButtonMobile{
      display: none;
    }
    .currentLocation{
      display: flex;
      align-items: center;
      overflow: scroll;
      scrollbar-width: none;
      width: 90%;
      margin: 1rem auto;
      -ms-overflow-style: none;
      ::-webkit-scrollbar {
        display: none;
      }
      div{
        color: #888;
        font-size: 15px;
        display: flex;
        align-items: center;
        min-width: max-content;
        a{
          display: flex;
          align-items: center;
          color: #000;
          text-decoration: none;
          min-width: max-content;
        }
        svg{
          margin: 0 .3rem;
        }
        b{
          min-width: max-content;
          text-decoration: none;
          color: #666;
          font-weight: 400;
        }
      }
    }
    .nav{
      display: flex;
      justify-content: space-between;
      margin: 1.8rem 0 1rem 0;
      button{
        border: none;
        background-color: #b6a5f5;
        color: #f8f8f8;
        font-size: 14px;
        border-radius: .5rem;
        height: max-content;
        font-weight: 500;
        padding: .85rem 3.8rem;
        cursor: pointer;
        transition: all .1s;
        &:hover{
          background-color: #917fcf;
        }
      }
      div{
        display: flex;
        align-items: center;
        margin-right: -1rem;
        margin-top: -1.2rem;
        p{
          font-weight: 500;
          margin: 1rem 0;
          margin-top: .8rem;
        }
        a:nth-child(1){
          transform: rotate(180deg);
          margin-top: -.4rem;
        }
        a{
          padding: 1rem;
        }
      }
    }
    .wrapperX2{
      width: 90%;
      margin: 0 auto;
      display: grid;
      grid-template-columns: 1fr 2.4fr;
      grid-gap: 1.5rem;
      margin-top: 3rem;
    }
    @media screen and (max-width:1024px){
      .gradeVisibility{
        display: none;
      }
      .gradeVisibilityMobile{
        display: flex;
      }
      background-color: #fff;
      border-top: 1px solid #eee;
      width: 100%;
      .openChatButton{
        display: none;
      }
      .openChatButtonMobile{
        display: flex;
        justify-content: center;
        width: 90%;
        margin: 2rem auto 0 auto;
      }
      .currentLocation{
        width: 90%;
        margin: 1rem auto;
      }
      .nav{
        display: none;
      }
      .wrapperX2{
        width: 90%;
        margin: 0 auto;
        grid-template-columns: 1fr;
        grid-gap: 0;
      }
    }
  }
`