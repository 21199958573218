import React from "react";
import { Button, Text } from "@mantine/core";
import { Link } from "react-router-dom";

const CommunityPostPublic = () => {
  return (
    <>
      <div
        style={{
          width: "500px",
          margin: "0 auto",
          padding: "300px",
        }}
      >
        <Text
          style={{
            fontSize: "20px",
            textAlign: "center",
            paddingBottom: "30px",
            fontWeight: "500",
          }}
        >
          Log in or register to see the post:
        </Text>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            styles={{
              root: {
                minWidth: "200px",
                fontWeight: "600",
                height: "60px",
                fontSize: "20px",
              },
            }}
            component={Link}
            to="/login"
            variant="light"
            color="gray.8"
          >
            Log in
          </Button>
          <Button
            styles={{
              root: {
                color: "#000",
                minWidth: "200px",
                fontWeight: "600",
                height: "60px",
                fontSize: "20px",
              },
            }}
            component={Link}
            to="/register"
            variant="filled"
            color="ts"
          >
            Register
          </Button>
        </div>
      </div>
    </>
  );
};

export default CommunityPostPublic;
