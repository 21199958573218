import { React } from "react";
import { ShowSidebar } from "../../../Components/Layout/LayoutHelper";
import { EditUserInfo } from "./EditUserInfo";
// import { DeleteProfile } from "./DeleteProfile"
import { AnimateOpa } from "../../../Components/AnimateOpa";

const UserSettings = ({ props }) => {
  const { isTaskool } = props;

  return (
    <ShowSidebar>
      <AnimateOpa>
        <EditUserInfo props={{ isTaskool }} />
        {/* <DeleteProfile /> */}
      </AnimateOpa>
    </ShowSidebar>
  );
};

export default UserSettings;
