import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import cib from "../Assets/Images/partners/cib.png";
import emobile from "../Assets/Images/partners/emobile.png";
import fakir from "../Assets/Images/partners/fakir.png";
import hafele from "../Assets/Images/partners/hafele.png";
import logix from "../Assets/Images/partners/logix.png";
import virtualCard from "../Assets/Images/partners/virtualCard.png";
import kapital from "../Assets/Images/partners/kapital.png";
import learntor from "../Assets/Images/partners/learntor.png";
import ealogo from "../Assets/Images/partners/ealogo.png";
import gamepons from "../Assets/Images/partners/gamepons.png";
import { useTranslation } from "react-i18next";

export const Partners = ({ props }) => {
  const { t } = useTranslation();

  const { showHeader } = props;

  return (
    <PartnersStyle showHeader={showHeader}>
      <h1>{t("our partners")}</h1>

      {/* <div className="partnerWrapper">
        <img src={kapital} alt="kapital" />
        <img src={virtualCard} alt="virtualCard" />
        <img src={logix} alt="logix" />
        <img src={fakir} alt="fakir" />
        <img src={hafele} alt="hafele" />
      </div>
      <div className="partnerWrapper">
        <img src={emobile} alt="emobile" />
        <img src={cib} alt="cib" />
        <img src={ealogo} alt="ealogo" />
        <img src={learntor} alt="learntor" />
        <img src={gamepons} alt="gamepons" />
      </div> */}
      <div className="partnerWrapper">
        <Swiper
          breakpoints={{
            300: {
              slidesPerView: 2,
            },
            600: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 4,
            },
            1200: {
              slidesPerView: 6,
            },
          }}
          modules={[Autoplay]}
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
          loop={true}
          spaceBetween={40}
          slidesPerView={6}
          onSlideChange={() => console.log("slide change")}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            {" "}
            <img src={kapital} alt="kapital" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={virtualCard} alt="virtualCard" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={logix} alt="logix" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={fakir} alt="fakir" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={hafele} alt="hafele" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={emobile} alt="emobile" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={cib} alt="cib" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={ealogo} alt="ealogo" />{" "}
          </SwiperSlide>
          <SwiperSlide>
            {" "}
            <img src={learntor} alt="learntor" />{" "}
          </SwiperSlide>

          <SwiperSlide>
            {" "}
            <img src={gamepons} alt="gamepons" />{" "}
          </SwiperSlide>
        </Swiper>
      </div>
    </PartnersStyle>
  );
};

const PartnersStyle = styled.div`
  background-color: #fff;
  margin: ${(props) => (props.showHeader ? "0rem 0 4rem 0" : "2rem 0 2rem 0")};
  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    font-weight: 500;
    color: #4f4f4f;
    margin-bottom: 1.75rem;
    margin-top: 1.75rem;
    font-size: ${(props) =>
      props.showHeader ? "clamp(1.6rem, 5vw, 2.2rem)" : "30px"};
  }
  img {
    width: 130px;
    height: 125px;
    object-fit: contain;
  }
  .partnerWrapper {
    display: flex;
    justify-content: center;
    width: 90%;
    width: ${(props) => (props.showHeader ? "100%" : "90%")};
    margin: 0 auto;
    flex-wrap: wrap;
    grid-gap: 2.5rem;
    align-items: center;
  }
  @media (max-width: 1025px) {
    .partnerWrapper {
      justify-content: space-around;
      width: ${(props) => (props.showHeader ? "90%" : "90%")};
    }
  }
  @media (max-width: 768px) {
    .partnerWrapper {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      width: 90%;
      width: ${(props) => (props.showHeader ? "80%" : "90%")};
      margin: 0 auto;
    }
  }
  @media (max-width: 365px) {
    .partnerWrapper {
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none;
      scrollbar-width: none;
      margin-left: -0.5rem;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      grid-gap: 1rem;
      justify-content: initial;
      width: 100%;
      overflow: hidden;
      overflow-x: scroll;
    }
  }
`;
