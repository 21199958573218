import React from 'react'

function GoogleLogin() {
  return (
    <div>

        <h1 style={{textAlign:'center',marginTop:'150px'}}>Google Login</h1>
        <p style={{textAlign:'center',marginBottom:'150px'}}>Google Login description</p>
    </div>
  )
}

export default GoogleLogin