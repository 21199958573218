import { useState, useContext } from "react";
import {
  Avatar,
  Button,
  Divider,
  Group,
  Text,
  TextInput,
  UnstyledButton,
  Center,
  Modal,
  Paper,
  Tooltip,
  CopyButton,
  Spoiler,
  Textarea,
} from "@mantine/core";
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { Link } from "react-router-dom";
import { Send, Trash } from "tabler-icons-react";
import moment from "moment";
import { motion } from "framer-motion";
import {
  MessageDots,
  MessageShare,
  ThumbUp,
  BrandFacebook,
  BrandLinkedin,
  BrandTwitter,
  BrandWhatsapp,
  Copy,
} from "tabler-icons-react";
import { AppContext } from "../Helpers/Context";

export function CommentSection({ props }) {
  const { post, ratePost, postReview, comments, refetch } = props;
  const { user } = useContext(AppContext);
  const [commentField, setCommentField] = useState({ comment: "" });
  const [showAllComments, setShowAllComments] = useState(false);
  const [displayedComments, setDisplayedComments] = useState(3);

  const [isTextareaOpen, setIsTextareaOpen] = useState(false);

  const queryClient = useQueryClient();

  const deleteComment = async (comment) => {
    await axios.delete(`/blog/comment/${comment?.id}/`);
    refetch();
  };

  const handleInputClick = () => {
    setIsTextareaOpen(true);
  };

  const handleTextareaBlur = () => {
    setIsTextareaOpen(false);
  };

  const [showModal, setShowModal] = useState(false);

  const handleShareFacebook = (url) => {
    const text = "Check that post in Taskilled:";
    const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      url
    )}&quote=${encodeURIComponent(text)}`;
    window.open(facebookShareUrl, "_blank");
  };

  const handleShareTwitter = (url) => {
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      "Check that post in Taskilled:"
    )} ${encodeURIComponent(url)}`;
    window.open(twitterShareUrl, "_blank");
  };

  const handleShareLinkedIn = (url) => {
    const text = "Check that post in Taskilled:";
    const linkedinShareUrl = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      url
    )}&title=${encodeURIComponent(text)}`;
    window.open(linkedinShareUrl, "_blank");
  };

  const handleShareWhatsApp = (url) => {
    const text = `Check that post in Taskilled: ${url}`;
    const whatsappShareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
      text
    )}`;
    window.open(whatsappShareUrl, "_blank");
  };

  const { mutate: postComment } = useMutation(
    ["post comment"],
    async (e) => {
      e.preventDefault();
      return await axios.post("/blog/comment/create/", {
        post: post?.id,
        user: user?.id,
        comment: commentField?.comment,
      });
    },
    {
      onSuccess: () => {
        setCommentField((prev) => ({ ...prev, comment: "" }));
        refetch();
      },
    }
  );

  const handleViewMoreClick = () => {
    setDisplayedComments((prev) => prev + 5);
    //setShowAllComments(true);
  };

  const renderDescription = (givenText) => {
    const urlRegex = /(https?:\/\/\S+)/g;
    const matches = givenText?.match(urlRegex);

    if (matches) {
      const elements = givenText?.split(urlRegex).map((part, index) =>
        index % 2 === 0 ? (
          <p key={index}>{part}</p>
        ) : (
          <a key={index} href={part} target="_blank" rel="noopener noreferrer">
            {part}
          </a>
        )
      );

      return <>{elements}</>;
    } else {
      return <p>{givenText}</p>;
    }
  };

  return (
    <>
      <Group
        style={{
          padding: "0 .25rem",
          backgroundColor: "#fff",
          gap: 0,
          width: "100%",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Group
          p={".25rem"}
          style={{
            gap: "0.25rem",
            width: "100%",
            display: "grid",
            gridTemplateColumns: "1fr 1fr 1fr",
          }}
        >
          <Button
            onClick={() => ratePost()}
            style={{ fontWeight: "500" }}
            radius={".5rem"}
            size="xs"
            color={"gray.4"}
            variant={"default"}
            leftIcon={
              <ThumbUp
                color={"black"}
                fill={postReview.current === true ? "gray" : "white"}
              />
            }
          >
            Like
          </Button>
          <Button
            onClick={() => {
              setCommentField((prev) => !prev);
            }}
            style={{ fontWeight: "500" }}
            radius={".5rem"}
            size="xs"
            color={"gray.4"}
            variant="default"
            leftIcon={<MessageDots />}
          >
            Comment
          </Button>
          <Button
            onClick={() => setShowModal(true)}
            style={{ fontWeight: "500" }}
            radius={".5rem"}
            size="xs"
            color={"gray.4"}
            variant="default"
            leftIcon={<MessageShare />}
          >
            Share
          </Button>
          <Modal
            mt={200}
            radius={"lg"}
            title={"Share post"}
            opened={showModal}
            onClose={() => setShowModal(false)}
          >
            <Center
              style={{
                display: "flex",
                flexDirection: "column",
                gap: ".75rem",
              }}
            >
              <div
                style={{ display: "flex", flexWrap: "wrap", gridGap: ".5rem" }}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Paper
                    radius={"xs"}
                    withBorder
                    py={6}
                    px={15}
                    style={{ width: "fit-content" }}
                  >
                    <Text>taskilled.com/posts/{post?.id}</Text>
                  </Paper>
                </div>
                <CopyButton value={`taskilled.com/posts/${post?.id}`}>
                  {({ copied, copy }) => (
                    <Tooltip label={copied ? "copied" : "copy post url"}>
                      <Button
                        style={{
                          borderTopLeftRadius: 0,
                          borderBottomLeftRadius: 0,
                        }}
                        px={"xs"}
                        color={copied ? "teal" : "gray.8"}
                        onClick={copy}
                        ml={".5rem"}
                      >
                        <Copy />
                      </Button>
                    </Tooltip>
                  )}
                </CopyButton>
              </div>
              <Group style={{ gap: ".5rem" }}>{/* <Share/> */}</Group>
              <Group>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareFacebook(
                      `https://taskilled.com/posts/${post?.id}`
                    )
                  }
                >
                  <BrandFacebook />
                </Button>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareTwitter(
                      `https://taskilled.com/posts/${post?.id}`
                    )
                  }
                >
                  <BrandTwitter />
                </Button>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareLinkedIn(
                      `https://taskilled.com/posts/${post?.id}`
                    )
                  }
                >
                  <BrandLinkedin />
                </Button>
                <Button
                  color="gray"
                  px={"xs"}
                  variant="default"
                  onClick={() =>
                    handleShareWhatsApp(
                      `https://taskilled.com/posts/${post?.id}`
                    )
                  }
                >
                  <BrandWhatsapp />
                </Button>
              </Group>
            </Center>
          </Modal>
        </Group>
      </Group>
      {commentField && (
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: -50, opacity: 0 }}
          key={post.id + "comments"}
        >
          <div>
            <form
              onSubmit={(e) => postComment(e)}
              style={{
                display: "flex",
                alignItems: "center",
                gap: ".5rem",
                margin: ".5rem",
              }}
            >
              <Avatar src={user?.profile_img} size={36} radius={100} />
              {isTextareaOpen ? (
                <Textarea
                  placeholder="Add a comment.."
                  styles={{ root: { width: "100%" } }}
                  value={commentField?.comment}
                  autoFocus
                  onChange={(e) =>
                    setCommentField((prev) => ({
                      ...prev,
                      comment: e.target.value,
                    }))
                  }
                  onBlur={handleTextareaBlur}
                />
              ) : (
                <TextInput
                  radius={100}
                  placeholder="Add a comment.."
                  styles={{ root: { width: "100%" } }}
                  value={commentField?.comment}
                  onChange={(e) =>
                    setCommentField((prev) => ({
                      ...prev,
                      comment: e.target.value,
                    }))
                  }
                  onClick={handleInputClick}
                />
              )}
              <Button
                type="submit"
                disabled={commentField?.comment?.length < 1}
                style={{ fontWeight: "500", color: "black" }}
                radius={100}
                color={"ts"}
                variant="filled"
                leftIcon={<Send size={20} />}
              >
                Send
              </Button>
            </form>
            <div style={{ margin: "1rem .5rem .5rem .5rem" }}>
              {comments?.results
                ?.slice(0, displayedComments)
                .reverse()
                .map((e, index) => {
                  return (
                    <>
                      {index !== 0 && <Divider my={8} color={"gray.2"} />}
                      <div style={{ display: "flex", gap: "1rem" }}>
                        <Avatar
                          mt={5}
                          src={e?.user?.profile_img}
                          size={36}
                          radius={100}
                        />
                        <div>
                          <div
                            style={{
                              display: "flex",
                              gap: "1rem",
                              alignItems: "center",
                            }}
                          >
                            <UnstyledButton
                              component={Link}
                              to={`/profile/${e?.user?.guid}`}
                            >
                              <Text style={{ fontWeight: 500 }}>
                                {e?.user?.first_name} {e?.user?.last_name}
                              </Text>
                            </UnstyledButton>
                            <Text
                              style={{
                                color: "gray",
                                fontSize: "14px",
                              }}
                            >
                              {moment(e?.timestamp).fromNow()}
                            </Text>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "end",
                              }}
                            >
                              {e?.user?.id === user?.id && (
                                <Trash
                                  cursor="pointer"
                                  color="red"
                                  onClick={() => deleteComment(e)}
                                />
                              )}
                            </div>
                          </div>
                          {e?.comment && (
                            <Spoiler
                              mt={-10}
                              mb={10}
                              maxHeight={120}
                              showLabel="Show more"
                              hideLabel="Hide"
                              styles={{ control: { color: "#868E96" } }}
                            >
                              {renderDescription(e?.comment)}
                            </Spoiler>
                          )}
                        </div>
                      </div>
                    </>
                  );
                })}
              {comments?.results?.length > displayedComments &&
                !showAllComments && (
                  <UnstyledButton
                    style={{ color: "#7a7a79" }}
                    mt={16}
                    onClick={handleViewMoreClick}
                    variant="link"
                  >
                    View more comments
                  </UnstyledButton>
                )}
            </div>
          </div>
        </motion.div>
      )}
    </>
  );
}
