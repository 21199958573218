import { createContext, useLayoutEffect, useState } from "react";
import { getCookie } from "./useCookie";
import Cookies from "universal-cookie";


export const AppContext = createContext();
const cookie = new Cookies()


export let aTokenX = "";

export const AppContextProvider = (props) => {
  const [aToken, setAToken] = useState("");
  const [rToken, setRToken] = useState("");
  const [postURL, setPostURL] = useState("");

  let [user, setUser] = useState(null);
  const [activeTab, setActiveTab] = useState("promo");
  const[newSidebarOpen,setNewSidebarOpen]=useState(false)
  const contextValues = {
    user,
    setUser,
    aToken,
    setAToken,
    rToken,
    setRToken,
    postURL,
    setPostURL,
    activeTab,
    setActiveTab,
    newSidebarOpen,
  setNewSidebarOpen
  };

  useLayoutEffect(() => {
    let userData = JSON.parse(localStorage.getItem("taskooluser"));
    if (userData !== undefined) {
      userData?.id !== undefined ? setUser(userData) : setUser(null);
      setAToken(cookie.get("access"));
      setRToken(cookie.get("refresh"));
    }
  }, []);

  useLayoutEffect(() => {
    setAToken(cookie.get("access"));
    setRToken(cookie.get("refresh"));
    aTokenX = cookie.get("access");
  }, [aToken, user]);

  return (
    <AppContext.Provider value={contextValues}>
      {props.children}
    </AppContext.Provider>
  );
};
