import React from "react";
import styled from "styled-components";
import arrowUp from "../../../Assets/SVGs/profile/arrow-up-right.svg";
import sliderRight from "../../../Assets/SVGs/profile/arrow-right.svg";
import sliderLeft from "../../../Assets/SVGs/profile/arrow-left.svg";
import arrowDown from "../../../Assets/SVGs/profile/arrow-down.svg";
import calendarIcon from "../../../Assets/SVGs/profile/calendar.svg";
import statisticUp from "../../../Assets/SVGs/profile/statistic-up.svg";

function ProjectTasks() {
  return (
    <ProjectTasksStyle>
      <div className="project-tasks-header">
        <h2>Project and tasks</h2>
        <div className="open-task-manager">
          {" "}
          Open task manager
          <img src={arrowUp} alt="arrow up" />
        </div>
      </div>

      <div className="project-slider-header">
        <div className="left">
          <div className="project-slider-arrows">
            <div className="project-arrow-item">
              <img src={sliderLeft} alt="" />
            </div>
            <div className="project-arrow-item">
              <img src={sliderRight} alt="" />
            </div>
          </div>

          <div className="project-date">
            <div className="calendar-icon">
              <img src={calendarIcon} alt="" />
            </div>
            2023, 18 September
          </div>
        </div>
        <div className="right">
          <div className="project-filter">
            Weekly <img src={arrowDown} alt="" />
          </div>
        </div>
      </div>

      <div className="project-statistics">
        <div className="project-statistic-item">
          <h4>Total time spend</h4>
          <p>12 h. 42 m.</p>
        </div>

        <div className="project-statistic-item">
          <h4>Total task and projects</h4>
          <p>136</p>
        </div>

        <div className="project-statistic-item">
          <h4>Done tasks</h4>
          <p>122</p>
        </div>

        <div className="project-statistic-item">
          <h4>Canceled tasks</h4>
          <p>14.</p>
        </div>

        <div className="project-statistic-item">
          <h4>Growth</h4>
          <p>
            36% <img src={statisticUp} alt="" />
          </p>
        </div>
      </div>

      <div className="project-tasks-box">
        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle cancelled"></div>
              Cancelled
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>

        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle todo"></div>
              To do
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>

        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle done"></div>
              Done
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>

        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle cancelled"></div>
              Cancelled
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>

        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle cancelled"></div>
              Cancelled
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>

        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle todo"></div>
              To do
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>

        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle done"></div>
              Done
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>

        <div className="project-task-item">
          <div className="task-date">23.01.24 / 01:32 - 02:15</div>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lor..
          </p>
          <div className="task-item-status-box">
            <div className="task-item-status">
              <div className="status-circle cancelled"></div>
              Cancelled
            </div>

            <div>
              <img src={arrowUp} alt="" />
            </div>
          </div>
        </div>
      </div>
    </ProjectTasksStyle>
  );
}

export default ProjectTasks;

const ProjectTasksStyle = styled.div`
  padding: 32px 40px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 24px;
  overflow: hidden;
  .project-tasks-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    h2 {
      color: #202939;
      font-family: "Euclid";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

    .open-task-manager {
      display: flex;
      gap: 12px;
      color: #1570ef;
      text-overflow: ellipsis;
      cursor: pointer;
      font-family: "Inter";
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 150% */
    }
  }

  .project-slider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .left {
      display: flex;
      align-items: center;
      gap: 16px;
      .project-slider-arrows {
        display: flex;
        gap: 16px;
        .project-arrow-item {
          border-radius: 12px;
          width: 48px;
          height: 48px;
          text-align: center;
          line-height: 60px;
          border: 1px solid #cdd5df;
          background: #fff;
        }
      }

      .project-date {
        border-radius: 12px;
        border: 1px solid #cdd5df;
        padding: 4px 20px 4px 4px;
        height: 48px;
        display: flex;
        align-items: center;
        gap: 16px;
        color: #0d1018;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        .calendar-icon {
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 48px;
          background: #eef2f6;
          border-radius: 8px;
        }
      }
    }

    .right {
      .project-filter {
        display: flex;
        gap: 16px;
      }
    }
  }

  .project-statistics {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 32px;
    .project-statistic-item {
      width: 100%;
      padding: 16px;
      border-radius: 16px;
      background: #fffcf5;

      h4 {
        color: #dc6803;
        font-family: "Inter";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 145.455% */
        margin: 0px;
        padding: 0px;
        margin-bottom: 2px;
      }

      p {
        color: #000;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
        margin: 0px;
      }
    }
  }

  .project-tasks-box {
    width:907px !important;
    display: flex;
    gap: 20px;
    padding-bottom: 32px;
    overflow-x:scroll;

   /* width */
::-webkit-scrollbar {
  height: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #EEF2F6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 2px;
background: #CDD5DF;
}


    .project-task-item {
      overflow: hidden;
      padding: 16px;
      border-radius: 16px;
      background: #fcfcfd;
      flex-basis: 240px;
    flex-grow: 0;
    flex-shrink: 0;

      .task-date {
        color: #697586;
        font-family: "Inter";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
      }

      p {
        color: #000;
        font-family: "Poppins";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .task-item-status-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .task-item-status {
          display: flex;
          align-items: center;
          color: #707070;
          font-family: "Inter";
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 150% */
          .status-circle {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            margin-right: 8px;
          }

          .cancelled {
            background: #f04438;
          }

          .todo {
            background: #6c737f;
          }

          .done {
            background: #17b26a;
          }
        }
      }
    }
  }
`;
