import { React } from "react";
import styled from "styled-components";
;

const About = () => {

  window.scrollTo(0, 0, 'smooth');

  return (
    <>

      <AboutStyles>
        <div>
          <h1>About us</h1>
          <p>Ta<ins>sk</ins>illed is a platform where you can gain new skills and build your portfolio from scratch by:</p>
          <ul>
            <li>taking courses on variety of topics provided by professionals </li>
            <li>joining internship programs across the world and completing real business tasks </li>
            <li>playing simulation games to acquire practical skills needed in modern business environment </li>
          </ul>
          <p>Our purpose is to help you grow personally and professionally. We are here to make knowledge accessible and affordable to you. Let’s grow up together!</p>
        </div>
      </AboutStyles>

    </>
  );
};

export default About;

const AboutStyles = styled.div`
  margin: 5%;
  div {
    background-color: #f8f8f8;
    width: min(90%, 50rem);
    padding: 5%;
    margin: 0 auto;
    border-radius: 10px;
    line-height: 1.35;
    h1 {
      font-weight: 500;
    }
    b {
      font-weight: 500;
    }
    p{
      font-size: clamp(.9rem, 2rem, 1.1rem);
    }
    ins{
      text-decoration: none;
      background-color: var(--yellow);
      border-radius: 0 0.3rem 0 .3rem;
    }
  }
`;
