import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../Helpers/Context";
import axios from "axios";
import { Button } from "@mantine/core";
import { Lock } from "tabler-icons-react";
import { useQuery } from "@tanstack/react-query";

export function UnpurchasedSection({ props }) {

  const { user } = useContext(AppContext)
  const { courseData, currentSection } = props

  const [ sections, setSections ] = useState([]);

  const [ order, setOrder ] = useState({});
  const [ buyCourse, setBuyCourse ] = useState(false);

  const { } = useQuery(["sections list", courseData.id],
    async () => await axios.get(`internshipsection/section-list/${courseData?.id}/`).then(res => res.data),
    {
      enabled: !!courseData.id,
      onSuccess: (data) => {
        let filtered = data.map(section => {
          if(section.is_final_project === false){
            return {
              id: section.id,
              title: section.title,
              chosen: false,
              section_active: section.section_active,
              is_final_project: section.is_final_project,
            }
          }
        })
        setSections(filtered.filter(e => e !== undefined))
      }
    }
  )

  useEffect(() => {
    if(order.url){
      localStorage.setItem("CPOrderIdTaskool", order.order_id)
      window.location = order.url
    }
  }, [order]);

  useEffect(() => {

    if(buyCourse === true){
      (async () => {
        const response = await axios.post(`/internship/create-internorder/`,{
          intershipinfo: courseData?.id,
          user: user?.id,
          promocode: "",
          installment_paid: true,
          sections: [currentSection.id],
        })

        if(response.status === 201){
          setOrder(response.data);
        }
      })();
    }
  }, [buyCourse])

  function buySectionAlone(){
    setBuyCourse(true);
  }

  return(
    sections?.length > 0 &&
    <div className="unpurchasedSection" style={{ marginLeft: "1rem" }}>
      <h2 style={{display:"flex",alignItems:"center", gridGap:"1rem"}}><Lock/> {currentSection.title} - {(courseData.price / sections.length).toFixed(2)} AZN</h2>
      <h3>You have not purchased this section yet</h3>
      <p></p>
      <div style={{ display:"flex", gridGap:"1rem" }}>
        <Button variant="gradient" gradient={{ from: 'teal', to: 'lime', deg: 105 }}
          onClick={() => buySectionAlone()}>
            Buy Section
        </Button>
      </div>
    </div>
  )
}
