import { React, useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import { Minus, Plus } from "tabler-icons-react";
import { showNotification } from "@mantine/notifications";
import { Button, Checkbox, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Partition } from "../../../Components/Partition";

export const Education = ({ props }) => {
  const { user, isTaskool } = props;
  const [addSign, setAddSign] = useState(true);

  let texts = isTaskool
    ? {
        header: "Təhsil",
        bSend: "Göndər",
        bEdit: "Redaktə et",
        bDelete: "Sil",
        bCancel: "Ləğv et",
        l1: "Təhsil ocağı",
        l2: "Təhsil növü",
        l3: "Başlama tarixi",
        l4: "Hazırda oxuyuram",
        l5: "Bitmə tarixi",
        p1: "10 nömrəli liset",
        p2: "Orta təhsil",
        present: "İndi",
        nt1: "Təshil əlavə edildi",
        nt2: "Təshil redaktə edildi",
        nt3: "Təshil silindi",
        ntm1: "Təshil uğurla əlavə edildi",
        ntm2: "Təshil uğurla redaktə edildi",
        ntm3: "Təshil uğurla silindi",
        e1: "Təshil əlavə edilərkən xəta baş verdi",
        e2: "Zəhmət olmasa tələb olunan xanaları doldurun",
        e3: "Hal-hazırda işə getmirsinizsə, bitmə tarixini doldurmalısınız",
      }
    : {
        header: "Education",
        bSend: "Submit",
        bEdit: "Edit",
        bDelete: "Remove",
        bCancel: "Cancel",
        l1: "College",
        l2: "Education",
        l3: "Start date",
        l4: "Currently studying",
        l5: "End date",
        p1: "University of Warsaw",
        p2: "Computer Science",
        present: "Present",
        nt1: "Education added",
        nt2: "Education edited",
        nt3: "Education deleted",
        ntm1: "Education successfully added",
        ntm2: "Education successfully edited",
        ntm3: "Education successfully deleted",
        e1: "An error occurred while adding education",
        e2: "Please fill in the required fields",
        e3: "If you are not currently working, please fill in the end date",
      };

  let [educationArray, setEducationArray] = useState([]);
  let [currentEditing, setCurrentEditing] = useState(0);

  // const notifications = useNotifications();

  async function fetchData() {
    try {
      const fetchedExperiences = await (
        await axios.get(`/api/educationbyuserid/${user?.id}/`)
      ).data;
      fetchedExperiences.sort((a, b) => a.id - b.id);
      setEducationArray(fetchedExperiences);
    } catch (error) {}
  }

  // Fetches data when the page is loaded
  useEffect(() => {
    fetchData();
    return () => {
      setEducationArray([]);
    };
  }, [user]);

  // Adds new skill
  function addInput() {
    // If there is no current editing, set current editing to the last index

    let educationIds = educationArray.map(function (education) {
      return education.id;
    });

    if (educationIds.includes(currentEditing.id)) {
      setCurrentEditing(0);
      setEducationArray((educations) => {
        const newEducation = [...educations];
        newEducation.push({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        return newEducation;
      });
    }

    if (currentEditing === 0) {
      // setCurrentEditing(0)
      setEducationArray((educations) => {
        const newEducation = [...educations];
        newEducation.push({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: educationArray.length
            ? educationArray[educationArray?.length - 1]?.id + 1
            : 2,
        });
        return newEducation;
      });
    }
  }

  function handleEdit(index) {
    if (addSign === false) {
      setAddSign(true);
    }
    setCurrentEditing(educationArray[index]);

    // filter out entries from educationArray if college is undefined
    setEducationArray(
      educationArray.filter((education) => education.college !== undefined)
    );
  }

  // Deletes skill
  async function handleDelete(e, index) {
    setCurrentEditing(0);
    // filter out entries from educationArray if college is undefined
    setEducationArray(
      educationArray.filter((education) => education.college !== undefined)
    );

    try {
      // deletes skill from database
      const deletedEducation = await axios.delete(
        `api/education/${educationArray[index].id}/`
      );

      setEducationArray(
        educationArray.filter(
          (education) => education.id !== educationArray[index].id
        )
      );

      if (deletedEducation.status === 204) {
        showNotification({
          title: texts?.nt3,
          message: texts?.ntm3,
          color: "white",
        });
      }

      // reFetches educations and sorts them in ascending order after deleting one
      // const fetchedEducation = await axios.get("api/education/");
      // fetchedEducation.data.sort((a, b) => a.id - b.id);

      // setEducationArray(
      //   Object.keys(fetchedEducation.data).map(function (key) {
      //     return fetchedEducation.data[key];
      //   })
      // );
    } catch (error) {}
  }

  // Requests change to skill
  async function patchOrPost(e, index) {
    let item = educationArray[educationArray.length - 1];

    if (!item.college || !item.education || !item.start_date) {
      showNotification({
        title: texts?.e1,
        message: texts?.e2,
        color: "red",
      });
    } else if (
      (item.is_present === undefined || item.is_present === false) &&
      item.end_date === undefined
    ) {
      showNotification({
        title: texts?.e1,
        message: texts?.e3,
        color: "yellow",
      });
    } else {
      setCurrentEditing(0);

      try {
        setAddSign(true);
        const updatedEducation = await axios.patch(
          `api/education/${currentEditing.id}/`,
          currentEditing
        );

        if (updatedEducation.status === 200) {
          setEducationArray((educations) => {
            const newEducation = [...educations];
            newEducation[index].is_present = false;
            return newEducation;
          });

          fetchData();
          showNotification({
            title: texts?.nt2,
            message: texts?.ntm2,
            color: "white",
          });
        }
      } catch (error) {
        try {
          if (
            error?.response?.status === 404 ||
            error?.response?.status === 403 ||
            error?.response?.status === 400
          ) {
            const formX = educationArray[educationArray.length - 1];
            formX.id = currentEditing.id;
            formX.user = user?.id;
            const response = await axios.post("api/create-education/", formX);

            if (response.status === 201) {
              fetchData();
              showNotification({
                title: texts?.nt1,
                message: texts?.ntm1,
                color: "green",
              });
            }
          }
        } catch (error) {}
      }
    }
  }

  // Cancel editing
  function handleCancel() {
    setCurrentEditing(0);
    // filter out entries from educationArray if college is undefined
    setEducationArray(
      educationArray.filter((education) => education.college !== undefined)
    );
  }

  return (
    <Partition>
      <Educationstyle>
        <div className="title">
          <h2>{texts?.header}</h2>
          {addSign ? (
            <Button
              variant="default"
              className="addItem"
              type="button"
              onClick={() => {
                setAddSign(false);
                addInput(true);
              }}
            >
              <Plus />
            </Button>
          ) : (
            <Button
              variant="default"
              className="addItem"
              type="button"
              onClick={() => {
                handleCancel();
                setAddSign(true);
              }}
            >
              <Minus />
            </Button>
          )}
        </div>

        <form onSubmit={(e) => e.preventDefault()}>
          {educationArray.map((education, index) => {
            return currentEditing?.id === education?.id ? (
              <div
                key={index}
                className="editing insideWrapper"
                id={education.id}
              >
                <div>
                  <TextInput
                    required
                    name="college"
                    placeholder={texts?.p1}
                    label={texts?.l1}
                    value={education.college}
                    onChange={(e) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        newEducation[index].college = e.target.value;
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  <TextInput
                    required
                    name="education"
                    placeholder={texts?.p2}
                    label={texts?.l2}
                    value={education.education}
                    onChange={(e) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        newEducation[index].education = e.target.value;
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  <DatePicker
                    required
                    name="start_date"
                    label={texts?.l3}
                    placeholder="15.09.2016"
                    value={
                      education.start_date
                        ? new Date(education.start_date)
                        : null
                    }
                    onChange={(date) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        let dateX = new Date(date);
                        dateX.setDate(dateX.getDate() + 1);
                        newEducation[index].start_date = dateX
                          .toISOString()
                          .split("T")[0];
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  <Checkbox
                    name="is_present"
                    label={texts?.l4}
                    required
                    checked={education.is_present}
                    onChange={(e) => {
                      setEducationArray((educations) => {
                        const newEducation = [...educations];
                        newEducation[index].is_present = e.target.checked;
                        return newEducation;
                      });
                    }}
                  />
                  <br />

                  {!education.is_present && (
                    <DatePicker
                      required={!education.is_present}
                      name="end_date"
                      label={texts?.l5}
                      placeholder="21.05.2020"
                      value={
                        education.end_date ? new Date(education.end_date) : null
                      }
                      onChange={(date) => {
                        setEducationArray((educations) => {
                          const newEducation = [...educations];
                          let dateX = new Date(date);
                          dateX.setDate(dateX.getDate() + 1);
                          newEducation[index].end_date = dateX
                            .toISOString()
                            .split("T")[0];
                          return newEducation;
                        });
                      }}
                    />
                  )}
                  <br />
                </div>

                <div className="buttons">
                  <div className="groupOne">
                    <Button
                      variant="outline"
                      color="teal"
                      type="button"
                      onClick={(e) => patchOrPost(e, index)}
                    >
                      {texts?.bSend}
                    </Button>
                    <Button
                      variant="outline"
                      color="gray"
                      type="button"
                      onClick={() => {
                        setAddSign(true);
                        handleCancel();
                      }}
                    >
                      {texts?.bCancel}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} id={education.id} className="insideWrapper">
                <div className="box">
                  <div className="info">
                    <h3>{education.college}</h3>
                    <p>{education.education}</p>
                    <ins>
                      {education.is_present === true
                        ? education?.start_date + " - İndi"
                        : education?.start_date + " - " + education?.end_date}
                    </ins>
                  </div>
                  <div className="manageEntry">
                    <Button
                      variant="subtle"
                      type="button"
                      onClick={() => {
                        setAddSign(true);
                        handleEdit(index);
                      }}
                    >
                      {texts?.bEdit}
                    </Button>
                    <Button
                      variant="outline"
                      color="red"
                      type="button"
                      className="removeSkill"
                      onClick={(e) => {
                        setAddSign(true);
                        handleDelete(e, index);
                      }}
                    >
                      {texts?.bDelete}
                    </Button>
                  </div>
                </div>
                <hr />
              </div>
            );
          })}
        </form>
      </Educationstyle>
    </Partition>
  );
};

const Educationstyle = styled.div`
  button {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .insideWrapper {
    width: 95%;
    margin: 0 auto;
    margin-top: 1rem;
    hr {
      border: none;
      border-bottom: 1px solid #ddd;
      margin-top: 1rem;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addItem {
      background: none;
      font-size: 17px;
      color: #2f80ed;
      font-weight: 400;
      border: none;
      cursor: pointer;
    }
    svg {
      color: #000;
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .manageEntry {
      display: flex;
      button {
        display: flex;
        border: none;
        font-weight: 500;
      }
    }
    .info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      h3 {
        margin: 0;
        font-size: 17px;
      }
      p {
        margin: 0.5rem 0;
      }
      ins {
        margin: 0;
        text-decoration: none;
        font-size: 14px;
        color: #666;
      }
    }
  }
  .editing {
    padding: 1rem 1.25rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    align-items: center;
    .buttons {
      .groupOne {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
      }
      button {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .insideWrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
`;
