import React from "react";
import { Button } from "@mantine/core";
import styled from "styled-components";
import { Partition } from "../../../Components/Partition";
import { SoftModal } from "../../../Components/SoftModal";

function SoftSkillsQuiz() {
  return (
    <Partition>
      <SoftTest>
        <div className="title">
          <h2>Soft Skills</h2>
        </div>
        <SoftModal />
      </SoftTest>
    </Partition>
  );
}

const SoftTest = styled.div`
  button {
    margin: 0 auto;
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addItem {
      background: none;
      font-size: 17px;
      color: #2f80ed;
      font-weight: 400;
      border: none;
      cursor: pointer;
    }
  }
  h2 {
    font-weight: 500;
  }

  @media screen and (max-width: 768px) {
    .insideWrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
`;

export default SoftSkillsQuiz;
