import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AnimateOpa } from "./AnimateOpa";
import { ShowSidebar } from "./Layout/LayoutHelper";
import axios from "axios";
import { Button, Container, Group, Text, TextInput } from "@mantine/core";
import { Plus, X } from "tabler-icons-react";
import { useContext } from "react";
import { AppContext } from "../Helpers/Context";
import { useParams } from "react-router-dom";

const Teams = () => {

  const { user } = useContext(AppContext)

  const { id } = useParams()

  const queryClient = useQueryClient()

  const { data:userTeam} = useQuery([`user team ${id}`], 
    async () => await axios.get(`hackathon/teambyid/${id}/`).then(res => res.data)
  )

  const { mutate:addMember, isError, error, isLoading  } = useMutation([`add member`], 
    async (e) => {
      e.preventDefault()

      return await axios.patch(`/hackathon/team_edit/${id}/?member_email=${e?.target?.email?.value}`)
      .then(res => res.data)
    },
    {
      onSuccess: () => queryClient.invalidateQueries([`user team ${id}`])
    }
  )

  const { mutate:removeUser, isErrorRemove, errorRemove, isLoadingRemove  } = useMutation([`remove member`], 
    async (e) => await axios.delete(`/hackathon/team_edit/${id}/?member_email=${e}`),
    {
      onSuccess: () => queryClient.invalidateQueries([`user team ${id}`])
    }
  )

  if(!userTeam){
    return
  }

  return (
    <ShowSidebar>
      <AnimateOpa>
        <div style={{ backgroundColor:"#fff", borderRadius:"0.75rem", padding:"2rem", border: "1px solid #DEE2E6" }}>
          <div style={{ display:"flex", alignItems:"center", justifyContent:"space-between" }}>
            <Text size={22}>Team - {userTeam[0]?.name}</Text>
          </div>
          {
            userTeam[0]?.user?.id === user?.id &&
            <form onSubmit={(e) => addMember(e)}>
              <Group style={{ gap:".5rem" }} mt={15}>
                <TextInput
                  required
                  name="email"
                  placeholder="Enter an email"
                  label="Add members via email"
                />
                <Button loading={isLoading} type="submit" mt={23} color="gray.8" leftIcon={<Plus/>}>Add</Button>
              </Group>
              {
                isError && <Text mt={10} color="red">{error?.response?.data?.detail}</Text>
              }
            </form>
          }
          <Container m={0} mt={15} p={0}>
            <Text>Members: {userTeam[0]?.members?.length}</Text>
            {
              userTeam[0]?.members?.length > 0 &&
              <Container p={0} py={10} style={{ borderRadius:".5rem" }}>
                {
                  userTeam[0]?.members.map((e, i) => 
                  <Group key={e.id} my={3}>
                    <Button onClick={() => removeUser(e?.email)} px={5} size="xs" color="red"><X size={20}/></Button>
                    <Text>{i + 1 + ". " + e.first_name + " " + e.last_name}</Text>
                  </Group>
                  )
                }
              </Container>
            }
          </Container>
        </div>
      </AnimateOpa>
    </ShowSidebar>
  );
};

export default Teams