import { React, useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { showNotification } from "@mantine/notifications";
import { Minus, Plus } from "tabler-icons-react";
import { Button, Checkbox, TextInput } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import { Partition } from "../../../Components/Partition";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

export const Experience = ({ props }) => {
  const { t, i18n } = useTranslation();
  const { user, isTaskool } = props;
  const location = useLocation();

  let [experienceArray, setExperienceArray] = useState([]);
  let [currentEditing, setCurrentEditing] = useState(0);
  let [addSign, setAddSign] = useState(true);

  // const notifications = useNotifications();

  async function fetchData() {
    try {
      const fetchedExperience = await axios.get("api/experience/");
      fetchedExperience.data.sort((a, b) => a.id - b.id);
      setExperienceArray(
        Object.keys(fetchedExperience.data).map(function (key) {
          return fetchedExperience.data[key];
        })
      );
    } catch (error) {}
  }

  // Fetches data when the page is loaded
  useEffect(() => {
    fetchData();
    return () => {
      setExperienceArray([]);
    };
  }, []);

  // Adds new experience
  function addInput() {
    // If there is no current editing, set current editing to the last index

    let experienceIds = experienceArray.map(function (experience) {
      return experience.id;
    });

    if (experienceIds.includes(currentEditing.id)) {
      setCurrentEditing(0);
      setExperienceArray((experiences) => {
        const newExperience = [...experiences];
        newExperience.push({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        return newExperience;
      });
    }

    if (currentEditing === 0) {
      // setCurrentEditing(0)
      setExperienceArray((experiences) => {
        const newExperience = [...experiences];
        newExperience.push({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        setCurrentEditing({
          id: experienceArray.length
            ? experienceArray[experienceArray?.length - 1]?.id + 1
            : 2,
        });
        return newExperience;
      });
    }
  }

  function handleEdit(index) {
    if (addSign === false) {
      setAddSign(true);
    }
    setCurrentEditing(experienceArray[index]);

    setExperienceArray(
      experienceArray.filter(
        (experience) => experience.job_position !== undefined
      )
    );
  }

  // Deletes skill
  async function handleDelete(index) {
    setCurrentEditing(0);
    setExperienceArray(
      experienceArray.filter(
        (experience) => experience.job_position !== undefined
      )
    );

    try {
      // deletes skill from database
      const deletedExperience = await axios.delete(
        `api/experience/${experienceArray[index].id}/`
      );
      setExperienceArray(
        experienceArray.filter(
          (experience) => experience.id !== experienceArray[index].id
        )
      );

      if (deletedExperience.status === 204) {
        showNotification({
          title: t("experience-deleted"),
          message: t("experience-deleted-succesfully"),
          color: "white",
        });
      }

      // // reFetches experiences and sorts them in ascending order after deleting one
      // const fetchedExperience = await axios.get("api/experience/");
      // fetchedExperience.data.sort((a, b) => a.id - b.id);

      // setExperienceArray(
      //   Object.keys(fetchedExperience.data).map(function (key) {
      //     return fetchedExperience.data[key];
      //   })
      // );
    } catch (error) {}
  }

  // Requests change to skill
  async function patchOrPost(e, index) {
    let item = experienceArray[experienceArray.length - 1];

    if (!item.job_position || !item.job_status || !item.company) {
      showNotification({
        title: t("experience-error"),
        message: t("please-fill-experience"),
        color: "red",
      });
    } else if (
      (item.is_present === undefined || item.is_present === false) &&
      (item.end_date === undefined || item.end_date === null)
    ) {
      showNotification({
        title: t("experience-error"),
        message: t("not-work-anymore-exp"),
        color: "yellow",
      });
    } else {
      setCurrentEditing(0);

      try {
        setAddSign(true);
        const updatedExperience = await axios.patch(
          `api/experience/${currentEditing.id}/`,
          currentEditing
        );

        if (updatedExperience.status === 200) {
          setExperienceArray((experiences) => {
            const newExperience = [...experiences];
            newExperience[index].is_present = false;
            return newExperience;
          });

          fetchData();
          showNotification({
            title: t("experience-edited"),
            message: t("experience-edited-succesfully"),
            color: "white",
          });
        }
      } catch (error) {
        try {
          if (
            error?.response?.status === 404 ||
            error?.response?.status === 403 ||
            error?.response?.status === 400
          ) {
            const formX = experienceArray[experienceArray.length - 1];
            formX.id = currentEditing.id;
            formX.user = user?.id;
            const response = await axios.post("api/create-experience/", formX);

            if (response.status === 201) {
              fetchData();
              showNotification({
                title: t("experience-added"),
                message: t("experience-added-succesfully"),
                color: "green",
              });
            }
          }
        } catch (error) {}
      }
    }
  }

  // Cancel editing
  function handleCancel() {
    setCurrentEditing(0);
    setExperienceArray(
      experienceArray.filter(
        (experience) => experience.job_position !== undefined
      )
    );
  }

  return (
    <Partition>
      <ExperienceStyle>
        <div className="title">
          <h2>{t("experience")}</h2>
          {addSign ? (
            <Button
              variant="default"
              className="addItem"
              type="button"
              onClick={() => {
                addInput(true);
                setAddSign(false);
              }}
            >
              <Plus />
            </Button>
          ) : (
            <Button
              variant="default"
              className="addItem"
              type="button"
              onClick={() => {
                handleCancel();
                setAddSign(true);
              }}
            >
              <Minus />
            </Button>
          )}
        </div>
        <form
          onSubmit={(e) => e.preventDefault()}
          style={{
            transition: "max-height 0.15s ease-out",
          }}
        >
          {experienceArray.map((experience, index) => {
            return currentEditing?.id === experience?.id ? (
              <div
                key={index}
                className="editing insideWrapper"
                id={experience?.id}
              >
                <div>
                  <TextInput
                    required
                    name="job_position"
                    placeholder={t("experience-programmer")}
                    label={t("job-title")}
                    value={experience?.job_position}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].job_position = e.target.value;
                        return newExperience;
                      });
                    }}
                  />

                  <br />
                  <TextInput
                    required
                    name="company"
                    placeholder={t("experience-taskool")}
                    label={t("company")}
                    value={experience?.company}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].company = e.target.value;
                        return newExperience;
                      });
                    }}
                  />

                  <br />
                  <TextInput
                    required
                    name="job_status"
                    placeholder={t("experience-fulltime")}
                    label={t("job-status")}
                    value={experience?.job_status}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].job_status = e.target.value;
                        return newExperience;
                      });
                    }}
                  />
                  <br />

                  <DatePicker
                    required
                    name="start_date"
                    label={t("start-date")}
                    placeholder="15.10.2019"
                    value={
                      experience?.start_date
                        ? new Date(experience?.start_date)
                        : null
                    }
                    onChange={(date) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        let dateX = new Date(date);
                        dateX.setDate(dateX.getDate() + 1);
                        newExperience[index].start_date = dateX
                          .toISOString()
                          .split("T")[0];
                        return newExperience;
                      });
                    }}
                  />
                  <br />

                  <Checkbox
                    name="is_present"
                    label={t("currently-work-place")}
                    required
                    checked={experience?.is_present}
                    onChange={(e) => {
                      setExperienceArray((experiences) => {
                        const newExperience = [...experiences];
                        newExperience[index].is_present = e.target.checked;
                        return newExperience;
                      });
                    }}
                  />
                  <br />

                  {!experience?.is_present && (
                    <DatePicker
                      required={!experience?.is_present}
                      name="end_date"
                      label={t("end-date")}
                      placeholder="02.01.2022"
                      value={
                        experience?.end_date
                          ? new Date(experience?.end_date)
                          : null
                      }
                      onChange={(date) => {
                        setExperienceArray((experiences) => {
                          const newExperience = [...experiences];
                          let dateX = new Date(date);
                          dateX.setDate(dateX.getDate() + 1);
                          newExperience[index].end_date = dateX
                            .toISOString()
                            .split("T")[0];
                          return newExperience;
                        });
                      }}
                    />
                  )}
                  <br />
                </div>

                <div className="buttons">
                  <div className="groupOne">
                    <Button
                      variant="outline"
                      color="teal"
                      type="button"
                      onClick={(e) => patchOrPost(e, index)}
                    >
                      {t("send-button-experience")}
                    </Button>
                    <Button
                      variant="outline"
                      color="gray"
                      type="button"
                      onClick={() => {
                        setAddSign(true);
                        handleCancel();
                      }}
                    >
                      {t("cancel-button-experience")}
                    </Button>
                  </div>
                </div>
              </div>
            ) : (
              <div key={index} id={experience?.id} className="insideWrapper">
                {index !== 0 && <hr />}
                <div className="box">
                  <div className="info">
                    <h3>{experience?.job_position}</h3>
                    <p>
                      {experience?.company}, {experience?.job_status}
                    </p>
                    <ins>
                      {experience?.is_present === true
                        ? experience?.start_date +
                          ` - ${t("experience-present")}`
                        : experience?.start_date + " - " + experience?.end_date}
                    </ins>
                  </div>
                  <div className="manageEntry">
                    <Button
                      variant="subtle"
                      type="button"
                      onClick={() => handleEdit(index)}
                    >
                      {t("edit-button-experience")}
                    </Button>
                    <Button
                      variant="outline"
                      color="red"
                      type="button"
                      className="removeSkill"
                      onClick={() => handleDelete(index)}
                    >
                      {t("delete-button-experience")}
                    </Button>
                  </div>
                </div>
              </div>
            );
          })}
        </form>
      </ExperienceStyle>
    </Partition>
  );
};

const ExperienceStyle = styled.div`
  button {
    display: flex;
    align-items: center;
    text-align: center;
    font-weight: 500;
    font-size: clamp(1rem, 2vw, 1.05rem);
  }
  h2,
  h3 {
    font-weight: 500;
  }
  .insideWrapper {
    margin-top: 1rem;
    width: 95%;
    margin: 0 auto;
    margin-top: 1rem;
    hr {
      border: none;
      border-bottom: 1px solid #ddd;
      margin-top: 1rem;
    }
  }
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .addItem {
      background: none;
      font-size: 17px;
      color: #2f80ed;
      font-weight: 400;
      border: none;
      cursor: pointer;
    }
    svg {
      color: #000;
    }
  }
  .box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
    .manageEntry {
      display: flex;
      button {
        display: flex;
        border: none;
        font-weight: 500;
      }
    }
    .info {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      h3 {
        margin: 0;
        font-size: 17px;
      }
      p {
        margin: 0.5rem 0;
      }
      ins {
        margin: 0;
        text-decoration: none;
        font-size: 14px;
        color: #666;
      }
    }
  }
  .editing {
    padding: 1rem 1.25rem;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    align-items: center;
    .buttons {
      .groupOne {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1rem;
      }
      button {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
      }
    }
  }
  @media screen and (max-width: 768px) {
    .insideWrapper {
      width: 100%;
      margin: 0 auto;
    }
  }
`;
