import { useContext, useState } from "react"
import taskilledShadowLogo from "../../../Assets/Images/taskilledShadowLogo.png"
import taskoolShadowLogo from "../../../Assets/Images/taskoolShadowLogo.png"
import { Link, useLocation } from "react-router-dom"
import { AppContext } from "../../../Helpers/Context"
import { Menu2, User } from "tabler-icons-react"
import { SearchBarComponent } from "../../SearchBar";
import { Categories } from "./Categories";
import { NotificationIndicator } from "./NotificationIndicator";
import { NavbarStyle } from "./NabarStyle";
import { DesktopMenu, MobileMenu } from "./Menu";
import { Button, Group, Image } from "@mantine/core";
import {useMutation} from '@tanstack/react-query'
import axios from '../../../Helpers/Axios'
import { getCookie } from "../../../Helpers/useCookie"
import Cookies from "universal-cookie";


function UserControls({ props }) {

  const { user, logout, register, isTaskool } = props;

  const texts = isTaskool ?
    {
      logIn: "Daxil ol",
      register: "Qeydiyyat",
    }
    :
    {
      logIn: "Log in",
      register: "Register",
    }

  if (register) {
    return (
      <Group>
        <Categories />
        <SearchBarComponent props={{ width: "300px" }} />
        <Button styles={{ root: { minWidth: "120px", fontWeight: "500" } }} component={Link} to="/login" variant="light" color="gray.8">{texts.logIn}</Button>
        <Button styles={{ root: { color: "#000", minWidth: "120px", fontWeight: "500" } }} component={Link} to="/register" variant="filled" color="ts">{texts.register}</Button>
      </Group>
    )
  }

  return (
    <Group style={{ gap: "0.25rem" }}>
      <SearchBarComponent props={{ width: "300px" }} />
      <Categories />
      <Button px={12} variant="subtle" color={"gray.8"} component={Link} to={"/profile"}>
        <User />
      </Button>
      <NotificationIndicator />
      <DesktopMenu props={{ logout, user }} />
    </Group>
  )
}

export const Navbar = ({ isTaskool, register, width }) => {

  const { user, setUser } = useContext(AppContext)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  let refreshToken = getCookie("refresh");
  const cookie = new Cookies()



  // Logs the current user out
  const {mutate : logOut} = useMutation(
    ["logout"],
    async (e) => {
      return await axios
        .post("/api/v1/logout/",e)
    },
    {
      onSuccess: () => {
        cookie.remove('access', { path: '/',domain : ".taskilled.com"})
        cookie.remove('refresh', { path: '/',domain : ".taskilled.com" })
        setUser(null)
      },
      onError : (err) =>{
        console.log('err',err);
      }
    }
  )

  function logout() {
    localStorage.setItem("taskooluser", null);
    // setUser(null);
    const formData = new FormData();
    formData.append("refresh", refreshToken)
    // // remove cookies
    // document.cookie = "refresh=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // document.cookie = "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    // location.pathname = "/";

    logOut(formData)
  }

  let image = isTaskool ? taskoolShadowLogo : taskilledShadowLogo

  return (
    <NavbarStyle>
      <div className="desktop" style={{ width: width ? width + "%" : "90%" }}>
        <Button p={0} variant="white" component={Link} to="/"><Image width={isTaskool ? 135 : 145} src={image} /></Button>
        <UserControls props={{ user, logout, register, isTaskool }} />
      </div>
      <div className="mobile">
        <Button p={0} variant="white" component={Link} to="/"><Image width={isTaskool ? 135 : 145} src={image} /></Button>
        <Button variant="default" onClick={() => setMobileMenuOpen(!mobileMenuOpen)}><Menu2 /></Button>
        <MobileMenu props={{ isTaskool, register, logout, mobileMenuOpen, setMobileMenuOpen }} />
      </div>
    </NavbarStyle>
  );
};