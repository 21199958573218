import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { Button, FileInput, Image, Modal, Text, TextInput } from "@mantine/core";
import { Plus, Upload } from "tabler-icons-react";
import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";

function CreateTeamModal({ props }){

  const { user } = useContext(AppContext)

  const { createTeamModalOpen, setCreateTeamModalOpen } = props

  const queryClient = useQueryClient()

  const { id:hackathonId } = useParams()

  const { mutate:createOrder, isLoading:orderLoading } = useMutation([`create order`],
    async () => {
      return await axios.post('/hackathon/create-order/',
        {
          user:user.id,
          hackathon:JSON.parse(hackathonId),
          promocode:"",
          is_free: true,
        }
      )
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries([`internship-info`, hackathonId])
        setCreateTeamModalOpen(false)
      }
    }
  )

  function callOrder(){
    createOrder()
  }

  const { mutate, isLoading } = useMutation([`create team`],
    async (e) => {
      e.preventDefault()
      return await axios.post('/hackathon/team_create/',
        {
          name:e.target.name.value,
          user:user.id,
          hackathon:3
        }
      )
    },
    {
      onSuccess: () => callOrder()
    }
  )

  return(
    <Modal overlayBlur={5} radius={"md"} title={"Create a team"} opened={createTeamModalOpen} onClose={() => setCreateTeamModalOpen(false)}>
      <form onSubmit={(e) => mutate(e)}>
        <Image radius={"md"} height={220} src="" withPlaceholder/>
        <FileInput name="image" radius={"md"} mt={15} placeholder="Image" label="Upload an image" rightSection={<Upload size={20}/>} rightSectionWidth={45} />
        <TextInput name="name" radius={"md"} mt={15} placeholder="Team name" label="Team name" required/>
        <Button loading={isLoading} type="submit" radius={"md"} mt={15} fullWidth color="gray.8" leftIcon={<Plus/>}>Create team</Button>
      </form>
    </Modal>
  )
}

const EntranceForHackathon = () => {

  const [ createTeamModalOpen, setCreateTeamModalOpen ] = useState(false)

  return (
    <div>
      <CreateTeamModal props={{ createTeamModalOpen, setCreateTeamModalOpen }}/>
      <Button fullWidth size="md" radius={"md"} onClick={() => setCreateTeamModalOpen(true)} color="gray.8" leftIcon={<Plus/>}>Create team and join</Button>
    </div>
  );
};

export default EntranceForHackathon