import React from "react";
import styled from "styled-components";
import NewNavbar from "../../Components/NewNavbar/NewNavbar";
import { Footer } from "../../Components/Layout/Footer";
import backgrounPattern from "../../Assets/Images/payment/pattern.png";
import powerBi from "../../Assets/Images/payment/power-bi.png";
import check from "../../Assets/SVGs/payment/check.svg";
import whiteCheck from "../../Assets/SVGs/payment/check-white.svg";
import greenCheck from "../../Assets/SVGs/payment/check-green.svg";
import arrowBack from "../../Assets/SVGs/payment/arrow-left.svg";
import subscriptionIcon from "../../Assets/SVGs/payment/subscription.svg";
import tagIcon from "../../Assets/SVGs/payment/tag.svg";
import birbank from "../../Assets/SVGs/payment/birbank.svg";
import ulduzum from "../../Assets/SVGs/payment/ulduzum.svg";

function NewPayment() {
  return (
    <div>
      <NewNavbar />
      <NewPaymentStyle>
        <div className="container">
          <div className="payment">
            <div className="payment-header">
              <div className="back">
                <img src={arrowBack} alt="" />

                <h4>Checklist</h4>
              </div>
            </div>
            <div className="payment-box">
              <div className="order-summary payment-item">
                <h3 className="payment-item-title">Order Summary</h3>
                <div className="order-course">
                  <h5>Order course</h5>
                  <div className="order-course-item">
                    <div className="order-course-img">
                      <img src={powerBi} alt="" />
                    </div>

                    <div className="order-course-body">
                      <h6>Microsoft Power BI: Data Analytics</h6>
                      <div className="order-course-detail">
                        <p>
                          Price:
                          <span className="order-course-price">
                            50 AZN
                          </span>{" "}
                          <span className="order-course-price-label">
                            Per one module
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="select-modules">
                  <h5>Select modules</h5>
                  <div className="select-module-box">
                    <div className="select-module-item">
                      <div className="select-module-check">
                        <input type="checkbox" /> Modul 1
                      </div>
                      <div className="select-module-item-price">50 AZN</div>
                    </div>

                    <div className="select-module-item">
                      <div className="select-module-check">
                        <input type="checkbox" /> Modul 1
                      </div>
                      <div className="select-module-item-price">50 AZN</div>
                    </div>

                    <div className="select-module-item">
                      <div className="select-module-check">
                        <input type="checkbox" /> Modul 1
                      </div>
                      <div className="select-module-item-price">50 AZN</div>
                    </div>
                  </div>
                </div>

                <div className="payment-item-footer">
                  <div className="payment-item-total">Subtotal</div>

                  <div className="payment-item-price">50 AZN</div>
                </div>
              </div>

              <div className="payment-method payment-item">
                <h3 className="payment-item-title">Order Summary</h3>
                <div className="choose-payment-row">
                  <h5>Choose payment method</h5>
                  <div className="subscription-btn">
                    <img src={subscriptionIcon} alt="" />
                    Subscription
                  </div>
                </div>

                <div className="choose-payment-box">
                  <div className="choose-payment-item active">
                    <input type="radio" />
                    <div className="choose-payment-name">
                      <img src={tagIcon} alt="" />
                      Promo code
                    </div>
                  </div>

                  <div className="choose-payment-item">
                    <input type="radio" />
                    <div className="choose-payment-name">
                      <img src={birbank} alt="" />
                      Birbank
                    </div>
                  </div>

                  <div className="choose-payment-item">
                    <input type="radio" />
                    <div className="choose-payment-name">
                      <img src={ulduzum} alt="" />
                      Ulduzum
                    </div>
                  </div>
                </div>

                <div className="promo-code-form">
                  <div className="promo-code-input-box">
                    <label htmlFor="">Enter promo code</label>
                    <input type="text" value="taskilled" />
                  </div>

                  <div className="apply-btn">
                    Aplly <img src={whiteCheck} alt="" />
                  </div>
                </div>

                <p className="promo-code-note">
                  <span className="promocode-company">Taskilled</span>
                  <span className="promocode-message">Promo code applied</span>
                  <span className="promocode-discount">
                    <img src={greenCheck} alt="" /> -10% discount
                  </span>
                </p>

                <div className="receipt-box">
                  <div className="receipt-item">
                    <div className="receipt-item-name">Subtotal</div>

                    <div className="receipt-item-price">50 AZN</div>
                  </div>

                  <div className="receipt-item">
                    <div className="receipt-item-name">Tax</div>

                    <div className="receipt-item-price">2 AZN</div>
                  </div>

                  <div className="receipt-item">
                    <div className="receipt-item-name">Discount</div>

                    <div className="receipt-item-price">- 5 AZN</div>
                  </div>
                </div>

                <div className="payment-item-footer">
                  <div className="payment-item-total">Total</div>

                  <div className="payment-item-price">47 AZN</div>
                </div>

                <div className="payment-btn">PAY - 47 AZN</div>
              </div>
            </div>
          </div>

          <div className="subscription">
            <div className="subscription-header">
              <h2>Subscription</h2>
            </div>

            <div className="subscription-cards">
              <div className="subscription-item">
                <div className="subscription-item-title">
                  <h3>
                    Starter Pack <span className="">4 Month</span>
                  </h3>
                  <div className="price">30₼</div>
                </div>

                <div className="subscription-body">
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less
                  </p>

                  <ul className="subscription-benefits">
                    <li>
                      <span>
                        <img src={check} alt="" />
                      </span>{" "}
                      There are many variations
                    </li>
                    <li>
                      <span>
                        <img src={check} alt="" />
                      </span>
                      If you are going to use
                    </li>
                    <li>
                      <span></span>Latin words, combined
                    </li>
                    <li>
                      <span></span>The first line of Lorem Ipsum
                    </li>
                    <li>
                      <span></span>Hampden-Sydney College
                    </li>
                    <li>
                      <span></span>All the Lorem Ipsum generators
                    </li>
                  </ul>

                  <div className="btn-choose">Choose plan</div>
                </div>
              </div>

              <div className="subscription-item popular">
                <div className="popular">
                  <h3>MOST POPULAR</h3>
                </div>
                <div className="subscription-item-title">
                  <h3>
                    Pro Pack <span className="">12 Month</span>
                  </h3>
                  <div className="price">50₼</div>
                </div>

                <div className="subscription-body">
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less
                  </p>

                  <ul className="subscription-benefits">
                    <li>
                      <span>
                        <img src={check} alt="" />
                      </span>{" "}
                      There are many variations
                    </li>
                    <li>
                      <span>
                        <img src={check} alt="" />
                      </span>
                      If you are going to use
                    </li>
                    <li>
                      <span>
                        {" "}
                        <img src={check} alt="" />
                      </span>
                      Latin words, combined
                    </li>
                    <li>
                      <span>
                        {" "}
                        <img src={check} alt="" />
                      </span>
                      The first line of Lorem Ipsum
                    </li>
                    <li>
                      <span>
                        {" "}
                        <img src={check} alt="" />
                      </span>
                      Hampden-Sydney College
                    </li>
                    <li>
                      <span>
                        {" "}
                        <img src={check} alt="" />
                      </span>
                      All the Lorem Ipsum generators
                    </li>
                  </ul>

                  <div className="btn-choose">Choose plan</div>
                </div>
              </div>

              <div className="subscription-item">
                <div className="subscription-item-title">
                  <h3>
                    Lite Pack <span className="">8 Month</span>
                  </h3>
                  <div className="price">40₼</div>
                </div>

                <div className="subscription-body">
                  <p>
                    It is a long established fact that a reader will be
                    distracted by the readable content of a page when looking at
                    its layout. The point of using Lorem Ipsum is that it has a
                    more-or-less
                  </p>

                  <ul className="subscription-benefits">
                    <li>
                      <span>
                        <img src={check} alt="" />
                      </span>{" "}
                      There are many variations
                    </li>
                    <li>
                      <span>
                        <img src={check} alt="" />
                      </span>
                      If you are going to use
                    </li>
                    <li>
                      <span>
                        {" "}
                        <img src={check} alt="" />
                      </span>
                      Latin words, combined
                    </li>
                    <li>
                      <span>
                        {" "}
                        <img src={check} alt="" />
                      </span>
                      The first line of Lorem Ipsum
                    </li>
                    <li>
                      <span></span>Hampden-Sydney College
                    </li>
                    <li>
                      <span></span>All the Lorem Ipsum generators
                    </li>
                  </ul>

                  <div className="btn-choose">Choose plan</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </NewPaymentStyle>
      <Footer />
    </div>
  );
}

export default NewPayment;

const NewPaymentStyle = styled.div`
  * {
    box-sizing: border-box;
    margin: 0px;
    padding: 0px;
  }
  background-color: #f9fafb;
  padding-top: 32px;
  padding-bottom: 44px;
  .container {
    width: 1300px;
    margin: 0 auto;

    .payment {
      margin-bottom: 32px;
      .payment-header {
        margin-bottom: 32px;
        .back {
          display: flex;
          align-items: center;
          gap: 16px;
          color: #0a0d13;
          font-family: "Inter";
          font-size: 30px;
          font-style: normal;
          font-weight: 600;
          line-height: 38px; /* 126.667% */
        }
      }

      .payment-box {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .payment-item {
          background: #fff;
          border-radius: 24px;
          padding: 24px;
          width: 100%;
          h3 {
            color: #0a0d13;
            font-family: "Inter";
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 32px; /* 133.333% */
            padding-bottom: 24px;
            border-bottom: 1px solid #cdd5df;
          }

          h5 {
            color: #697586;
            font-family: "Inter";
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px; /* 155.556% */
            margin: 24px 0px;
          }

          .select-modules {
            .select-module-box {
              padding-bottom: 24px;
              margin-bottom: 24px;
              border-bottom: 1px solid #cdd5df;
              .select-module-item {
                padding: 12px 0px 12px 4px;
                display: flex;
                justify-content: space-between;
                .select-module-check {
                  color: #364152;

                  font-family: "Inter";
                  font-size: 14px;
                  font-weight: 500;
                  line-height: 20px; /* 142.857% */
                  display: flex;
                  align-items: center;
                  input {
                    width: 20px;
                    height: 20px;
                    margin-right: 12px;
                  }
                }

                .select-module-item-price {
                  color: #4b5565;
                  font-family: "Inter";
                  font-size: 16px;
                  font-weight: 600;
                  line-height: 24px; /* 150% */
                }
              }
            }
          }

          .payment-item-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .payment-item-total {
              color: #0a0d13;
              font-family: "Inter";
              font-size: 18px;
              font-style: normal;
              font-weight: 500;
              line-height: 28px; /* 155.556% */
            }
            .payment-item-price {
              color: #0a0d13;
              font-family: "Inter";
              font-size: 36px;
              font-style: normal;
              font-weight: 600;
              line-height: 44px; /* 122.222% */
              letter-spacing: -0.72px;
            }
          }
        }

        .order-course {
          .order-course-item {
            display: flex;
            align-items: center;
            gap: 14px;
            border-bottom: 1px solid #cdd5df;
            padding-bottom: 24px;
            h6 {
              color: #121926;
              font-family: "Inter";
              font-size: 18px;
              font-style: normal;
              font-weight: 600;
              line-height: 28px; /* 155.556% */
              margin-bottom: 11px;
            }
            p {
              overflow: hidden;
              color: #9aa4b2;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */

              .order-course-price {
                color: #4b5565;
                display: inline-block;
                margin-left: 4px;
                margin-right: 12px;
                font-family: "Inter";
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: 24px;
              }

              .order-course-price-label {
                display: inline-block;
                text-align: center;
                line-height: 22px;
                background: #eff8ff;
                width: 106px;
                height: 22px;
                padding: 2px, 8px;
                border-radius: 16px;
                border: 1px solid #b2ddff;
                color: #1570ef;
                font-family: "Inter";
                font-size: 12px;
                font-weight: 500;
              }
            }
          }
        }

        .payment-method {
          .choose-payment-row {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .subscription-btn {
              display: flex;
              align-items: center;
              gap: 8px;
              font-family: "Euclid";
              font-size: 16px;
              font-weight: 500;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: left;
            }
          }

          .choose-payment-box {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            margin-bottom: 24px;
            .choose-payment-item {
              display: flex;
              align-items: center;
              gap: 16px;
              width: 100%;
              height: 48px;
              padding: 12px 10px 12px 8px;
              border-radius: 12px;
              border: 1px solid #cdd5df;

              input {
                width: 20px;
                height: 20px;
              }

              .choose-payment-name {
                display: flex;
                align-items: center;
                gap: 12px;
              }
              &.active {
                background-color: #f8fafc;
              }
            }
          }

          .promo-code-form {
            display: flex;
            gap: 20px;
            margin-bottom: 12px;
            .promo-code-input-box {
              position: relative;
              label {
                position: absolute;
                top: 8px;
                left: 16px;
                color: #00000099;
                font-family: "Inter";
                font-size: 11px;
                font-weight: 400;
                line-height: 16px;
                letter-spacing: 0em;
                text-align: left;
              }
              input {
                width: 388px;
                height: 56px;
                padding: 9px 16px;
                padding-top: 30px;
                border-radius: 10px;
                border: 1px solid #cdd5df;
                background: #fff;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
                line-height: 24px;
                letter-spacing: 0em;
                text-align: left;
              }
            }

            .apply-btn {
              display: flex;
              align-items: center;
              justify-content: center;
              gap: 12px;
              margin-bottom: 12px;
              width: 184px;
              padding: 16px 24px 16px 32px;
              border-radius: 10px;
              border: 1px solid #f79009;
              background: #f79009;
              box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
              color: #fff;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 600;
              line-height: 24px; /* 150% */
            }
          }

          .promo-code-note {
            .promocode-company {
              color: #1570ef;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              display: inline-block;
              margin-right: 8px;
            }

            .promocode-message {
              color: #17b26a;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              display: inline-block;
              margin-right: 8px;
            }

            .promocode-discount {
              color: #6c737f;
              font-family: "Inter";
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              line-height: 24px; /* 150% */
              img{
                margin-right:8px;
              }
            }
          }

          .receipt-box {
            margin: 24px 0px;
            padding-top: 24px;
            padding-bottom: 8px;
            border-top: 1px solid #cdd5df;
            border-bottom: 1px solid #cdd5df;
            .receipt-item {
              display: flex;
              justify-content: space-between;
              margin-bottom: 16px;
              .receipt-item-name {
                font-family: "Inter";
              }

              .receipt-item-price {
                font-family: "Inter";
              }
              font-size: 18px;
              font-weight: 500;
              line-height: 28px;
              letter-spacing: 0em;
              text-align: left;
            }
          }
        }

        .payment-btn {
          background: #1570ef;
          margin-top: 24px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 56px;
          padding: 16px, 28px, 16px, 28px;
          border-radius: 10px;
          border: 1px;
          color: #ffffff;
          font-family: "Inter";
          font-size: 16px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          text-align: left;
        }
      }
    }
  }
  .subscription {
    .subscription-header {
      background-image: url(${backgrounPattern}),
        linear-gradient(90deg, #1849a9 0.13%, #1570ef 100%);
      border-radius: 24px 24px 0px 0px;
      h2 {
        text-align: center;
        padding: 40px;
        color: #fff;
        font-family: "Inter";
        font-size: 36px;
        font-style: normal;
        font-weight: 600;
        line-height: 44px; /* 122.222% */
        letter-spacing: -0.72px;
      }
    }

    .subscription-cards {
      padding: 40px 0px;
      border-radius: 0px 0px 24px 24px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;
      .subscription-item {
        overflow: hidden;
        height: 586px;
        &.popular {
          width: 420px;
          height: 640px;
          background: #102a56;
          .subscription-item-title {
            h3 {
              color: #fff;
              span {
                color: #84caff;
              }
            }
          }
          p {
            color: #cdd5df;
          }

          li {
            color: #fff;
          }
        }
        .popular {
          text-align: center;
          padding: 24px;
          background: #1570ef;
          color: #ffffff;
          font-family: "Inter";
          font-size: 20px;
          font-weight: 600;
          line-height: 30px;
          letter-spacing: 0em;
        }
        width: 376px;
        border-radius: 24px;
        border: 2px solid #84caff;
        background: rgba(239, 248, 255, 0.2);
        backdrop-filter: blur(4px);
        .subscription-item-title {
          border-bottom: 1px solid #84caff;
          padding: 24px;
          h3 {
            color: #202939;
            font-family: "Inter";
            font-size: 20px;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 0em;
            text-align: left;
            span {
              font-size: 20px;
              font-weight: 500;
              color: #1570ef;
              display: inline-block;
              /* margin-left: 16px; */
            }
          }

          .price {
            color: #1570ef;
            font-family: "Inter";
            font-size: 48px;
            font-weight: 600;
            line-height: 60px;
            letter-spacing: -0.02em;
          }
        }

        .subscription-body {
          padding: 24px;
          p {
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0em;
            text-align: left;
            margin-bottom: 24px;
          }

          .subscription-benefits {
            list-style: none;
            li {
              margin-bottom: 8px;
              font-family: "Inter";
              font-size: 16px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0em;
              text-align: left;
              display: flex;
              align-items: center;
              gap: 8px;

              span {
                display: inline-block;
                width: 24px;
                height: 24px;
              }
            }
          }

          .btn-choose {
            background: #1570ef;
            margin-top: 24px;
            width: 100%;
            height: 48px;
            padding: 13px, 20px, 13px, 20px;
            border-radius: 10px;
            border: 1px solid #1570ef;
            box-shadow: 0px 1px 2px 0px #1018280d;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
            font-family: "Inter";
            font-size: 15px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
          }
        }
      }
    }
  }
`;
