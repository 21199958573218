import { Card, Group, Image, Text } from "@mantine/core";
import { Link } from "react-router-dom";

export function ProgramCard({ props }) {
  const { link, image, title } = props;

  return (
    <Card
      component={link?.length > 0 ? Link : ""}
      to={link}
      p="lg"
      radius="lg"
      withBorder
      style={{ width: "320px", border: "1px solid #DEE2E6" }}
    >
      <Card.Section>
        <Image
          src={image?.name ? URL.createObjectURL(image) : image}
          height={180}
          alt="Card"
          withPlaceholder
        />
      </Card.Section>

      <Group position="apart" mt="md" mb="0" style={{ minHeight: "48px" }}>
        <Text size={16} weight={500}>
          {title?.length > 48
            ? title.substring(0, 48) + "..."
            : title || "Type something"}
        </Text>
      </Group>
    </Card>
  );
}
