import styled from "styled-components";
import { Skills } from "./Skills";
import { Education } from "./Education";
import { Experience } from "./Experience";
import { Certifications } from "./Certificates";
import { Tasks } from "./Tasks";
import { ProfileIntro } from "./ProfileIntro";
import { NotificationsProvider } from "@mantine/notifications";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import axios from "axios";
import { SideBarNav } from "../../../Components/SideBarNav";
import { RecommendedPrograms } from "../../../Components/RecommendedPrograms";
import { useEffect, useState } from "react";

const StudentProfile = ({ props }) => {
  //   const extractIDFromURL = (url) => {
  //     const regexPattern = /\d+$/;
  //     const match = url.match(regexPattern);

  //     if (match) {
  //       const idNumber = match[0];
  //       return Number(idNumber);
  //     } else {
  //       return null;
  //     }
  //   };

  const { id: guid } = useParams();

  const { isTaskool } = props;

  const { data } = useQuery(["user", guid], async () => {
    return await (
      await axios.get(`/api/v1/user-data-with-guid/${guid}/`)
    ).data;
  });

  const [certificates, setCertificates] = useState([]);
  const [experiences, setExperiences] = useState([]);
  const [educations, setEducation] = useState([]);
  const [skills, setSkills] = useState([]);

  async function fetchData() {
    try {
      if (data?.id) {
        const fetchedCerts = await (
          await axios.get(`/api/certificatebyuser_id/${data?.id}/`)
        ).data;
        fetchedCerts.sort((a, b) => a.id - b.id);
        setCertificates(fetchedCerts);
      }

      if (data?.id) {
        const fetchedExperiences = await (
          await axios.get(`/api/experiencebyuserid/${data?.id}/`)
        ).data;
        fetchedExperiences.sort((a, b) => a.id - b.id);
        setExperiences(fetchedExperiences);
      }

      if (data?.id) {
        const fetchedEducations = await (
          await axios.get(`/api/educationbyuserid/${data?.id}/`)
        ).data;
        fetchedEducations.sort((a, b) => a.id - b.id);
        setEducation(fetchedEducations);
      }

      if (data?.id) {
        const fetchedSkills = await (
          await axios.get(`/api/userskillbyuserid/${data?.id}/`)
        ).data;
        fetchedSkills.sort((a, b) => a.id - b.id);
        setSkills(fetchedSkills);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    (async () => await fetchData())();
  }, [data]);

  return (
    <NotificationsProvider>
      <ProfileStyle>
        <div className="wrapperAll">
          <div className="profileParts">
            <ProfileIntro props={{ userData: data }} />
            {/* {
              !isTaskool &&
              <Internship props={{ userInternships: data?.data?.UserInternships[0], isTaskool }}/>
            } */}
            <Tasks props={{ isTaskool, user: data || {} }} />
            <Skills props={{ skillsData: skills, isTaskool }} />
            <ExperienceAndEducation
              props={{
                experienceData: experiences,
                educationData: educations,
                isTaskool,
              }}
            />
            <Certifications
              props={{
                certificationData: certificates,
                isTaskool,
              }}
            />
          </div>
          <div className="profileParts">
            <SideBarNav props={{ isTaskool }} />
            <RecommendedPrograms props={{ isTaskool }} />
          </div>
        </div>
      </ProfileStyle>
    </NotificationsProvider>
  );
};

export default StudentProfile;

const ProfileStyle = styled.div`
  display: flex;
  background-color: #f5f5f5;
  .wrapperAll {
    width: 90%;
    margin: 1.5rem auto;
    background-color: #f5f5f5;
    display: grid;
    grid-template-columns: 2.1fr 1fr;
    grid-gap: 1.5rem;
    .profileParts {
      display: flex;
      flex-direction: column;
      grid-gap: 1.5rem;
    }
  }
  @media (max-width: 768px) {
    display: block;
    padding: 0;
    .wrapperAll {
      width: 100%;
      margin: 0 auto;
      display: block;
      background-color: #f2f2f2;
      .profileParts {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
      }
    }
  }
`;

const ExperienceAndEducation = ({ props }) => {
  const { experienceData, educationData, isTaskool } = props;

  return (
    <E2Style>
      <Experience props={{ experienceData, isTaskool }} />
      <Education props={{ educationData, isTaskool }} />
    </E2Style>
  );
};

const E2Style = styled.div`
  background: #fff;
  border-radius: 0.5rem;
  @media (max-width: 768px) {
    border-radius: 0;
  }
`;
