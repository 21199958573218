import { React } from "react";
import styled from "styled-components";
import rectangle from "../../../Assets/SVGs/rectangle.svg";

export const Newsletter = () => {
  return (
    <NewsletterStyle>
      <div className="wrapperNews">
        <div className="newsBg">
          <img src={rectangle} alt="" />
        </div>
        <div className="inside">
          <h2>Sign up to our newsletter</h2>
          <h3>Recieve latest news, updates and other news</h3>
          <div className="inputs">
            <input type="text" placeholder="Enter your email address" />
            <button>Subscribe</button>
          </div>
        </div>
      </div>
    </NewsletterStyle>
  );
};

const NewsletterStyle = styled.div`
  padding: 0.5rem 0 2rem 0;
  margin-bottom: 10rem;
  position: relative;
  .wrapperNews{
    text-align: center;
    .inside{
      width: 90%;
      margin-inline: auto;
      margin-top: 5.5%;
      h2{
        font-weight: 600;
        color: #4f4f4f;
        font-size: clamp(1.6rem, 2.4vw, 1.6rem);
      }
      h3{
        color: #828282;
        font-weight: 500;
        font-size: clamp(1.2rem, 2.4vw, 1.4rem);
      }
      .inputs{
        display: flex;
        justify-content: center;
        grid-gap: .5rem;
        input {
          font-weight: 400;
          background: #fff;
          border: none;
          border-radius: 0.4rem;
          margin: 0.25rem 0;
          padding: 1rem 0;
          padding-left: 1rem;
          width: min(20rem, 100%);
          font-size: clamp(14px, 1vw, 14px);
        }
        button {
          font-size: clamp(14px, 1vw, 14px);
          font-weight: 400;
          padding: 1rem;
          border: none;
          background: var(--yellow);
          border-radius: 0.4rem;
          margin: 0.25rem;
          cursor: pointer;
          min-width: max-content;
          :hover {
            background: var(--yellow-shadow);
          }
        }
      }
    }
    .newsBg{
      width: min(1200px, 90%);
      margin-inline: auto;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      img{
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    .wrapperNews{
      .inside{
        margin-top: 5%;
      }
      .newsBg{
        width: min(1200px, 100%);
      }
    }
  }
  @media screen and (max-width: 1025px) {
    background: #fbf5f1;
    margin-bottom: 0;
    padding-bottom: 2.5rem;
    .newsBg{
      img{
        display: none;
      }
    }
  }
`;