import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Center, Container, Text, ThemeIcon } from '@mantine/core'
import { Error404 } from "tabler-icons-react";

const ErrorPage = () => {

  window.scrollTo(0, 0, 'smooth');
  let location = useLocation();
  const [ pageNotFound, setPageNotFound ] = useState("");

  useEffect(() => {
    setPageNotFound(location.pathname);
    return () => {
      setPageNotFound("");
    }
  }, [location.pathname]);

  return (
    <Container my={150}>
      <Center>
        <Error404 size={150}/>
      </Center>
      <Text color={""} mt={20} align="center" size={25} weight={600}>{pageNotFound} - Axtardığınız səhifə mövcud deyil</Text>
    </Container>
  )
}

export default ErrorPage;