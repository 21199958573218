import React from "react";
import { Text, TextInput, Button } from "@mantine/core";
import { AdjustmentsHorizontal, Search } from "tabler-icons-react";

export const FilterProjects = () => {
  return (
    <div
      style={{
        backgroundColor: "#fff",
        padding: "1rem",
        borderRadius: ".75rem",
        border: "1px solid #ddd",
        marginBottom: ".5rem",
      }}
    >
      <Text weight={500} mb={10}>
        Find project
      </Text>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          width: "100%",
        }}
        className="search-other-projects"
      >
        <TextInput
          style={{ width: "100%" }}
          radius={"md"}
          placeholder="Search for projects"
          icon={<Search />}
          iconWidth={45}
          rightSectionWidth={45}
        />
        <Button size="sm" radius={"md"} color="gray.8">
          Search
        </Button>
      </div>
    </div>
  );
};
