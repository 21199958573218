import { Loader } from '@mantine/core'
import { Suspense } from 'react'

export const SuspenseSpinner = (props) => {
  return (
    <Suspense
      fallback={
        <Loader alignmentBaseline='central' color={"yellow.4"} variant='bars' size={50}
          style={{ display:"flex", justifyContent:"center", margin:"20rem auto" }}
        />
    }>
      {props.children}
    </Suspense>
  );
};