import { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { Button, Center, Divider, Group, Modal, Radio } from "@mantine/core";
import { useMutation, useQuery } from "@tanstack/react-query";
let data={
  values:{
    question_list_1:{},
    question_list_2:{},
    question_list_3:{},
    question_list_4:{},
    question_list_5:{},
    question_list_6:{},
    question_list_7:{},
    question_list_8:{},
    question_list_9:{},
    question_list_10:{},
  }
}

export const SoftModal = ({ url }) => {
  const [showModal, setShowModal] = useState(false);
  const [numberList, setNumberList] = useState(1);
  const [questionNumber, setQuestionNumber] = useState(0);
  const [answerNumber, setAnswerNumber] = useState(0);
  const [userAnswers, setUserAnswers] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState(1);

  const [answersData, setAnswersData] = useState({});
  const [link, setlink] = useState(`/skill/17`);

  const { isLoading } = useQuery(
    [`forms`],
    async () => await axios.get(link).then((res) => res.data),
    {
      onSuccess: (data) => {
        if (!data) return setAnswersData([]);

        data = data.values;
        let resultFormated = [];
        Object.keys(data).map((key) => {
          let object = {};
          object[key] = [];
          const item = data[key];
          Object.keys(item).map((key2) => {
            object[key].push(item[key2]);
            resultFormated.push(object);
          });
        });
        setAnswersData(resultFormated);
      },
    }
  );

  const { mutate: fetchPage, isLoading: pageFetchLoading } = useMutation(
    [`answers page`],
    async (link) => {
      let linkWithS = link.replace("http", "https");
      return await axios.get(linkWithS).then((res) => res.data);
    },
    {
      onSuccess: (data) => {
        if (!data) return setAnswersData([]);

        data = data.values;
        let resultFormated = [];
        Object.keys(data).map((key) => {
          let object = {};
          object[key] = [];
          const item = data[key];
          Object.keys(item).map((key2) => {
            object[key].push(item[key2]);
            resultFormated.push(object);
          });
        });
        setAnswersData(resultFormated);
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    fetchPage(link);
  }, [link]);

  const handleSubmit = (event) => {
    event.preventDefault();

    const selectedAnswer = document.querySelector(
      `input[name=question_list_${numberList}_${questionNumber}]:checked`
    );

    if (selectedAnswer) {
      // Add the selected answer to the userAnswers array
      setUserAnswers((prevUserAnswers) => [
        ...prevUserAnswers,
        {
          question:answersData[answerNumber][`question_list_${numberList}`][
            questionNumber
          ]?.question,
          answer_1:selectedAnswer.value,
        }
      ]);
      data["values"][`question_list_${numberList}`][`question_${questionNumber+1}`]=
        {
          question:answersData[answerNumber][`question_list_${numberList}`][
            questionNumber
          ]?.question,
          answer_1:selectedAnswer.value,
        }
      console.log(data)

      // Move to the next question or finish the test
      if (questionNumber === 4 && numberList === 10) {
        setShowModal(false);
        axios.post("/skill/17",data).then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
      } else {
        if (questionNumber >= 4) {
          setNumberList(1 + numberList);
          setAnswerNumber(5 + answerNumber);
          setQuestionNumber(0);
        } else {
          setQuestionNumber(1 + questionNumber);
        }
        setCurrentQuestion((prevCurrentQuestion) => prevCurrentQuestion + 1);
      }

      // Clear the selected answer
      selectedAnswer.checked = false;
    }
  };

  return (
    <>
      <Button variant="default" onClick={() => setShowModal(true)}>
        Begin test
      </Button>

      <Modal
        size={"xl"}
        radius={"lg"}
        title={"Soft skills test"}
        opened={showModal}
        onClose={() => setShowModal(false)}
      >
        <Center
          mt={"lg"}
          style={{ display: "flex", flexDirection: "column", gap: ".5rem" }}
        >
          {answersData.length && (
            <form onSubmit={handleSubmit}>
              <p>
                {
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.question
                }
              </p>
              <input
                required
                type="radio"
                name={`question_list_${numberList}_${questionNumber}`}
                id={`question_list_${numberList}_${questionNumber}1`}
                value={
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_1
                }
              />{" "}
              <label htmlFor={`question_list_${numberList}_${questionNumber}1`}>
                {
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_1
                }
              </label>{" "}
              <br />
              <input
                value={
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_2
                }
                required
                type="radio"
                name={`question_list_${numberList}_${questionNumber}`}
                id={`question_list_${numberList}_${questionNumber}2`}
              />{" "}
              <label htmlFor={`question_list_${numberList}_${questionNumber}2`}>
                {
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_2
                }
              </label>{" "}
              <br />
              <input
                value={
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_3
                }
                required
                type="radio"
                name={`question_list_${numberList}_${questionNumber}`}
                id={`question_list_${numberList}_${questionNumber}3`}
              />{" "}
              <label htmlFor={`question_list_${numberList}_${questionNumber}3`}>
                {
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_3
                }
              </label>{" "}
              <br />
              <input
                value={
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_4
                }
                required
                type="radio"
                name={`question_list_${numberList}_${questionNumber}`}
                id={`question_list_${numberList}_${questionNumber}4`}
              />{" "}
              <label htmlFor={`question_list_${numberList}_${questionNumber}4`}>
                {
                  answersData[answerNumber][`question_list_${numberList}`][
                    questionNumber
                  ]?.answer_4
                }
              </label>
              <Divider color="gray.1" mt={"xl"} mb={"xl"} />
              <Group
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <p>{currentQuestion}/50</p>
                <input
                  style={{
                    padding: "10px 50px",
                    backgroundColor: "white",
                    fontSize: "16px",
                    borderRadius: "10px",
                  }}
                  type="submit"
                  value={currentQuestion < 50 ? "Next" : "Submit"}
                />
              </Group>
            </form>
          )}
        </Center>
      </Modal>
    </>
  );
};
