import { React } from "react";
import styled from "styled-components";
import NotificationBig from "../../Assets/SVGs/notificationBig.png";
import { Link } from "react-router-dom";
import { ArrowLeft } from "tabler-icons-react";

const Notifications = () => {
  return (
    <NotiStyle>
      <div className="goBack">
        <Link to="/">
          {" "}
          <ArrowLeft /> Dashboard{" "}
        </Link>
      </div>
      <div className="noNotification">
        <img src={NotificationBig} alt="" />
        <h2>Hələ heç bir ismarıcınız yoxdur</h2>
      </div>
    </NotiStyle>
  );
};

export default Notifications;

const NotiStyle = styled.div`
  background-color: #fff;
  border-top: 2px solid #eee;
  padding-bottom: 10rem;
  .goBack {
    width: 90%;
    margin: 1rem auto;
    a {
      display: flex;
      padding: 0.5rem;
      grid-gap: 0.5rem;
      align-items: center;
      text-decoration: none;
      color: #000;
      width: max-content;
    }
  }
  .noNotification {
    padding-top: 5rem;
    text-align: center;
    width: 100%;
    h2 {
      margin: 1rem;
    }
    img {
      width: 20%;
    }
  }
  h2 {
    font-weight: 500;
    color: #aaa;
  }
  @media screen and (max-width: 800px) {
    a {
      display: flex;
      margin: 1rem 1.5rem;
      align-items: center;
      text-decoration: none;
      color: #000;
      p {
        margin: 0 1rem;
        text-decoration: none;
        color: #000;
        font-weight: 500;
      }
    }
    div {
      img {
        width: 50%;
      }
    }
  }
`;
