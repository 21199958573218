import React from 'react'
import styled from 'styled-components'

function NewFooter() {
  return (
    <NewFooterStyle>
      <div>

      </div>
    </NewFooterStyle>
  )
}

export default NewFooter

const NewFooterStyle=styled.div`
  
`