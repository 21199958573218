import React from 'react'
import styled from "styled-components";
import addIcon from "../../../Assets/SVGs/profile/plus.svg";
import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import educationImg1 from "../../../Assets/Images/profile/education-1.png";

function Education() {
  return (
    <EducationStyle>
    <div className="education-header">
      <h2>Education</h2>

      <div className="header-icons">
        <div className="header-icon-item">
          <img src={addIcon} alt="" />
        </div>

        <div className="header-icon-item">
          <img src={editIcon} alt="" />
        </div>
      </div>
    </div>

    <div className="education-box">
      <div className="education-item">
        <div className="education-img">
          <img src={educationImg1} alt="" />
        </div>
        <div className="education-detail">
          <div className="education-date">January 2024 - March 2024</div>
          <h5>
            UX/UI Design <span className="position">Bachelor’s degree</span>
          </h5>
          <div className="education-company">Baku Engineering University</div>
        </div>
      </div>

      <div className="education-item">
        <div className="education-img">
          <img src={educationImg1} alt="" />
        </div>
        <div className="education-detail">
          <div className="education-date">January 2024 - March 2024</div>
          <h5>
            UX/UI Design <span className="position">Master degree</span>
          </h5>
          <div className="education-company">Baku Engineering University</div>
        </div>
      </div>
    </div>
  </EducationStyle>
  )
}

export default Education


const EducationStyle = styled.div`
  background: #fff;
  padding: 40px 32px;
  border-radius: 12px;
  margin-bottom: 45px;
  .education-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    h2 {
      color: #202939;
      font-family: "Euclid";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 140% */
    }

    .header-icons {
      display: flex;
      gap: 12px;
      .header-icon-item {
        padding: 12px;
      }
    }
  }

  .education-box {
    .education-item {
      display: flex;
      align-items: center;
      gap: 14px;
      padding:12px 0px;
      border-bottom: 1px solid #CDD5DF;

      .education-date {
        color: #9aa4b2;
        font-family: "Euclid";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 4px;
      }

      h5 {
        margin: 0px;
        color: #121926;
        font-family: "Euclid";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
        /* display: flex;
        align-items: center; */
        margin-bottom: 4px;
        .position {
          color: #4b5565;
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          margin-left: 12px;
        }
      }

      .education-company {
        color: #4b5565;
        font-family: "Euclid";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }
`;