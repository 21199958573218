import { AppContextProvider } from "./Helpers/Context";
import { RouteLogic } from "./Helpers/RouterLogic";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SuspenseSpinner } from "./Components/Loaders/SuspenseSpinner";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { MantineProvider } from "@mantine/core";

function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: 1,
      },
    },
  });

  return (
    <AppContextProvider>
      <MantineProvider
        theme={{
          colorScheme: "light",
          fontFamily: "Euclid, sans-serif",
          colors: {
            // override dark colors to change them for all components
            ts: [
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffe01b",
              "#ecd018",
              "#ffffff",
            ],
            white: [
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
              "#ffffff",
            ],
          },
        }}
      >
        <QueryClientProvider client={queryClient}>
          <SuspenseSpinner>
            {/* <div
              style={{ width:"90%", margin:"5rem auto 0 auto", display:"flex", flexDirection:"column", alignItems:"center", textAlign:"center", justifyContent:"center" }}
            >
              <Backhoe size={200}/>
              <Text size={40} weight={600}>Texniki işlərlə əlaqəli portalda dayanmalar müşayiət olunur. Qısa zamanda texniki işlərin yekunlaşması gözlənilir.</Text>
            </div> */}
            <RouteLogic />
          </SuspenseSpinner>
          <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
        </QueryClientProvider>
      </MantineProvider>
    </AppContextProvider>
  );
}

export default App;
