import { useEffect, useState, useContext } from "react";
import axios from "axios";
import styled from "styled-components";
import { AppContext } from "../../../../Helpers/Context";
import {
  Book,
  ChevronRight,
  Lock,
  LockOpen,
  Messages,
  Notes,
  Photo,
  PlayerPlay,
} from "tabler-icons-react";
import { useParams } from "react-router-dom";
import { Button, Modal } from "@mantine/core";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const SectionContent = ({ props }) => {
  const {
    mentorId,
    tasksVisibility,
    visibilityModalOpen,
    setVisibilityModalOpen,
    currentSection,
    setCurrentSection,
    setMaterialLoading,
    setOpened,
    showPart,
    setShowPart,
    singleSectionContentFiltered,
    setSingleSectionContentFiltered,
  } = props;

  const { internshipId } = useParams();
  const { user } = useContext(AppContext);

  const [allContent, setAllContent] = useState([]);
  const [sections, setSections] = useState([]);
  const [singleSectionContent, setSingleSectionContent] = useState([]);
  const [courseData, setCourseData] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(`internship/${internshipId}`);
        setCourseData(response.data);
      } catch (error) {}
    })();
    return () => {
      setCourseData([]);
    };
  }, []);

  // fetch section function
  async function fetchSection(id) {
    setSingleSectionContentFiltered([]);

    try {
      const response = await axios.get(
        `internshipsection/section-detail/${id}/`
      );
      // check if section is empty
      if (response.data.length > 0) {
        setSingleSectionContent(response.data);
      } else {
        sections.map((section) => {
          if (section.id === id) {
            setSingleSectionContent(section);
          }
        });
      }

      return response;
    } catch (error) {}
  }

  // fetch course content
  async function fetchCourseContent(id) {
    try {
      const response = await axios.get(`internshipsection/section-list/${id}/`);
      setAllContent(response?.data);
      return response;
    } catch (error) {}
  }

  // filter content based on their type
  async function filterContent() {
    setSingleSectionContentFiltered([]);

    function filterX(section, type) {
      setSingleSectionContentFiltered((singleSectionContentFiltered) => [
        ...singleSectionContentFiltered,
        {
          ...section[type],
          type: type,
          section: section.section,
        },
      ]);
    }

    // filter based on type
    if (singleSectionContent.length > 0) {
      singleSectionContent.map((section) => {
        section.video
          ? filterX(section, "video")
          : section.chart
          ? filterX(section, "chart")
          : section.text
          ? filterX(section, "text")
          : filterX(section, "quiz");
      });
    }

    setMaterialLoading(false);
  }

  // Get all sections
  useEffect(() => {
    fetchCourseContent(internshipId);
    return () => {
      setAllContent([]);
    };
  }, []);

  // Get all the existing section ids and titles
  useEffect(() => {
    if (courseData?.user?.id) {
      (async () => {
        try {
          const response = await axios.get(
            `internshipsection/section-list/${internshipId}/`
          );
          // sort the sections in ascending order
          const sortedSections = response?.data?.sort((a, b) => a.id - b.id);
          setSections(sortedSections);
        } catch (error) {}
      })();
    }

    return () => {
      setSections([]);
    };
  }, [courseData, allContent]);

  useEffect(() => {
    setMaterialLoading(true);
    // if(user?.id === courseData?.user?.id){
    //   setCurrentSection({
    //     id: sections[0]?.id,
    //     section_active: sections[0]?.section_active,
    //     title: sections[0]?.title
    //   })
    // } else {
    //   if(sections.length > 0){
    //     sections.map(section => {
    //       if(section.section_active){
    //         setCurrentSection({
    //           id: section.id,
    //           section_active: section.section_active,
    //           title: section.title
    //         })
    //       }
    //     })
    //   }
    // }

    if (sections.length > 0) {
      setCurrentSection({
        id: sections[0]?.id,
        section_active: sections[0]?.section_active === true ? true : false,
        title: sections[0]?.title,
      });
    }

    return () => {
      setCurrentSection({ id: null, section_active: false });
    };
  }, [sections]);

  // Get section data
  useEffect(() => {
    (async () => {
      // check if any sections exist
      if (
        sections[0]?.id !== undefined &&
        currentSection?.id !== undefined &&
        currentSection?.id !== null
      ) {
        try {
          const response = await fetchSection(currentSection?.id);
          response.data.length > 0
            ? setSingleSectionContent(response?.data)
            : setSingleSectionContent(sections[sections?.length - 1]);
        } catch (error) {}
      }
    })();

    return () => {
      setSingleSectionContent([]);
    };
  }, [sections, currentSection]);

  // Filtering raw data to remove null entries
  useEffect(() => {
    filterContent();
    return () => {
      setSingleSectionContentFiltered([]);
    };
  }, [singleSectionContent]);

  useEffect(() => {
    if (singleSectionContentFiltered[0]?.id) {
      // singleSectionContentFiltered[0])
      setShowPart({
        id: singleSectionContentFiltered[0]?.id,
        type: singleSectionContentFiltered[0]?.type,
        section: singleSectionContentFiltered[0]?.section,
      });
    }

    return () => {
      setShowPart({});
    };
  }, [currentSection, singleSectionContentFiltered[0]?.id]);

  function handleMaterialChange(section, material, matIndex) {
    setShowPart({ id: material?.id, type: material?.type, section });
  }

  function handleSectionChange(section) {
    setCurrentSection({
      id: section.id,
      section_active: section.section_active,
      title: section.title,
    });
  }

  function SectionTitle({ props }) {
    const { section } = props;

    return (
      <div
        style={{ color: section.section_active ? "initial" : "#888" }}
        className={
          section?.id === currentSection?.id
            ? "rotate sectionHeader"
            : "sectionHeader"
        }
        onClick={() => handleSectionChange(section)}
      >
        <h3 className="title">
          {section.section_active === false && <Lock />}
          {section.title.length > 35
            ? section.title.substring(0, 35).concat("...")
            : section.title.substring(0, 35)}
          {section.is_final_project === true && (
            <ins
              style={{
                textDecoration: "none",
                marginLeft: ".5rem",
                color: "var(--meadow)",
              }}
            >
              (Final Project)
            </ins>
          )}
        </h3>
        {section.section_active === true && (
          <ChevronRight className="arrowChev" />
        )}
      </div>
    );
  }

  function SectionContentList({ props }) {
    const { section } = props;

    return (
      <div className="week">
        {section?.id === currentSection?.id &&
          singleSectionContentFiltered.map((material, matIndex) => {
            return (
              <div
                key={matIndex}
                onClick={() =>
                  handleMaterialChange(section, material, matIndex)
                }
                className={
                  material?.id === showPart?.id &&
                  material.type === showPart.type
                    ? "material current"
                    : "material"
                }
              >
                <span className="icon">
                  {material.type === "video" ? (
                    <PlayerPlay size={15} />
                  ) : material.type === "quiz" ? (
                    <Notes size={15} />
                  ) : material.type === "text" ? (
                    <Book size={15} />
                  ) : (
                    <Photo size={15} />
                  )}
                </span>
                <ins>{material?.title ? material.title : material.name}</ins>
              </div>
            );
          })}
      </div>
    );
  }

  const queryClient = useQueryClient();

  function ModalVisibility() {
    const formData = new FormData();
    // formData.append(""success""success"course", false);
    formData.append("user", user?.id);
    formData.append("internship", internshipId);

    const { mutate: mutateVisibility } = useMutation(
      ["makeVisible"],
      async () => {
        return (
          await axios
            .post(`/user/completed_course_internship/`, formData)
            .then((res) => res.data),
          {
            onSuccess: (e) => {
              setVisibilityModalOpen(false);
              queryClient.invalidateQueries(["tasksVisibility"]);
            },
          }
        );
      }
    );

    return (
      <Modal
        opened={visibilityModalOpen}
        onClose={() => setVisibilityModalOpen(false)}
        width="80%"
        withCloseButton={false}
      >
        <div style={{ textAlign: "center" }}>
          <h3 style={{ fontWeight: "500", marginBottom: "0" }}>
            Are you sure you want to make this internship visible to everyone?
          </h3>
          <p>You won't be able to change this anymore.</p>
        </div>
        <br />
        <div
          style={{ display: "flex", justifyContent: "center", gridGap: "1rem" }}
        >
          <Button
            color={"teal"}
            onClick={() => (mutateVisibility(), setVisibilityModalOpen(false))}
          >
            Make visible
          </Button>
          <Button
            variant="outline"
            color={"red"}
            onClick={() => setVisibilityModalOpen(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal>
    );
  }

  return (
    <SectionMaterialsStyle>
      <ModalVisibility />
      {sections.map((section, index) => {
        return (
          <div key={index}>
            <SectionTitle props={{ section }} />
            <SectionContentList props={{ section }} />
          </div>
        );
      })}
      <hr />
      <Button
        disabled={mentorId?.length < 1}
        className="openChatButton"
        fullWidth
        variant="default"
        onClick={() => setOpened(true)}
        leftIcon={<Messages />}
      >
        Discuss
      </Button>
      <div className="gradeVisibility">
        {tasksVisibility?.internship == internshipId ? (
          <Button
            color={"dark"}
            variant="outline"
            onClick={() => setVisibilityModalOpen(true)}
            leftIcon={<LockOpen />}
          >
            Your tasks are visible to companies
          </Button>
        ) : (
          <Button
            color={"dark"}
            variant="outline"
            onClick={() => setVisibilityModalOpen(true)}
            leftIcon={<Lock />}
          >
            Show internship on profile
          </Button>
        )}
      </div>
    </SectionMaterialsStyle>
  );
};

const SectionMaterialsStyle = styled.div`
  max-height: 600px;
  overflow-y: scroll;
  position: relative;
  cursor: default;
  hr {
    width: 92%;
    border: none;
    border-bottom: 1px solid #aaa;
    margin: 1.5rem auto 2rem auto;
  }
  button {
    width: 92%;
    margin: 0 auto;
    font-size: 16px;
    justify-content: center;
  }
  h3 {
    margin: 1rem;
    margin-bottom: 1.7rem;
    font-size: 17px;
    font-weight: 500;
    padding: 0.2rem 0;
  }
  .gradeVisibility {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    margin-top: 1rem;
    button {
      width: 96%;
      font-weight: 500;
    }
  }
  .sectionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    h3 {
      padding: 0;
      margin: 0.75rem 1rem;
      display: flex;
      align-items: center;
      svg {
        margin-right: 0.75rem;
      }
      ::before {
        content: "";
        background: #ddd;
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 1rem;
        border-radius: 50%;
      }
    }
    .arrowChev {
      transition: 0.2s all;
      margin-right: 1rem;
    }
  }
  .rotate {
    .arrowChev {
      transform: rotate(90deg);
    }
    h3 {
      ::before {
        content: "";
        background: #2a72cc;
        width: 0.5rem;
        height: 0.5rem;
        margin-right: 1rem;
        border-radius: 50%;
      }
    }
  }
  .week {
    margin: 0.5rem 0;
    .material {
      width: 85%;
      display: flex;
      align-items: center;
      border-left: 7px solid transparent;
      padding: 0.75rem 0rem 0.75rem 0rem;
      border-radius: 0.55rem;
      cursor: pointer;
      transition: all 0.1s;
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 1rem;
        min-height: 1rem;
        padding: 0.2rem;
        border-radius: 50%;
        border: 1px solid #555;
        margin-left: 1rem;
      }
      ins {
        text-decoration: none;
        font-weight: 500;
        margin: 0 0.5rem 0 1rem;
        min-width: max-content;
        font-size: 14px;
      }
    }
    .current {
      background-color: #f2f8ff;
      border-left: 7px solid #2a72cc;
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    margin-top: 1rem;
  }

  ::-webkit-scrollbar-track {
    margin-top: 1rem;
    border-radius: 1rem;
    background: rgba(126, 126, 126, 0);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: rgba(126, 126, 126, 0.5);
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgba(126, 126, 126, 1);
  }
  @media screen and (max-width: 1024px) {
  }
`;
