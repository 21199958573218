import { React, useState, useContext, useEffect } from "react";
import styled from "styled-components";
import appStore from "../../Assets/Images/appStore.png";
import playStore from "../../Assets/Images/playStore.png";
import { Link, useParams, useNavigate } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";
import axios from "axios";
import { Input,LoadingOverlay, Modal, Select, Textarea } from "@mantine/core";
import { ChevronRight, Download } from "tabler-icons-react";
;

const AboutGame = () => {

  const { id } = useParams();

  const [ allGames, setAllGames ] = useState([])

  const [ removeGameModalOpen, setRemoveGameModalOpen ] = useState(false)
  const [ deleteGameModalOpen, setDeleteGameModalOpen ] = useState(false)
  const [ editGameModalOpen, setEditGameModalOpen ] = useState(false)
  const [ loaderVisible, setLoaderVisible ] = useState(false)

  const { user, aToken } = useContext(AppContext);



  async function fetchGame(){
    try {
      const response = await axios.get(`api/game/${id}/`)

      setAllGames(response.data)
      if(response.status === 200){
        setLoaderVisible(false);
        setEditGameModalOpen(false);
      }
      return response
    } catch (error) {

    }
  }

  useEffect(() => {
    fetchGame();
    return () => {
      setAllGames([])
    }
  }, []);

  function EditGameModal(){

    const [ gameData, setGameData ] = useState({...allGames})
    const { created_at, description, image, title, id: gameId, exe_file, game_category, google_play_link, ios_link, is_active, pc_link, user: gameUser } = gameData;

    const newForm = {...gameData};
    !image?.name && delete newForm?.image;
    !exe_file?.name && delete newForm?.exe_file;

    async function editGame(){
      setLoaderVisible(true)
      try {
        const response = await axios.patch(`api/game/${id}/`, {
          ...newForm
        })
        if(response.status === 200){
          fetchGame(id);
          setLoaderVisible(false)
        }
      } catch (error) {


        setLoaderVisible(false)
      }
    }

    return(
      <Modal opened={editGameModalOpen} onClose={() => setEditGameModalOpen(false)} title="Edit Game Info">
        <div>
          <form>
            <Input.Wrapper label="Title">
              <Input type="text" label="Title" name="title" value={title} onChange={(e) => setGameData({...gameData, title: e.target.value})} placeholder="Game Title" />
            </Input.Wrapper>
            <br/>
            <Textarea minRows={5} label="Description" name="description" value={description} onChange={(e) => setGameData({...gameData, description: e.target.value})} placeholder="Game Description" />
            <br/>
            {/* <Input type="file" label="" name="image" value={image} onChange={(e) => setGameData({...gameData, image: e.target.files[0]})} placeholder="Game Image" /> */}
            <Input.Wrapper label="Image">
              <br />
              <p style={{fontSize:"13px", color:"#888", marginTop:"0"}}>Current image: {image?.name ? image.name : image}</p>
              <input type="file" accept="image/*" onChange={(e) => setGameData({...gameData, "image": e.target.files[0]})} />
            </Input.Wrapper>
            <br/>
            <Input.Wrapper label="Executable file">
              <br />
              <p style={{fontSize:"13px", color:"#888", marginTop:"0"}}>Current image: {exe_file?.name ? exe_file.name : exe_file}</p>
              <input type="file" accept=".exe" onChange={(e) => setGameData({...gameData, "exe_file": new File([e.target.files[0]], e.target.files[0].name)})} />
            </Input.Wrapper>
            <br/>
            {/* <Input type="file" label="" name="exe_file" value={exe_file} onChange={(e) => setGameData({...gameData, exe_file: e.target.files[0]})} placeholder="Game Executable File" /> */}
            <Select
              label="Category"
              placeholder={game_category}
              // value={{value:1, label: game_category}}
              data={[
                { value: "1", label: "Puzzle" },
                { value: "2", label: "Educational" },
                { value: "3", label: "Adventure" },
                { value: "4", label: "Logic" },
                { value: "5", label: "War" },
              ]}
            />
            <br/>
            <Input.Wrapper label="Google Play Link">
              <Input type="text" label="Google Play Link" name="google_play_link" value={google_play_link ? google_play_link : ""} onChange={(e) => setGameData({...gameData, google_play_link: e.target.value})} placeholder="Google Play Link" />
            </Input.Wrapper>
            <br/>
            <Input.Wrapper label="iOS Link">
              <Input type="text" label="iOS Link" name="ios_link" value={ios_link ? ios_link : ""} onChange={(e) => setGameData({...gameData, ios_link: e.target.value})} placeholder="iOS Link" />
            </Input.Wrapper>
            <br/>
          </form>
          <div className="buttons" style={{display:"flex", justifyContent:"center"}}>
            <button style={{backgroundColor:"#eee", border:"none", cursor:"pointer", padding:"0.3rem 1rem", margin:"0 .5rem", borderRadius:"0.2rem"}} className="cancel" onClick={() => setEditGameModalOpen(false)}>
              <span>Cancel</span>
            </button>
            <button style={{backgroundColor:"var(--yellow)", border:"none", cursor:"pointer", padding:"0.3rem 1rem", margin:"0 .5rem", borderRadius:"0.2rem"}} className="submit" onClick={() => editGame()}>
              <span>Submit</span>
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  function RemoveGameModal(){
    const navigate = useNavigate()

    async function removeSection(){
      setLoaderVisible(true)
      try {
        const response = await axios.delete(`api/game/${id}/`)
        if(response.status === 204){
          const gameInfo = fetchGame(id);
          setLoaderVisible(false);
          navigate("/my-games");
        }
      } catch (error) {

        setLoaderVisible(false)
      }
    }

    return(
      <Modal opened={deleteGameModalOpen} onClose={() => setDeleteGameModalOpen(false)} title="Delete game">

        <div>
          <p style={{color:"red"}}>Are you sure you want to delete this game? If you delete this game, all it's content will be removed.</p>
          <div className="buttons" style={{display:"flex", justifyContent:"center"}}>
            <button style={{backgroundColor:"#eee", border:"none", cursor:"pointer", padding:"0.3rem 1rem", margin:"0 .5rem", borderRadius:"0.2rem"}} className="cancel" onClick={() => setDeleteGameModalOpen(false)}>
              <span>Cancel</span>
            </button>
            <button style={{backgroundColor:"#dd4a4a", color:"white", border:"none", cursor:"pointer", padding:"0.3rem 1rem", margin:"0 .5rem", borderRadius:"0.2rem"}} className="submit" onClick={() => removeSection()}>
              <span>Delete</span>
            </button>
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <>

      <GameStyle>
        <EditGameModal/>
        <RemoveGameModal/>
        <LoadingOverlay visible={loaderVisible} loaderProps={{ color: 'var(--yellow)' }}/>
        <div className="container">
          <p className="currentLocation"> Home <ChevronRight size={20}/> Design <ChevronRight size={20}/> UX/UI design <ChevronRight size={20}/> <ins>Grow your creative business in design field</ins></p>
          <div className="wrapper">
            <div className="leftside">
              <h1>{allGames.title}</h1>
              <div className="about">
                <p>{allGames.description}</p>
              </div>
              <div className="downloads">
                <a href={allGames.exe_file} download><button><Download/> Download .exe file</button></a>
                <a href={allGames.google_play_link}><img src={playStore} alt="" /></a>
                <a href={allGames.ios_link}><img src={appStore} alt="" /></a>
              </div>
              <div className="managaGame">
                <button onClick={() => setEditGameModalOpen(true)}>Edit game</button>
                <button onClick={() => setDeleteGameModalOpen(true)}>Delete game</button>
              </div>
            </div>
            <div className="rightside">
              <div className="thumbnail">
                <img className="image" src={allGames.image} alt="Video thumbnail" />
              </div>
            </div>
          </div>
        </div>
      </GameStyle>

    </>
  );
};

export default AboutGame;

const GameStyle = styled.div`
  background-color: #fff;
  margin: 0 auto;
  border: 1px solid #eee;
  .container{
    width: 90%;
    margin: 0 auto;
    padding: 0.5rem auto 5rem auto;
    .currentLocation {
      display: flex;
      align-items: center;
      color: #888;
      font-size: 15px;
      svg {
        margin: 0.3rem;
      }
      ins {
        text-decoration: none;
        color: #000;
        font-weight: 400;
      }
    }
    .wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      grid-gap: 0rem;
      margin-top: 2.5rem;
      margin-bottom: 5rem;
      .leftside {
        h1 {
          font-size: 36px;
          font-weight: 500;
          max-width: 500px;
          margin-bottom: 1.2rem;
        }
        .managaGame{
          margin-top: 2rem;
          button:nth-child(1){
            padding: 0.35rem 1rem;
            border-radius: 0.2rem;
            border: 1px solid #aaa;
            background: none;
            margin-right: .5rem;
            cursor: pointer;
            font-weight: 500;
          }
          button:nth-child(2){
            cursor: pointer;
            padding: 0.35rem 1rem;
            border-radius: 0.2rem;
            background: none;
            border: none;
            color: red;
          }
        }
        .about {
          h3 {
            font-weight: 500;
            font-size: 17px;
            margin: 1.5rem 0;
          }
          p {
            font-size: 17px;
            line-height: 1.6rem;
            max-width: 80%;
            a {
              text-decoration: none;
              color: #2f80ed;
            }
          }
        }
        .downloads {
          display: flex;
          flex-wrap: wrap;
          margin-right: 2rem;
          a{
            cursor: pointer;
            text-decoration: none;
            margin-right: 0.5rem;
            img{
              max-width: 160px;
            }
          }
          button{
            cursor: pointer;
            width: 100%;
            min-width: max-content;
            background: #f2c94c;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 18px;
            font-weight: 500;
            border-radius: 0.3rem;
            border: none;
            padding: 0.7rem 1rem;
            svg{
              margin-right: .5rem;
            }
            &:hover{
              background: #dab33f;
            }
          }
        }
      }
      .rightside {
        display: flex;
        flex-direction: column;
        .about {
          display: none;
        }
        .thumbnail {
          display: flex;
          justify-content: right;
          position: relative;
          margin-top: 0.8rem;
          cursor: pointer;
          border-radius: 0.75rem;
          overflow: hidden;
          .play {
            position: absolute;
            left: 56%;
            top: 44%;
            z-index: 2;
          }
          .image {
            margin: 0;
            width: 100%;
            object-fit: cover;
            height: 390px;
            filter: brightness(0.9);
          }
        }
      }
    }
    @media screen and (max-width: 1024px) {
      width: 90%;
      margin: 0 auto;
      .wrapper {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 2.5rem;
        margin-bottom: 5rem;
        .leftside {
          width: 90%;
          margin: 0 auto;
          order: 2;
          h1 {
            font-size: 36px;
            font-weight: 500;
            margin-bottom: 1.2rem;
          }
          .details {
            text-align: center;
            max-width: 100%;
            margin-top: 3rem;
            div{
              display: flex;
              /* align-items: center; */
              width: 100%;
              margin: 0 auto;
              justify-content: space-between;
              margin-bottom: 1.5rem;
              a{
                img{
                  width: 95%;
                }
              }
            }
            button{
              cursor: pointer;
              width: 100%;
              background: #f2c94c;
              color: #fff;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              font-weight: 500;
              border-radius: 0.5rem;
              border: none;
              padding: 1rem 0;
              svg{
                transform: rotate(90deg) translateX(.2rem);
                margin-right: .5rem;
              }
              &:hover{
                background: #dab33f;
              }
            }
          }
          .downlodBtn {
            background-color: #f2c94c;
            color: #fff;
            display: flex;
            width: 400px;
            align-items: center;
            padding: 2px;
            justify-content: center;
            font-size: 20px;
            font-weight: 500;
            border-radius: 5px;
            cursor: pointer;
            :hover {
              -webkit-box-shadow: 1px 1px 5px 1px rgba(114, 114, 114, 0.23);
              box-shadow: 1px 1px 5px 1px rgba(114, 114, 114, 0.23);
            }
          }
        }
        .rightside {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-bottom: 1rem;
          .thumbnail {
            .image {
              margin: 0;
              width: 100%;
              object-fit: cover;
              height: 200px;
              filter: brightness(0.9);
            }
          }
          hr {
            display: none;
          }
        }
      }
    }
  }
`;
