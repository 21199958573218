import React, { useState, useContext, useRef, useEffect } from "react";
import parse from "html-react-parser";
import styled from "styled-components";
import axios from "axios";
import { ArrowNarrowLeft, Link as LinkIcon } from "tabler-icons-react";
import {
  Container,
  Image,
  Grid,
  Avatar,
  Card,
  Text,
  Tabs,
  Modal,
  TextInput,
  Button,
  AspectRatio,
  Overlay,
  Stepper,
  Group,
  Skeleton,
  Spoiler,
  LoadingOverlay,
} from "@mantine/core";
import { ProjectCard } from "../../Components/HackathonComponents/ProjectCard/ProjectCard";
import { Link } from "react-router-dom";

import { useParams } from "react-router-dom";
import { AppContext } from "../../Helpers/Context";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

import moment from "moment";
import { useMediaQuery } from "@mantine/hooks";

import { MobileMenuNew } from "../../Components/Layout/Navbar/Menu";

import { ShowSidebar } from "../../Components/Layout/LayoutHelper";

import { Speaker } from "../../Components/HackathonComponents/HackathonSpeaker";

export const MainHackathon = ({ props }) => {
  // Data from params and context
  const { id } = useParams();
  const ref = useRef();
  const { user } = useContext(AppContext);
  const queryClient = useQueryClient();

  // State to keep alert message
  const [message, setMessage] = useState("");

  // State to keep hackathon data entries
  const [entryData, setEntryData] = useState([]);

  const [activeStep, setActiveStep] = useState(0);

  const [userTeams, setUserTeams] = useState(0);
  const [joinedHackathonId, setJoinedHackathonId] = useState(0);

  // Media queries
  const xs = useMediaQuery("(max-width: 568px)");
  const sm = useMediaQuery("(max-width: 768px)");
  const sm2 = useMediaQuery("(max-width: 850px)");
  const lg = useMediaQuery("(min-width: 995px)");
  const md = useMediaQuery("(max-width: 1150px)");
  const xs3 = useMediaQuery("(max-width: 600px)");
  const xs4 = useMediaQuery("(max-width: 460px)");
  const xl = useMediaQuery("(min-width: 1024.5px)");

  // State to manupilate modal for Team Creation
  const [showCreateTeamModal, setShowCreateTeamModal] = useState(false);

  const [member, setMember] = useState({});
  const [members, setMembers] = useState([user]);
  const [team, setTeam] = useState("");
  const [errors, setErrors] = useState({ team: null, member: null });
  const [data, setData] = useState([]);

  const { data: hackathonTeam, isLoading: teamLoading } = useQuery(
    ["hackathon team"],
    async () =>
      (await axios
        .get(`hackathon/user_teams/${user?.id}`)
        .then((res) => res.data[0])) || []
  );

  const { data: hackathonProjects, isLoading: projectsLoading } = useQuery(
    ["hackathon all projects", id],
    async () =>
      axios.get(`/project/projectsbyhackathonid/${id}`).then((res) => res.data)
  );

  const sortedHackathonProjects = hackathonProjects
    ?.slice()
    .sort((a, b) => b?.sum_rating - a?.sum_rating);

  const { data: hackathonProject, isLoading: projectLoading } = useQuery(
    ["hackathon project", hackathonTeam?.id],
    async () => await axios.get(`/project/byteam_id/${hackathonTeam?.id}`),
    { enabled: !!hackathonTeam }
  );

  const getUserByEmail = async (member) => {
    const response = await axios.get(`/api/v1/user_by_email/${member}/`);
    return response?.data;
  };

  const validate = async ({ id, value }) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    if (id == "member") {
      if (!emailRegex.test(value))
        return setErrors({ ...errors, member: "Not in the email format" });
      try {
        const reqMember = await getUserByEmail(value);
        let _members = members;
        if (_members.length == 3)
          return setErrors({
            ...errors,
            member: "Team can have up to 3 members",
          });

        if (!reqMember)
          return setErrors({ ...errors, member: "Member is not found" });

        setErrors({});
        return reqMember;
      } catch (err) {
        console.log("Error", err);
        setErrors({ ...errors, member: "Member is not found" });
        return null;
      }
    }
  };

  const changeLink = () => {
    // alert(
    //   "Komandanız yaradıldı. Hakaton başlayarkən səhifə aktiv olacaqdır..."
    // );
    window.location.href = `/project/${hackathonProject?.data[0].id}`;
  };

  function addMember() {
    let _members = members;

    if (!Object.keys(member).length)
      return setErrors({
        ...errors,
        member: "You should add at least 1 more member",
      });

    if (_members.length == 3)
      return setErrors({ ...errors, member: "Team can have up to 3 members" });

    if (member.id == user.id)
      return setErrors({
        ...errors,
        member: "You can not add yourself again",
      });

    let _finded = members.find((_member) => _member?.id == member?.id);
    if (_finded?.id) {
      return setErrors({
        ...errors,
        member: "You can not add same user twice",
      });
    }

    _members.push(member);
    setMembers(_members);
    setMember({});
  }

  const handleChange = async (e) => {
    const { id, value } = e.target;

    if (id == "member") {
      setMember({});

      if (value) {
        setErrors({ ...errors, member: null });

        const member = await validate({ id, value });
        if (member) {
          setMember({ ...member, email: value });
        }
      }
    }

    if (id == "team") {
      setTeam("");

      if (value) {
        setErrors({ ...errors, team: null });
        setTeam(value);
      }
    }
  };

  async function handleSubmitTeam(e) {
    e.preventDefault();

    if (!team.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    if (!members.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    const formData = {
      name: team,
      description: "",
      is_active: true,
      hackathon: entryData?.id,
      user: user?.id,
      members: members.map((m) => m.id),
    };

    axios.post("/hackathon/team_create/", formData).then((data) => {
      axios
        .post("/project/create/", {
          name: "Project",
          user: user?.id,
          team: data?.data?.id,
          description: "<b>Here you will add project description...<b>",
        })
        .then(() => window.location.reload());
    });
  }

  //  if(errors?.member){
  //   setButtonDisabled(true);
  // }
  // else{
  //   setButtonDisabled(false)};
  // }

  // Style for active tab
  const activeTabStyle = {
    backgroundColor: "yellow",
    border: "1px solid black",
    marginRight: "10px",
    borderRadius: "8px",
  };

  // Get hackathon data
  const { isLoading, isFetching } = useQuery(
    ["hackathon", id],
    async () => await axios.get(`/hackathon/hackathonbyid/${id}/`),
    {
      onSuccess: (data) => {
        setEntryData(data?.data[0]);
      },
    }
  );

  const isMentor = entryData?.mentors?.find((mentor) => mentor.id === user?.id);

  // Get user teams data
  const getUserTeamsLength = async () => {
    await axios.get(`/hackathon/user_teams/${user?.id}/`).then((response) => {
      setUserTeams(response?.data?.length);
      response?.data[0]?.hackathon?.id &&
        setJoinedHackathonId(response?.data[0]?.hackathon?.id);
    });
  };
  getUserTeamsLength();
  // console.log(joinedHackathonId);

  // Funtion for handling next step
  const nextStep = () => {
    if (activeStep == 1 && !team.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    if (activeStep == 2 && !members.length)
      return setErrors({ ...errors, team: "Team name can not be blank" });

    if (activeStep != 3) {
      setActiveStep((current) => (current < 3 ? current + 1 : current));
    }
  };

  const handleOrder = async (e) => {
    e.preventDefault();
    // Step 1: Create hackathon order
    const formXorder = new FormData();
    formXorder.append("is_free", true);
    formXorder.append("user", user?.id);
    formXorder.append("hackathon", id);
    formXorder.append("promocode", "");

    try {
      const response = await axios.post("/hackathon/create-order/", formXorder);
      console.log(response);
    } catch (error) {
      console.log(error?.response?.data);
    }

    setShowCreateTeamModal(true);
  };

  if (
    isLoading ||
    isFetching ||
    teamLoading ||
    projectLoading ||
    projectsLoading
  ) {
    return (
      <LoadingOverlay
        visible={true}
        variant="bars"
        loaderProps={{ color: "var(--yellow)" }}
      />
    );
  }

  // Return main component
  return (
    <div>
      <ShowSidebar>
        <Container size={"lg"} mt={20}>
          <MobileTab>
            <div className="mobileMenuMain">
              <MobileMenuNew />
            </div>
          </MobileTab>
          <Grid>
            <div
              style={{
                backgroundColor: "white",
                borderRadius: "4px",
                padding: "30px 20px",
              }}
            >
              <Grid.Col span={12}>
                <div
                  className="title"
                  style={{ display: "flex", marginBottom: "14px" }}
                >
                  <Link to={"/hackathons"}>
                    <ArrowNarrowLeft
                      size={32}
                      strokeWidth={2}
                      color={"black"}
                    />
                  </Link>
                  <div className="text" style={{ marginLeft: "16px" }}>
                    <a
                      href="/"
                      style={{
                        color: "#1a1d1c",
                        textDecoration: "none",
                        fontSize: "24px",
                        fontWeight: "400",
                        fontStyle: "normal",
                        lineHeight: "24px",
                      }}
                    >
                      {entryData?.title}
                    </a>
                    <span
                      style={{
                        display: "block",
                        marginTop: "4px",
                        fontSize: "12px",
                        color: "#5e5e5e",
                        marginTop: "-3px",
                      }}
                    >
                      Hackathon event
                    </span>
                  </div>
                </div>

                <Grid>
                  <Grid.Col xl={6} md={12}>
                    <div
                      style={{
                        width: xl ? "425px" : "",
                        height: xl ? "240px" : "",
                        borderRadius: "4px",
                        overflow: "hidden",
                      }}
                    >
                      <Image
                        src={
                          entryData?.image?.name
                            ? URL.createObjectURL(entryData?.image)
                            : entryData?.image
                        }
                        alt="event-image"
                        style={{
                          maxWidth: "100%",
                          objectFit: "cover",
                        }}
                      />
                    </div>
                  </Grid.Col>
                  <Grid.Col xl={6} md={12}>
                    <div>
                      <div className="about" style={{ maxWidth: "100%" }}>
                        <h1
                          style={{
                            fontSize: "20px",
                            fontWeight: 500,
                            color: "#202939",
                          }}
                        >
                          About
                        </h1>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="columns">
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              Type:
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              Start date:
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              End date:
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 400,
                                lineHeight: "10px",
                              }}
                            >
                              Language:
                            </p>
                          </div>
                          <div className="columns-values">
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              Hackathon
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              {moment(entryData?.start_date).format(
                                "DD.MM.YYYY"
                              )}
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              {moment(entryData?.end_date).format("DD.MM.YYYY")}
                            </p>
                            <p
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                lineHeight: "10px",
                              }}
                            >
                              {entryData?.language?.language}
                            </p>
                          </div>
                        </div>
                        {isMentor ? (
                          <p
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontWeight: 600,
                            }}
                          >
                            Mentors cannot participate in the contest.
                          </p>
                        ) : !userTeams ? (
                          <div className="link" style={{ marginTop: "2px" }}>
                            <div>
                              <Button
                                color="yellow"
                                mt={25}
                                fullWidth
                                onClick={handleOrder}
                              >
                                Join
                              </Button>
                            </div>
                          </div>
                        ) : userTeams && joinedHackathonId == id ? (
                          <div className="link" style={{ marginTop: "2px" }}>
                            <div>
                              <Button
                                color="yellow"
                                mt={25}
                                fullWidth
                                onClick={changeLink}
                              >
                                Enter
                              </Button>
                            </div>
                          </div>
                        ) : (
                          <p
                            style={{
                              color: "red",
                              textAlign: "center",
                              fontWeight: 600,
                            }}
                          >
                            You already joined another hackathon (or added by
                            someone else).
                          </p>
                        )}
                      </div>
                    </div>
                  </Grid.Col>
                </Grid>
                <Grid.Col span={12}>
                  <h1
                    style={{
                      fontSize: "20px",
                      fontWeight: 500,
                      color: "#202939",
                    }}
                  >
                    Description
                  </h1>
                  <Spoiler
                    mt={-14}
                    maxHeight={160}
                    showLabel="Show more"
                    hideLabel="Hide"
                    styles={{ control: { color: "#868E96" } }}
                  >
                    <div style={{ maxWidth: "96%" }}>
                      <p style={{ textAlign: "justify", maxWidth: "100%" }}>
                        {entryData?.description &&
                          parse(entryData?.description)}
                      </p>
                    </div>
                  </Spoiler>
                </Grid.Col>
              </Grid.Col>
            </div>
          </Grid>

          <HackathonMain>
            <div className="info">
              <div className="about">
                <Modal
                  opened={showCreateTeamModal}
                  onClose={() => {
                    setShowCreateTeamModal(false);
                    setErrors({ member: null, team: null });
                    setTeam("");
                    setMember({});
                    setMembers([]);
                    setActiveStep(0);
                    window.location.reload();
                  }}
                  title="Create team"
                  size={"lg"}
                  my={"auto"}
                >
                  <form ref={ref} onSubmit={handleSubmitTeam}>
                    <Stepper
                      color="#333"
                      active={activeStep}
                      onStepClick={setActiveStep}
                      allowNextStepsSelect={false}
                    >
                      <Stepper.Step
                        label="First step"
                        description="Read description"
                        allowStepClick={false}
                        allowStepSelect={false}
                      >
                        Welcome to the Hackathon Team Creation Portal! Before
                        you dive into creating your winning team, let's walk
                        through the process to ensure you have all the
                        information you need. In this hackathon, you'll be
                        solving exciting challenges, so teamwork is essential.
                        You'll start by defining a unique team name that
                        represents your mission and vision. Afterward, you'll
                        have the opportunity to invite team members to join you
                        on this thrilling journey. Remember, you must have at
                        least one team member (excluding the admin) to form a
                        team. Let's get started!
                      </Stepper.Step>
                      <Stepper.Step
                        label="Second step"
                        description="Define a team name"
                      >
                        <p style={{ marginBottom: "16px" }}>
                          A team's name is not just a label; it's a symbol of
                          your identity and purpose. Choose a name that reflects
                          your team's spirit and the challenge you aim to
                          conquer. Make it creative, memorable, and inspiring.
                          Your team name will be the banner under which you'll
                          tackle complex problems and showcase your innovation.
                          Once you've decided on the perfect name, proceed to
                          the next step to gather your teammates.
                        </p>
                        <TextInput
                          placeholder="Team name"
                          id="team"
                          error={errors?.team}
                          value={team}
                          onChange={handleChange}
                        />
                      </Stepper.Step>
                      <Stepper.Step
                        label="Final step"
                        description="Add members"
                      >
                        <p style={{ marginBottom: "16px" }}>
                          Great teams are built on diversity, expertise, and
                          collaboration. Now that you've established your team
                          name, it's time to bring in the talented individuals
                          who will help you succeed in the hackathon. You can
                          invite up to three members to join your team. Each
                          member will contribute their unique skills and
                          perspectives to tackle the challenges ahead. Don't
                          forget that you, as the admin, are already counted as
                          a team member. Collaboration is the key to success, so
                          choose your team members wisely. Once your team is
                          complete, you'll be ready to embark on the exciting
                          journey of the hackathon!
                        </p>
                        <div>
                          <TextInput
                            id="member"
                            error={errors?.member}
                            value={member?.email}
                            onChange={handleChange}
                            placeholder="Enter email address"
                          />
                          <div
                            className="createTeam"
                            style={{
                              marginTop: "10px",
                              display: "flex",
                              justifyContent: "flex-end",
                            }}
                          >
                            <Button
                              style={{ backgroundColor: "#333" }}
                              onClick={addMember}
                            >
                              Add member
                            </Button>
                          </div>
                          <div
                            className="addedUsers"
                            style={{ marginTop: "15px" }}
                          >
                            <Grid>
                              {members?.map((member) => (
                                <Grid.Col span={4} key={member?.id}>
                                  <div
                                    className="member"
                                    style={{
                                      display: "flex",
                                      gap: "10px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Image
                                      width={50}
                                      height={50}
                                      mb={10}
                                      src={member.profile_img}
                                      withPlaceholder
                                      radius={"100%"}
                                    />
                                    <Text
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: 400,
                                      }}
                                    >
                                      {member.first_name} {member.last_name}
                                    </Text>
                                  </div>
                                </Grid.Col>
                              ))}
                            </Grid>
                          </div>
                        </div>
                        <div
                          className="createTeam"
                          style={{
                            marginTop: "10px",
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        ></div>
                      </Stepper.Step>
                      <Stepper.Completed>
                        Completed, now you are ready to shine!
                        {message && message}
                        <Group justify="center" mt="xl">
                          <Button
                            type="submit"
                            style={{ backgroundColor: "#333" }}
                          >
                            Submit
                          </Button>
                        </Group>
                      </Stepper.Completed>
                    </Stepper>
                  </form>

                  <Group
                    justify="center"
                    mt="xl"
                    style={{ display: activeStep === 3 ? "none" : "block" }}
                  >
                    <Button
                      style={{ backgroundColor: "#333" }}
                      onClick={nextStep}
                    >
                      Next Step
                    </Button>
                  </Group>
                </Modal>
              </div>
            </div>
          </HackathonMain>

          <div
            style={{
              marginTop: "6px",
              marginBottom: "15px",
              backgroundColor: "white",
              borderRadius: "4px",
              padding: "30px",
              width: "100%",
              boxSizing: "border-box", // Ensure padding is included in the width
            }}
          >
            <div style={{ marginBottom: "14px" }}>
              <a
                style={{
                  color: "#1a1d1c",
                  fontSize: "24px",
                  fontWeight: "500",
                  fontStyle: "normal",
                  lineHeight: "24px",
                  marginLeft: "16px",
                }}
              >
                Mentors
              </a>
            </div>
            {entryData?.mentors?.length ? (
              <div className="mentors">
                <Grid>
                  {entryData?.mentors &&
                    entryData?.mentors.map((mentor) => (
                      <Grid.Col xl={6} lg={6} sm={6} xs={12}>
                        <Speaker speaker={mentor} />
                      </Grid.Col>
                    ))}
                </Grid>
              </div>
            ) : (
              <p style={{ marginLeft: "16px", fontSize: "14px" }}>
                Mentors are not specified yet.
              </p>
            )}
          </div>

          <OtherProjects>
            <div
              style={{
                marginTop: "6px",
                backgroundColor: "white",
                borderRadius: "4px",
                padding: "30px",
                width: "100%",
                boxSizing: "border-box", // Ensure padding is included in the width
              }}
            >
              <div style={{ marginBottom: "14px" }}>
                <a
                  style={{
                    color: "#1a1d1c",
                    fontSize: "24px",
                    fontWeight: "500",
                    fontStyle: "normal",
                    lineHeight: "24px",
                    marginLeft: "16px",
                  }}
                >
                  Projects
                </a>
              </div>
              {sortedHackathonProjects?.length > 0 ? (
                <div className="projects">
                  <Grid>
                    {sortedHackathonProjects?.map((project) => (
                      <Grid.Col
                        key={project?.id}
                        span={xs ? 12 : sm2 ? 6 : md ? 4 : 3}
                      >
                        <ProjectCard project={project} />
                      </Grid.Col>
                    ))}
                  </Grid>
                </div>
              ) : (
                <p style={{ marginLeft: "16px", fontSize: "14px" }}>
                  There are no projects yet.
                </p>
              )}
            </div>
          </OtherProjects>
        </Container>
      </ShowSidebar>
    </div>
  );
};

// Styled divs

const HackathonMain = styled.div`
  .title {
    display: flex;

    .text {
      margin-left: 16px;

      a {
        color: #1a1d1c;
        text-decoration: none !important ;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 100% */
      }

      span {
        display: block;
        margin-top: 4px;
        font-size: 15px;
        color: #5e5e5e;
      }
    }
  }

  .info {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .about {
      height: "max-content";
      .text {
        border-radius: 10px;
        background: #f8f9fa;
        margin-left: 24px;
        padding: 10px 30px;
        max-height: 100%;

        h1 {
          color: #4f4f4f;
          font-size: 22px;
        }

        p {
          text-align: justify;
          color: #333;
        }
      }

      .rules {
        display: flex;
        margin-top: 20px;

        .badge {
          margin-left: 26px;
          display: flex;
          padding: 1px 12px;
          justify-content: center;
          align-items: center;
          gap: 10px;
          border-radius: 8px;
          border: 1px solid #414a4c;

          svg {
            width: 24px;
            height: 24.001px;
          }

          p {
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
          }
        }
      }

      .submitter {
        width: 100%;
        padding-left: 24px;
        text-align: end;
        box-sizing: border-box;

        .subbut {
          margin-top: 8px;
          width: 100%;
          padding: 0;
          height: 50px;
          flex-shrink: 0;
          border-radius: 8px;
          background: #484848;
          color: white;
          font-size: 17px;
          cursor: pointer;
        }
      }
    }
  }
  @media screen and (max-width: 995px) {
    .info {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
    .about {
      .text {
        margin-left: 0 !important;
      }
    }
    .submitter {
      padding-left: 0 !important;
    }
  }
  @media (max-width: 568px) {
    // .info{
    width: 100%;
    // }
  }
`;

const YourProject = styled.div`
  // filter: blur(10px);

  .yourProject {
    background-color: #f1f3f5;
    margin-top: 50px;
    border-radius: 8px;
    padding: 16px 30px;

    .nav {
      display: flex;
      justify-content: space-between;
      .title {
        p {
          font-size: 24px;
          font-style: normal;
          font-weight: 500;
        }
      }

      .buttons {
        display: flex;
        margin-top: 20px;

        .editBtn {
          background: #f2c94c;
          border-color: transparent;
          border-radius: 8px;
          color: black;
          padding: 1px 12px;
          font-size: 16px;
          display: flex;
          align-items: center;
          gap: 10px;
          margin-right: 16px;
          height: 44px;
          font-weight: 400;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
          }
        }

        .removeBtn {
          background: red;
          color: #fff;
          border-color: transparent;
          border-radius: 8px;
          padding: 1px 12px;
          font-size: 16px;
          align-items: center;
          display: flex;
          height: 44px;
          font-weight: 400;
          gap: 10px;
          cursor: pointer;

          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }

    .main {
      margin-top: 14px;

      .details {
        padding-left: 18px;
        padding-right: 18px;

        h2 {
          color: #4f4f4f;
          font-size: 22px;
        }

        span {
          display: block;
          font-size: 17px;
          color: #5e5e5e;
          margin-top: -12px;
        }

        .yourDescription {
          height: 120px;
          overflow: hidden;
        }

        .avatars {
          display: flex;
          margin-top: 20px;

          .avatarsGroup {
            & > * {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
  @media (max-width: 568px) {
    .nav {
      flex-wrap: wrap;
    }
  }
`;

const OtherProjects = styled.div`
  z-index: 0;
  position: relative;
  // &::after {
  //   backdrop-filter: blur(10px);
  //   width: 100%;
  //   height: 100%;
  //   content: "Bu hissə hackathon bitəndə aktiv olacaq.";
  //   position: absolute;
  //   top: -30px;
  //   left: 0;
  //   text-align: center;
  //   font-size: 60px;
  //   font-weight: 600;
  //   color: #f5df4d;
  //   z-index: 200;
  // }
  .otherProjects {
    margin-top: 50px;
    padding: 16px 30px;

    .title {
      p {
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
      }
    }
  }
  @media (max-width: 500px) {
    .search-other-projects {
      flex-wrap: wrap;
      button {
        width: 100%;
      }
    }
  }
`;

const MobileTab = styled.div`
  @media (min-width: 1025px) {
    .mobileMenuMain {
      display: none;
    }
  }
`;
