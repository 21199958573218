import { Button, FileInput, Input, Skeleton } from "@mantine/core";
import axios from "axios";
import { useContext, useState, useEffect } from "react";
import { AppContext } from "../../../Helpers/Context";
import styled from "styled-components";
import parse from "html-react-parser";
import { RichTextEditor } from "@mantine/rte";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Grade from "./Grade";

const AnswerSection = ({ props }) => {
  const { showPart,type } = props;
  const { user } = useContext(AppContext);

  const [textInput, setTextInput] = useState("");
  const [linkInput, setLinkInput] = useState("");

  const [currentAnswer, setCurrentAnswer] = useState(null);
  

  const {
    data: userAnswers,
    error,
    isError,
  } = useQuery(
    ["userAnswer", showPart?.section?.id],
    async () =>
      await axios
        .get(
          `/answer/internship/answers/?user_id=${user?.id}&section_id=${showPart?.section?.id}`
        )
        .then((res) => res.data),
    {
      enabled: !!showPart?.section?.id,
    }
  );

  const queryClient = useQueryClient();

  useEffect(() => {
    if (userAnswers) {
      let answer = userAnswers?.find(
        (answer) =>
          answer?.section_text === showPart?.id ||
          answer?.section_video === showPart?.id ||
          answer?.section_chart === showPart?.id
      );
      setCurrentAnswer(answer);
    }
    return () => {
      setCurrentAnswer(null);
    };
  }, [userAnswers]);

  const {
    mutate: mutateSend,
    isLoading: sendingAnswer,
    isError: errorSendingAnswer,
    error: errorAnswer,
  } = useMutation(
    ["sendAnswer"],
    async (e) => {
      e.preventDefault();

      let formX = new FormData();
      formX.append("answer_text", textInput);
      formX.append("answer_link", linkInput);
      formX.append("user", user?.id);
      formX.append("section", showPart.section?.id);
      if (e.target.answerFile.files[0]) {
        formX.append("answer_file", e.target.answerFile.files[0]);
      }
      if (showPart.type === "chart") {
        formX.append("section_chart", showPart.id);
      }
      if (showPart.type === "video") {
        formX.append("section_video", showPart.id);
      }
      if (showPart.type === "text") {
        formX.append("section_text", showPart.id);
      }

      let response = await axios.post("/answer/internship/", formX);
      queryClient.invalidateQueries(["userAnswer", showPart?.section?.id]);

      return response.data;
    },
    {
      onSuccess: (e) => {
        queryClient.invalidateQueries(["userAnswer", showPart?.section?.id]);
      },
    }
  );

  if (currentAnswer === null) {
    return (
      <>
        <br />
        <br />
        <Skeleton width="750px" height="50px" mb={20} />
        <Skeleton width="750px" height="100px" />
      </>
    );
  }

  if (
    currentAnswer !== null &&
    (currentAnswer?.section_text === showPart?.id ||
      currentAnswer?.section_video === showPart?.id ||
      currentAnswer?.section_chart === showPart?.id)
  ) {
    return (
<>
<AnswerStyle>
        <div className="wrapperX">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              margin: "0rem 0 2rem 0",
            }}
          >
            <h2 style={{ margin: "0" }}>User answer</h2>
            <ins
              style={{
                fontWeight: "lighter",
                fontSize: "14px",
                margin: ".25rem 0 0 .5rem",
                color: "#555",
                textDecoration: "none",
              }}
            >
              [{currentAnswer?.created_at.substring(0, 10)} {"-"}{" "}
              {currentAnswer?.created_at.substring(11, 16)}]
            </ins>
          </div>
          {currentAnswer?.answer_text && parse(currentAnswer?.answer_text)}
          {currentAnswer?.answer_file && (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <p style={{ margin: "0 0.5rem 0 0" }}>File: </p>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={currentAnswer?.answer_file}
                >
                  {currentAnswer?.answer_file?.substring(100, 200)}
                </a>
              </div>
              {/* <img style={{ width:"250px", height:"250px", objectFit:"contain" }} src={currentAnswer?.answer_file} alt="" /> */}
            </div>
          )}
          {currentAnswer?.answer_link && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <p style={{ margin: "0 0.5rem 0 0" }}>Link: </p>
              <a
                target="_blank"
                rel="noreferrer"
                href={currentAnswer?.answer_link}
              >
                {currentAnswer?.answer_link}
              </a>
            </div>
          )}
        </div>
      </AnswerStyle>
     <Grade props={{ userAnswer: currentAnswer, forUsers: false, type }} />
</>
    );
  }

  return (
    <div>
      <hr />
      <AnswerFormStyle>
        <form onSubmit={(e) => mutateSend(e)} style={{ width: "60%" }}>
          <h2>Submit your answer</h2>
          <p style={{ color: "red" }}>
            You can only submit your answer once! Act accordingly.
          </p>
          <Input.Wrapper label="Answer">
            <RichTextEditor
              value={textInput}
              onChange={(e) => setTextInput(e)}
              style={{ width: "97%" }}
            />
          </Input.Wrapper>
          <br />
          <Input.Wrapper label="Link">
            <Input
              type="text"
              name="answerLink"
              placeholder="Answer link"
              value={linkInput}
              onChange={(e) => setLinkInput(e.target.value)}
              style={{ width: "97%" }}
            />
          </Input.Wrapper>
          <br />
          <FileInput
            name="answerFile"
            placeholder="Pick a file"
            label="File upload"
            // value={fileInput ? fileInput : null}
            // onChange={(e) => setFileInput(e.target.value)}
            style={{ width: "97%" }}
          />
          <br />
          {errorSendingAnswer && (
            <div>
              <p style={{ color: "red" }}>{errorAnswer?.message}</p>
            </div>
          )}
          <Button
            loading={sendingAnswer}
            className="submitAnswer"
            type="submit"
          >
            Submit
          </Button>
        </form>
      </AnswerFormStyle>
    </div>
  );
};

export default AnswerSection;

const AnswerStyle = styled.div`
  margin-top: 2rem;
  .wrapperX {
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2 {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider {
      margin-top: 1rem;
      p {
        margin-top: 0;
      }
    }
    button {
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      &:hover {
        background-color: var(--yellow-shadow);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    .wrapperX {
      max-width: 100%;
      padding: 1.5rem;
    }
  }
`;

const AnswerFormStyle = styled.div`
  margin-top: 2rem;
  form {
    background-color: #f2f8ff;
    padding: 1.5rem 2rem;
    max-width: 70%;
    border-radius: 0.5rem;
    border: 1px solid #eee;
    h2 {
      margin-top: 0;
      margin-bottom: 2rem;
    }
    .slider {
      margin-top: 1rem;
      p {
        margin-top: 0;
      }
    }
    .submitAnswer {
      background-color: var(--yellow);
      padding: 0.5rem 3rem;
      margin-top: 1.5rem;
      border: 0;
      font-size: 15px;
      border-radius: 0.25rem;
      color: #000;
      font-weight: 500;
      &:hover {
        background-color: var(--yellow-shadow);
      }
    }
  }
  @media screen and (max-width: 1024px) {
    border-bottom: 1px solid #eee;
    form {
      background: none;
      padding: 1.5rem 0rem;
      min-width: 100%;
      margin: 0 auto;
      border-radius: 0.5rem;
      border: none;
      h2 {
        margin-top: 0;
        margin-bottom: 2rem;
      }
      .slider {
        margin-top: 1rem;
        p {
          margin-top: 0;
        }
      }
      .submitAnswer {
        width: 100%;
        background-color: var(--yellow);
        padding: 0.5rem 3rem;
        margin-top: 1.5rem;
        border: 0;
        font-size: 15px;
        border-radius: 0.25rem;
        &:hover {
          background-color: var(--yellow-shadow);
        }
      }
    }
  }
`;
