import React from 'react'
import styled from "styled-components";
import arrowUp from "../../../Assets/SVGs/profile/arrow-up-right.svg";
import sliderRight from "../../../Assets/SVGs/profile/arrow-right.svg";
import sliderLeft from "../../../Assets/SVGs/profile/arrow-left.svg";
import arrowDown from "../../../Assets/SVGs/profile/arrow-down.svg";
import calendarIcon from "../../../Assets/SVGs/profile/calendar.svg";
import statisticUp from "../../../Assets/SVGs/profile/statistic-up.svg";
import chart from "../../../Assets/Images/profile/chart-1.png"


function Analytics() {
  return (
    <AnalyticsStyle>
    <div className="analytics-header">
      <h2>Analytics</h2>
    </div>

    <div className="project-slider-header">
      <div className="left">
        <div className="project-slider-arrows">
          <div className="project-arrow-item">
            <img src={sliderLeft} alt="" />
          </div>
          <div className="project-arrow-item">
            <img src={sliderRight} alt="" />
          </div>
        </div>

        <div className="project-date">
          <div className="calendar-icon">
            <img src={calendarIcon} alt="" />
          </div>
          2023, 18 September
        </div>
      </div>
      <div className="right">
        <div className="project-filter">
          Weekly <img src={arrowDown} alt="" />
        </div>
      </div>
    </div>

    <div className="project-statistics">
      <div className="project-statistic-item">
        <h4>Total time spend</h4>
        <p>12 h. 42 m.</p>
      </div>

      <div className="project-statistic-item">
        <h4>Total task and projects</h4>
        <p>136</p>
      </div>

      <div className="project-statistic-item">
        <h4>Done tasks</h4>
        <p>122</p>
      </div>

      <div className="project-statistic-item">
        <h4>Canceled tasks</h4>
        <p>14.</p>
      </div>

      <div className="project-statistic-item">
        <h4>Growth</h4>
        <p>
          36% <img src={statisticUp} alt="" />
        </p>
      </div>
    </div>

    <div className='chart'>
        <img src={chart} alt="" />
    </div>

  </AnalyticsStyle>
  )
}

export default Analytics


const AnalyticsStyle = styled.div`
  padding: 32px 40px;
  border-radius: 12px;
  background: #fff;
  margin-bottom: 24px;
  overflow: hidden;
  .analytics-header {
    margin-bottom: 32px;

    h2 {
      color: #202939;
      font-family: "Euclid";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px;
    }

  }

  .project-slider-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;

    .left {
      display: flex;
      align-items: center;
      gap: 16px;
      .project-slider-arrows {
        display: flex;
        gap: 16px;
        .project-arrow-item {
          border-radius: 12px;
          width: 48px;
          height: 48px;
          text-align: center;
          line-height: 60px;
          border: 1px solid #cdd5df;
          background: #fff;
        }
      }

      .project-date {
        border-radius: 12px;
        border: 1px solid #cdd5df;
        padding: 4px 20px 4px 4px;
        height: 48px;
        display: flex;
        align-items: center;
        gap: 16px;
        color: #0d1018;
        font-family: "Poppins";
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 150%; /* 24px */
        .calendar-icon {
          width: 40px;
          height: 40px;
          text-align: center;
          line-height: 48px;
          background: #eef2f6;
          border-radius: 8px;
        }
      }
    }

    .right {
      .project-filter {
        display: flex;
        gap: 16px;
      }
    }
  }

  .project-statistics {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 32px;
    .project-statistic-item {
      width: 100%;
      padding: 16px;
      border-radius: 16px;
      background: #fffcf5;

      h4 {
        color: #dc6803;
        font-family: "Inter";
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; /* 145.455% */
        margin: 0px;
        padding: 0px;
        margin-bottom: 2px;
      }

      p {
        color: #000;
        font-family: "Inter";
        font-size: 20px;
        font-style: normal;
        font-weight: 600;
        line-height: 30px; /* 150% */
        margin: 0px;
      }
    }
  }

`;
