import React from 'react'
import styled from "styled-components";
import addIcon from "../../../Assets/SVGs/profile/plus.svg";
import editIcon from "../../../Assets/SVGs/profile/edit.svg";
import experienceImg1 from "../../../Assets/Images/profile/experience-1.png";
import experienceImg2 from "../../../Assets/Images/profile/experience-2.png";
import experienceImg3 from "../../../Assets/Images/profile/experience-3.png";

function Volunteering() {
  return (
    <VolunteeringStyle>
    <div className="volunteering-header">
      <h2>Volunteering and Mentoring</h2>

      <div className="header-icons">
        <div className="header-icon-item">
          <img src={addIcon} alt="" />
        </div>

        <div className="header-icon-item">
          <img src={editIcon} alt="" />
        </div>
      </div>
    </div>

    <div className="volunteering-box">
      <div className="volunteering-item">
        <div className="volunteering-img">
          <img src={experienceImg1} alt="" />
        </div>
        <div className="volunteering-detail">
          <div className="volunteering-date">January 2024 - March 2024</div>
          <h5>
            UX/UI Design <span className="position">Mentor</span>
          </h5>
          <div className="volunteering-company">Kapital Bank</div>
        </div>
      </div>

      <div className="volunteering-item">
        <div className="volunteering-img">
          <img src={experienceImg2} alt="" />
        </div>
        <div className="volunteering-detail">
          <div className="volunteering-date">January 2024 - March 2024</div>
          <h5>
            UX/UI Design <span className="position">Mentor</span>
          </h5>
          <div className="volunteering-company">Azeintelecom</div>
        </div>
      </div>

      <div className="volunteering-item">
        <div className="volunteering-img">
          <img src={experienceImg3} alt="" />
        </div>
        <div className="volunteering-detail">
          <div className="volunteering-date">January 2024 - March 2024</div>
          <h5>
            UX/UI Design <span className="position">Volunteer</span>
          </h5>
          <div className="volunteering-company">Mobilex</div>
        </div>
      </div>
    </div>
  </VolunteeringStyle>
  )
}

export default Volunteering


const VolunteeringStyle = styled.div`
  background: #fff;
  padding: 40px 32px;
  border-radius: 12px;
  margin-bottom: 24px;
  .volunteering-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
    h2 {
      color: #202939;
      font-family: "Euclid";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 28px; /* 140% */
    }

    .header-icons {
      display: flex;
      gap: 12px;
      .header-icon-item {
        padding: 12px;
      }
    }
  }

  .volunteering-box {
    .volunteering-item {
      display: flex;
      align-items: center;
      gap: 14px;
      padding:12px 0px;
      border-bottom: 1px solid #CDD5DF;

      .volunteering-date {
        color: #9aa4b2;
        font-family: "Euclid";
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 18px */
        margin-bottom: 4px;
      }

      h5 {
        margin: 0px;
        color: #121926;
        font-family: "Euclid";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 100% */
        /* display: flex;
        align-items: center; */
        margin-bottom: 4px;
        .position {
          color: #4b5565;
          font-size: 12px;
          font-weight: 400;
          line-height: 150%;
          margin-left: 12px;
        }
      }

      .volunteering-company {
        color: #4b5565;
        font-family: "Euclid";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%; /* 24px */
      }
    }
  }
`;