import { React } from "react";
import styled from "styled-components";
import arrows from "../../../Assets/SVGs/arrows.svg";
import { Circles } from "tabler-icons-react";

export const ThirdPart = () => {
  return (
    <ThirdPartStyle>
      <div className="arrowDiv">
        <img className="arrows" src={arrows} alt="" />
      </div>
      <h1>Skill & Portfolio building platform</h1>
      <div className="wrapper">
        <div className="contain">
          <div className="x">
            <Circles className="svg1" size={40} color="white"/>
            <div>
              <h3>Business tasks</h3>
              <p>
              Practice with our cases to be more confident in real business environment and reduce the risk of the failure to a minimum!
              </p>
            </div>
          </div>
          <div className="x">
            <Circles className="svg2" size={40} color="white"/>
            <div>
              <h3>Internship & Traineeship</h3>
              <p>
                We make your dream come true - internship programs in that field you crave to build your career are now available!
              </p>
            </div>
          </div>
        </div>
        <div className="contain">
          <div className="x">
            <Circles className="svg3" size={40} color="white"/>
            <div>
              <h3>Study programs</h3>
              <p>
                Looking for the specific courses to extend your knowledge? You are at the right adress. Enroll now, thank later!
              </p>
            </div>
          </div>
          <div className="x">
            <Circles className="svg4" size={40} color="white"/>
            <div>
              <h3>Simulation games</h3>
              <p>
                Learning is new fun. Enjoy the process of mastering new skills and unlock the new world for yourself. It's your turn to try!
              </p>
            </div>
          </div>
        </div>
      </div>
    </ThirdPartStyle>
  );
};

const ThirdPartStyle = styled.div`
  margin-bottom: 18rem;
  margin-top: 2rem;
  width: 100%;
  .arrowDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    .arrows {
      width: 100%;
      margin-top: 5.5rem;
      position: absolute;
    }
  }
  h1 {
    text-align: center;
    color: #4f4f4f;
    font-size: clamp(1.6rem, 5vw, 2.2rem);
  }
  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    display: flex;
    grid-gap: 0 2.5rem;
    position: relative;
    transform: scale(1.1) translateX(0.1rem);
    div:nth-child(2) {
      transform: translateY(5rem);
    }
    div:nth-child(4) {
      transform: translateY(5rem);
    }
    .x{
      display: flex;
      border-radius: 0.5rem;
      box-shadow: 0 10px 20px 0px #00000022;
      margin: 3rem 0;
      padding: 1.5rem 2rem 1.3rem 2rem;
      svg {
        background-color: pink;
        padding: .75rem;
        border-radius: 50%;
        height: min-content;
        margin-right: 1rem;
        margin-top: -.4rem;
        margin-left: -.5rem;
        min-width: 1rem;
      }
      .svg1{
        background-color: #ffc356;
      }
      .svg2{
        background-color: #63e196;
      }
      .svg3{
        background-color: #20bece;
      }
      .svg4{
        background-color: #ff8251;
      }
      .spoiler{
        color: #828282;
        display: flex;
        flex-direction: column;
        margin-left: -1rem;
        margin-top: 1rem;
        margin-bottom: -1rem;
        justify-content: center;
        /* border: 1px solid red; */
        font-size: clamp(1.125rem, 2.5vw, 1.3rem);
        svg{
          background: none;
          color: #555;
        }
      }
      div {
        transform: translateY(0);
        margin-left: .5rem;
      }
      h3 {
        margin: 0;
        color: #4f4f4f;
        font-weight: 500;
        font-size: clamp(1.3rem, 5vw, 1.4rem);
      }
      p {
        color: #828282;
        margin-bottom: 0.5rem;
        margin-left: -1rem;
        padding: 0 1rem;
        font-size: clamp(1rem, 2.5vw, 1rem);
      }
    }
  }
  @media screen and (max-width: 1274px) {
    margin-bottom: 2rem;
    width: 100%;
    background-color: #fff;
    .arrowDiv {
      display: none;
    }
    h1 {
      width: 70%;
      font-weight: 500;
      text-align: center;
      color: #4f4f4f;
      margin: 4rem auto;
    }
    .wrapper {
      width: 80%;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      transform: scale(1) translateX(0.1rem);
      div:nth-child(2) {
        transform: translateY(0rem);
      }
      div:nth-child(4) {
        transform: translateY(0rem);
      }
      .x {
        display: block;
        box-shadow: 0 0px 20px 0px #00000011;
        margin: 1rem;
        text-align: center;
        padding: 1.5rem 1.5rem 1.5rem 1.5rem;
        svg {
          display: flex;
          height: min-content;
          margin-right: 1rem;
          margin: 0 auto;
          margin-bottom: 1rem;
        }
        div {
          transform: translateY(0);
        }
        h3 {
          color: #4f4f4f;
          font-weight: 500;
          text-align: center;
        }
        p {
          color: #828282;
          margin-bottom: 0.5rem;
          text-align: center;
        }
        .spoiler{
          margin-bottom: 0rem;
          svg{
            margin-bottom: -1rem;
          }
          *{
            padding-bottom: 0;
          }
        }
      }
    }
  }
`;